import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { Talent } from "redux/Talent/types";
import { MusicItem, MusicItemLink, TalentProfileModule, User } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "./analytics";

/**
 * Track an event using the analytics service
 */
export const trackEvent = (
  event: SEGMENT_EVENT,
  isMobile: boolean,
  user: User,
  module: TalentProfileModule,
  musicItem: MusicItem,
  link: MusicItemLink,
  talent?: Talent,
) => {
  const DOMWindow = window as any;
  if (!DOMWindow.analytics) {
    // TODO: probably want to throw/log that this occurred
    return;
  }

  AnalyticServices.track(event, {
    "User id": user?.id,
    Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
    Location: Cookies.get(KOMI_USER_LOCATION),
    "Talent ID": talent?.id,
    "Module ID": module?.id,
    "Module Name": module?.name,
    "Element ID": musicItem.id,
    "Element Name": musicItem.metadata?.name,
    Destination: link.type,
    "Smart Link URL": link.url,
    Platform: isMobile ? "Responsive" : "Web",
    "Page ID": module?.localizationId || null,
    "Page Name": module?.localizationName || "Default",
  });
};
