import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { Text } from "components/Typography";
import { komiConsumerUrl } from "services/DomainService";
import Link from "next/link";
import React from "react";
import SVG from "react-inlinesvg";
import { Talent } from "redux/Talent/types";
import { getTalentName } from "utils/experience";
import { AnalyticServices } from "utils/analytics";
import { SEGMENT_EVENT } from "constants/segment";

interface IProps {
  talent?: Talent;
  className?: string;
  isTalentProfile?: boolean;
  isMobile?: boolean;
}

//setup interface for use self pattern
interface IFooterSelf {
  policyName: string;
  ppLink: string;
}

//setup use self function that contains logic in the head to be tested
export function useFooterSelf({ talent }: IProps): IFooterSelf {
  //determine policy name given custom privacy policy
  const policyName = talent?.talentProfile?.privacyPolicyName || getTalentName(talent, true);
  let ppLink = talent?.talentProfile?.privacyPolicy || `${komiConsumerUrl}/talent-privacy-policy`;
  if (talent?.talentProfile?.id === "1607e513-4f58-4ca6-a8a0-1dd29d8c449b") {
    ppLink = "https://www.sonymusic.com/privacy-policy/";
  }

  return {
    policyName,
    ppLink,
  };
}

function trackPoweredByKomiButtonClick({
  isMobile,
  talent,
}: {
  isMobile?: boolean;
  talent?: Talent;
}) {
  const properties = {
    location: AnalyticServices.getLocation(),
    platform: AnalyticServices.getPlatform({ side: "client-side" }),
    talentName: getTalentName(talent, true),
    talentId: talent?.id,
  } as const;

  AnalyticServices.track(SEGMENT_EVENT.CLICK_POWERED_BY_KOMI_BUTTON, {
    Location: properties.location,
    Platform: properties.platform,
    "Talent Name": properties.talentName,
    "Talent ID": properties.talentId,
  });
  AnalyticServices.track(SEGMENT_EVENT.GA_CLICK_POWERED_BY_KOMI_BUTTON, {
    is_ga_only: true,
    location: properties.location,
    platform: properties.platform,
    talent_name: properties.talentName,
    talent_id: properties.talentId,
  });
}

const Footer: React.FC<IProps> = (props) => {
  const {
    //pass props to head logic
    policyName,
    ppLink,
  } = useFooterSelf(props);

  const {
    //deconstruct props to use
    isTalentProfile = false,
    className = "",
    isMobile,
  } = props;

  return (
    <div className={`footer container ${className}`}>
      <Row className="container footer__info" justify="space-between" align="middle">
        <Col xs={24} md={12} className={`text__align--${isMobile ? "center" : "left"}`}>
          <Text preset="regular14">
            <a
              className="text__decoration--underline"
              href={ppLink}
              target="_blank"
              rel="noreferrer"
            >
              <span>{policyName}</span>
              {"’s Privacy Policy"}
            </a>
          </Text>
        </Col>

        <Col
          xs={24}
          md={12}
          className={`cursor-pointer text__align--${isMobile ? "center" : "right"}`}
        >
          <Link href={`${komiConsumerUrl}`}>
            <SVG
              onClick={() => trackPoweredByKomiButtonClick(props)}
              height={22}
              src={`https://images.komi.io/production/icons/footer-logo${
                isTalentProfile ? "-black" : ""
              }.svg`}
            />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
