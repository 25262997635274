import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageProgessive from "components/ImageProgessive";
import Swiper from "components/Swiper";
import { Paragraph } from "components/Typography";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { LinkItem, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { convertToUrl } from "utils/string";
import { Text } from "components/Typography";
import Button from "antd/lib/button";
import SpecialOfferModal from "components/SpecialOfferModal";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

interface ILinkCardProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  link: LinkItem;
  isBig: boolean;
  isPreview?: boolean;
  talent?: Talent;
}

//return interface for Link Card head logic
interface ILinkCardSelf {
  handleOpenLink(): void;
  mapAnalytics(): void;
}

//property interface for Link Card
interface ILinkCardSelfProps extends ILinkCardProps {
  user?: any;
}

export function useLinkCardSelf({
  user,
  talent,
  module,
  link,
  isPreview,
  isMobile,
}: ILinkCardSelfProps): ILinkCardSelf {
  //map segment events to enable testing
  const mapAnalytics = () => {
    return {
      "User id": user?.id,
      Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
      Location: Cookies.get(KOMI_USER_LOCATION),
      "Talent ID": talent?.id,
      "Module ID": module?.id,
      "Module Name": module?.name,
      "Element ID": link?.id,
      "Element Name": link?.title,
      "Element URL": link?.url,
      Platform: isMobile ? "Responsive" : "Web",
      "Link Type": "Custom Link",
      "Page ID": module?.localizationId || null,
      "Page Name": module?.localizationName || "Default",
    };
  };

  const handleOpenLink = useCallback(() => {
    if ((window as any)?.analytics && !isPreview) {
      const segmentEvent = mapAnalytics();
      AnalyticServices.track(SEGMENT_EVENT.CLICK_CUSTOM_LINK, segmentEvent);
    }
    window.open(link?.url, "_blank");
  }, [
    isPreview,
    link?.url,
    link?.id,
    link?.title,
    user,
    talent?.id,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    isMobile,
  ]);

  return {
    handleOpenLink,
    mapAnalytics,
  };
}

const LinkCard = ({ link, isMobile, className = "", isBig, isPreview, module, talent }: ILinkCardProps) => {
  const user = useTypedSelector(selectUserData);

  const { handleOpenLink } = useLinkCardSelf({
    user,
    talent,
    module,
    link,
    isPreview,
    isMobile,
    isBig,
  });

  return (
    <Row className={`link-card cursor-pointer ${className}`} align="middle" onClick={handleOpenLink}>
      <Col>
        <ImageProgessive
          ratio={0.25}
          threshold={[0.25, 1]}
          src={link.thumbnail}
          width={isMobile ? (isBig ? 115 : 96) : 140}
          height={isMobile ? (isBig ? 115 : 96) : 140}
        />
      </Col>
      <Col
        className={`flex--1 ${isMobile ? (isBig ? "p__x--16" : "p__x--12 p__t--12 p__b--10") : "p__x--16 p__y--22"}`}
      >
        <Paragraph ellipsis={{ rows: 2 }} className="m__b--0" preset={isMobile ? "semibold14" : "semibold20"}>
          {link.title}
        </Paragraph>
      </Col>
    </Row>
  );
};

const SpecialOfferLinkCard = ({ link, isMobile, className = "", isBig, module, talent, isPreview }: ILinkCardProps) => {
  const user = useTypedSelector(selectUserData);

  const [showModal, setShowModal] = useState<boolean>(false);

  const onShowModal = useCallback(() => {
    if ((window as any)?.analytics && !isPreview) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_CUSTOM_LINK, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": link?.id,
        "Element Name": link.specialOffer?.title || link.title,
        "Element URL": link.specialOffer?.storeUrl,
        Platform: isMobile ? "Responsive" : "Web",
        "Link Type": "Special Offer",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      });
    }

    if (!link?.specialOffer?.couponCode) {
      link?.specialOffer?.storeUrl && window.open(link?.specialOffer?.storeUrl);
      return;
    }
    setShowModal(true);
  }, [
    isMobile,
    isPreview,
    link?.id,
    link.specialOffer?.couponCode,
    link.specialOffer?.storeUrl,
    link.specialOffer?.title,
    link.title,
    module?.id,
    module?.localizationId,
    module?.localizationName,
    module?.name,
    talent?.id,
    user,
  ]);

  return (
    <>
      <Row className={`link-card--special-offer cursor-pointer ${className}`} align="middle">
        <Col>
          <ImageProgessive
            ratio={0.25}
            threshold={[0.25, 1]}
            src={link.thumbnail}
            width={isMobile ? (isBig ? 115 : 96) : 140}
            height={isMobile ? (isBig ? 115 : 96) : 140}
          />
        </Col>
        <Col className={`flex--1 ${isMobile ? (isBig ? "p__x--16" : "p__x--12") : "p__x--16"}`}>
          <Paragraph ellipsis={{ rows: 2 }} className="m__b--0" preset={isMobile ? "regular12" : "regular18"}>
            {link.specialOffer?.title || link.title}
          </Paragraph>
          <Button className={`get-offer-btn ${isBig ? "m__t--16" : "m__t--8"}`} shape="round" onClick={onShowModal}>
            <Text preset={isMobile ? "semibold10" : "semibold16"}>Get The Offer</Text>
          </Button>
        </Col>
      </Row>
      <SpecialOfferModal
        show={showModal}
        toggleModal={() => setShowModal(false)}
        isMobile={isMobile}
        link={link}
        module={module}
        talent={talent}
      />
    </>
  );
};

//return interface for link swiper
interface ITalentDetailLinksSelf {
  links: LinkItem[];
  itemsRender: LinkItem[];
  params: any;
}

export function useTalentDetailLinksSelf(isMobile: boolean, module: TalentProfileModule): ITalentDetailLinksSelf {
  const [itemsRender, setItemsRender] = useState<LinkItem[]>([]);

  const links = useMemo(() => module?.items as LinkItem[], [module?.items]);

  const params: any = useMemo(
    () => ({
      slidesPerView: links?.length <= 3 ? 1 : isMobile ? 1.2508 : 1.5049,
      slidesPerColumn: links?.length <= 3 ? links?.length : 2,
      spaceBetween: links?.length <= 3 ? 16 : isMobile ? 0 : 24,
      freeMode: isMobile,
      navigation: !isMobile,
      centeredSlides: !isMobile,
      centeredSlidesBounds: !isMobile,
      slidesOffsetAfter: isMobile ? 16 : 0,
      noSwiping: isMobile,
    }),
    [isMobile, links],
  );

  useEffect(() => {
    if (links?.length) {
      setItemsRender([...links]);
    }
  }, [links]);

  return {
    links,
    itemsRender,
    params,
  };
}

const TalentDetailLinks: React.FC<IProps> = ({ className = "", isMobile, isPreview, module, talent, isGroup }) => {
  const { links, itemsRender, params } = useTalentDetailLinksSelf(isMobile, module);

  return links?.length > 0 ? (
    <div id={module.id} className={`talent-detail__links ${className}`}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "multiple-row": links?.length > 3,
            "p__x--16": isMobile && links?.length <= 3,
            "p__l--16": isMobile && links?.length > 3,
            "fix-width": links?.length <= 3,
          })}
          isMobile={isMobile}
          title={module.name}
          showTitle={module.showTitle}
          isGroup={isGroup}
          params={{ ...params }}
          items={itemsRender
            ?.filter((x) => x.visible)
            .map((link, i) =>
              link.specialOffer ? (
                <SpecialOfferLinkCard
                  talent={talent}
                  module={module}
                  isMobile={isMobile}
                  isPreview={isPreview}
                  key={i}
                  link={link}
                  isBig={links?.length <= 3}
                  className={isMobile && links?.length > 3 ? "m__r--16" : ""}
                />
              ) : (
                <LinkCard
                  talent={talent}
                  module={module}
                  isMobile={isMobile}
                  isPreview={isPreview}
                  key={i}
                  link={link}
                  isBig={links?.length <= 3}
                  className={isMobile && links?.length > 3 ? "m__r--16" : ""}
                />
              ),
            )}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailLinks;
