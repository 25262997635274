import { Text } from "components/Typography";
import { DIGITAL_DOWNLOAD_TYPES, MUSIC_LOGOS, STREAMING_TYPES } from "constants/music";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import React, { useCallback, useMemo } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { MusicItem as MusicItemElement, MusicItemLink, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Button = dynamic(() => import("antd/lib/button"));

interface MusicItemIProps {
  isMobile: boolean;
  module: TalentProfileModule;
  music: MusicItemLink;
  musicItem: MusicItemElement;
  isSingleElement?: boolean;
  talent?: Talent;
}

const MusicItem: React.FC<MusicItemIProps> = ({ music, module, isMobile, musicItem, isSingleElement, talent }) => {
  const user = useTypedSelector(selectUserData);

  const handlePlay = useCallback(() => {
    if ((window as any)?.analytics) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_MUSIC_PROVIDER, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": musicItem?.id,
        "Element Name": musicItem?.metadata?.name,
        Destination: music?.type,
        "Smart Link URL": music?.url,
        Platform: isMobile ? "Responsive" : "Web",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      });
    }
    window.open(music?.url, "_blank");
  }, [
    isMobile,
    module?.id,
    module?.localizationId,
    module?.localizationName,
    module?.name,
    music?.type,
    music?.url,
    musicItem?.id,
    musicItem?.metadata?.name,
    talent?.id,
    user,
  ]);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  return (
    <Row
      className={`music-item ${isSingleElement ? "" : isMobile ? "p__y--16" : "p__y--24"}`}
      justify="space-between"
      align="middle"
      data-testid="music-item"
    >
      <Col>
        <SVG
          className={isDarken ? "darken" : ""}
          src={`${MUSIC_LOGOS[music.type]}${isSingleElement ? "-white" : ""}.svg`}
        />
      </Col>
      <Col>
        <Button
          aria-label={(music.type + "").toLowerCase() + "-play-button"}
          className={`d--flex music-item${isSingleElement ? "--single" : ""}`}
          shape="round"
          onClick={handlePlay}
        >
          <Text preset={isMobile ? "medium12" : "semibold14"}>
            {STREAMING_TYPES.includes(music.type)
              ? "Play"
              : DIGITAL_DOWNLOAD_TYPES.includes(music.type)
              ? "Download"
              : "Buy"}
          </Text>
        </Button>
      </Col>
    </Row>
  );
};

export default MusicItem;
