import Button from "antd/lib/button";
import Row from "antd/lib/row";
import DesktopMainMenu from "components/DesktopMainMenu";
import ImageProgessive from "components/ImageProgessive";
import ImageSkeleton from "components/ImageSkeleton";
import { Text } from "components/Typography";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close.svg";
import { ReactComponent as MenuIcon } from "public/static/assets/icons/hamburger.svg";
import { ReactComponent as EmptyState } from "public/static/assets/icons/empty-state.svg";
import React, { useMemo, useState } from "react";
import { Talent } from "redux/Talent/types";
import { TalentProfileDisplayNameTypes } from "redux/User/types";
import { hexToRgbA } from "utils/color";
import { useResize } from "utils/responsive";
import classNames from "classnames";

const MIN_WIDTH = 375;

interface IProps {
  isMobile: boolean;
  isPreview?: boolean;
  talent: Talent;
  mobileMenuVisible: boolean;
  toggleMenuVisible(): void;
}

const TalentDetailHeader: React.FC<IProps> = ({
  isMobile,
  isPreview = false,
  talent,
  mobileMenuVisible,
  toggleMenuVisible,
}) => {
  const { width } = useResize();

  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const backgroundColor = useMemo(
    () => talent?.talentProfile?.themeColor?.backgroundColor || "#121212",
    [talent?.talentProfile?.themeColor?.backgroundColor],
  );

  const displayNameImage = useMemo(
    () => talent?.talentProfile?.displayNameImage,
    [talent?.talentProfile?.displayNameImage],
  );

  const __hotfixIsTankhouse = talent?.id === 110004;

  return (
    <>
      <div className="talent-detail__header">
        {talent?.hasBooking && (
          <div className="btn-menu-wrapper">
            {isMobile ? (
              <Button
                className="btn-menu text--white p--0"
                type="text"
                onClick={() => {
                  if (!isPreview) {
                    toggleMenuVisible();
                  }
                }}
              >
                {mobileMenuVisible ? <CloseIcon width={32} height={32} /> : <MenuIcon width={32} height={32} />}
              </Button>
            ) : (
              <div className="btn-menu text--white">
                <DesktopMainMenu
                  placement="bottomLeft"
                  icon={isOpenDropdown ? <CloseIcon width={32} height={32} /> : <MenuIcon width={32} height={32} />}
                  setOpenDropdown={setOpenDropdown}
                  talent={talent}
                  isPreview={isPreview}
                />
              </div>
            )}
          </div>
        )}
        <Row justify="center" className={isMobile ? "m__t--0" : "m__t--24"}>
          {!talent?.talentProfile?.avatar?.length && isPreview ? (
            <div className="empty-state empty-state--header">
              <EmptyState />
              <Text
                className={classNames({
                  "d--block m__t--20 text__align--center opacity--06": true,
                })}
                preset={isMobile ? "semibold16" : "semibold20"}
              >
                Profile Photo
              </Text>
            </div>
          ) : (
            <ImageProgessive
              className="talent-detail__header__avatar"
              ratio={0.5}
              threshold={[0.5, 1]}
              src={talent?.talentProfile?.avatar as string}
              borderRadius={isMobile ? 0 : 16}
              width={isMobile ? Math.max(width, MIN_WIDTH) : 366}
              height={isMobile ? 473 + (__hotfixIsTankhouse ? 100 : 0) : 461}
              __hotfixIsTankhouse={__hotfixIsTankhouse}
              showBorder={false}
            />
          )}
        </Row>

        <Row
          justify="center"
          className={`talent-detail__name m__t--${isMobile ? 0 : 16}`}
          style={
            isMobile
              ? {
                  background: `linear-gradient(180deg, ${hexToRgbA(backgroundColor, 0)} 0%, ${hexToRgbA(
                    backgroundColor,
                    __hotfixIsTankhouse ? 0.3 : 0.78,
                  )} 51.04%, ${hexToRgbA(backgroundColor, 1)} 100%)`,
                }
              : {}
          }
        ></Row>
      </div>

      {talent?.talentProfile?.showDisplayName &&
      talent?.talentProfile?.displayNameType === TalentProfileDisplayNameTypes.IMAGE ? (
        (displayNameImage?.length || 0) > 0 ? (
          <div className="talent-detail__name__image">
            <ImageSkeleton
              width={isMobile ? Math.max(width - 32, MIN_WIDTH) : 366}
              height={"auto" as any}
              borderRadius={8}
              style={{ width: talent?.talentProfile?.displayNameImageScale }}
              src={displayNameImage as string}
            />
          </div>
        ) : (
          <></>
        )
      ) : (
        <Text
          className={classNames({
            "talent-detail__name__text text__align--center": true,
            "p__x--16": isMobile,
            "empty opacity--06":
              isPreview && talent?.talentProfile?.showDisplayName && !talent?.talentProfile?.displayName,
          })}
          preset={isMobile ? "bold40" : "bold48"}
        >
          {talent?.talentProfile?.showDisplayName
            ? talent?.talentProfile?.displayName || (isPreview && "Display Name") || null
            : null}
        </Text>
      )}
    </>
  );
};

export default TalentDetailHeader;
