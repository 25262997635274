import moment from "moment";
import momentTZ from "moment-timezone";
import { DateOff } from "redux/Experience/types";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "a few secs",
    ss: "%d secs",
    m: "a min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

export const date = moment;
export const formatDate = (date: any, format: string) => moment(date).format(format);
export const dayAgo = (date: any) => {
  const now = moment(new Date());
  const end = moment(date); // another date
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  return days;
};

export const formatMinuteSecond = (second: number) => {
  return moment.utc(second * 1000).format("mm:ss");
};

export const formatHourMinuteSecond = (second: number) => {
  if (second <= 0) return "00:00";
  return moment.utc(second * 1000).format(second >= 3600 ? "HH:mm:ss" : "mm:ss");
};

export const formatMinuteSecondWithUnit = (second: number) => {
  return moment.utc(second * 1000).format("mm[m] ss[s]");
};

export const formatMinsAndSecs = (second: number) => {
  const mins = Math.floor(second / 60);
  const secs = second % 60;

  // return `${mins > 0 ? (mins === 1 ? `${mins} min` : `${mins} mins`) : ""} ${
  //   secs > 0 ? (secs === 1 ? `${secs} sec` : `${secs} secs`) : ""
  // }`;

  return `${mins > 0 ? (mins === 1 ? `${mins} min` : `${mins} mins`) : ""} ${
    secs > 0 ? (secs === 1 ? `${secs} sec` : `${secs} secs`) : ""
  }`;
};
export const formatMins = (second: number) => {
  const mins = Math.floor(second / 60);
  return mins > 0 ? (mins === 1 ? `${mins} Min` : `${mins} Mins`) : "";
};

export const formatTime = (date: Date) => moment(date).format("hh:mm a");

export const getDiffTime = (startDate: Date, endDate: Date): number => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.isBefore(start) ? 0 : moment.duration(end.diff(start)).asMinutes();
};

export const isDateOffs = (date: moment.Moment, dateOffs: DateOff[]): DateOff | undefined => {
  const dateTz = momentTZ(date).utcOffset(0).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return dateOffs?.find(({ date }) => {
    return moment(date).toISOString() === dateTz.toISOString();
  });
};

export const timeZoneFormatter = (
  timezone: string,
  options: { seperatorSymbol?: string; isCityFirst?: boolean } = {
    seperatorSymbol: " - ",
    isCityFirst: false,
  },
): string => {
  const { seperatorSymbol, isCityFirst } = options;
  const timeText = "Time";
  if (!timezone) return "";
  if (timezone?.split("/").length === 1) return `${timezone} ${timeText}`;
  const city = timezone?.split("/")[1];
  const zone = timezone?.split("/")[0];
  if (isCityFirst) return `${city}${seperatorSymbol}${zone} ${timeText}`;
  else return `${zone} ${timeText}${seperatorSymbol}${city}`;
};

export const todayStringFormat = () => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  return today.toDateString();
};

export const convertTimeLeftToDate = ({ diff, mostSignificantFigure = "day" }: any) => {
  let significance = ["year", "month", "day", "hour", "min", "sec"];

  let year = Math.floor(diff / 31104000000); // time diff in years
  let month = Math.floor((diff / 2592000000) % 12); // time diff in months (modulated to 12)
  let day = Math.floor((diff / 86400000) % 30); // time diff in days (modulated to 30)
  let hour = Math.floor((diff / 3600000) % 24); // time diff's hours (modulated to 24)
  let min = Math.floor((diff / 60000) % 60); // time diff's minutes (modulated to 60)
  let sec = Math.floor((diff / 1000) % 60); // time diff's seconds (modulated to 60)

  if (mostSignificantFigure === "none") {
    if (year === 0) {
      significance = significance.slice(1);
      if (month === 0) {
        significance = significance.slice(1);
        if (day === 0) {
          significance = significance.slice(1);
          if (hour === 0) {
            significance = significance.slice(1);
            if (min === 0) {
              significance = significance.slice(1);
            }
          }
        }
      }
    }
  } else {
    significance = significance.slice(significance.indexOf(mostSignificantFigure));
  }

  if (significance.indexOf("year") === -1) {
    month += year * 12;
    year = 0;
  }
  if (significance.indexOf("month") === -1) {
    day += month * 30;
    month = 0;
  }
  if (significance.indexOf("day") === -1) {
    hour += day * 24;
    day = 0;
  }
  if (significance.indexOf("hour") === -1) {
    min += hour * 60;
    hour = 0;
  }
  if (significance.indexOf("min") === -1) {
    sec += min * 60;
    min = 0;
  }
  return {
    year,
    month,
    day,
    hour,
    min,
    sec,
  };
};
