import { Text } from "components/Typography";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import ct from "countries-and-timezones";
import moment from "moment";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useCallback, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { selectLoadingTalent } from "redux/Talent/selector";
import { PresaveResponse, Talent } from "redux/Talent/types";
import { selectListUserPreSaveApple, selectListUserPreSaveSpotify, selectUserData } from "redux/User/selector";
import {
  GetListUserPreSaveResult,
  MusicItem as MusicItemElement,
  MusicItemLink,
  MusicItemType,
  TalentProfileModule,
} from "redux/User/types";
import { handleSpotifySignIn, KOMI_SPOTIFY_REFRESH_TOKEN } from "services/SpotifyService";
import { authorizeMusicKit, unauthorizeMusicKit } from "./musicKit";
import { dispatchPresave } from "services/PresaveService";
import { trackEvent } from "utils/trackEvent";
import { MARKETING_PERMISSIONS } from "constants/auth";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Button = dynamic(() => import("antd/lib/button"));

interface MusicItemIProps {
  isMobile: boolean;
  module: TalentProfileModule;
  music: MusicItemLink;
  musicItem: MusicItemElement;
  isActive: boolean;
  isSingleElement?: boolean;
  setCurrentPreSaveType(type: any): void;
  talent?: Talent;
  isPreview?: boolean;
  saveMarketingPermissions: (userID: string, platform: string, email?: string) => void;
}

const LOGOS: any = {
  [MusicItemType.SPOTIFY]: "/static/assets/icons/spotify-music",
  [MusicItemType.APPLE_MUSIC]: "/static/assets/icons/apple-music",
};

const userTz = moment.tz.guess();
const userUtcOffset = moment.tz(userTz).utcOffset();
const country = ct.getTimezone(userTz)?.countries;

const PreReleaseMusicItem: React.FC<MusicItemIProps> = ({
  music,
  module,
  isMobile,
  musicItem,
  setCurrentPreSaveType,
  isActive,
  isSingleElement = false,
  talent,
  isPreview,
  saveMarketingPermissions,
}) => {
  const router = useRouter();

  const user = useTypedSelector(selectUserData);
  const loading = useTypedSelector(selectLoadingTalent);

  // TODO: This is not going to scale
  const spotifyPresave = useTypedSelector(selectListUserPreSaveSpotify);
  const applePresave = useTypedSelector(selectListUserPreSaveApple);

  const [addingAppleMusic, setAddingAppleMusic] = useState(false);

  const isPreSavedSpotify = useMemo(
    () => spotifyPresave?.map((item: GetListUserPreSaveResult) => item?.musicItemId)?.includes(musicItem?.id),
    [musicItem?.id, spotifyPresave],
  );

  const isPreSavedApple = useMemo(
    () => applePresave?.map((item: GetListUserPreSaveResult) => item?.musicItemId)?.includes(musicItem?.id),
    [musicItem?.id, applePresave],
  );

  const contextualTrackEvent = useCallback(
    (eventType: SEGMENT_EVENT) => trackEvent(eventType, isMobile, user, module, musicItem, music, talent),
    [isMobile, module, music, musicItem, talent, user],
  );

  /**
   *  Creates a presave dispatcher using the specified token
   */
  const presave = useCallback(
    (token: string | undefined) => {
      return dispatchPresave(
        {
          moduleId: module?.id,
          musicId: musicItem?.id,
          token: token || "",
          utcOffset: userUtcOffset,
          link: music.url,
          linkType: music.type,
          country: country ? country[0] : "",
        },
        (userPreSave: PresaveResponse) => {
          /* Trigger the marketing permission modal logic after presave is complete and return response object */
          saveMarketingPermissions(userPreSave.response.userID, music.type, userPreSave.response?.email);
          contextualTrackEvent(SEGMENT_EVENT.SUCCESSFULL_PRE_SAVE);
        },
        async () => {
          switch (music.type) {
            case MusicItemType.APPLE_MUSIC:
              await unauthorizeMusicKit();
          }
        },
      );
    },
    [contextualTrackEvent, module?.id, music?.type, music.url, musicItem?.id],
  );

  /**
   * Presave the music item using Apple Music.
   *
   * @return {any} The dispatcher for the presave action
   */
  const presaveAppleMusic = useCallback(async () => {
    const link = musicItem.links.find((link) => link.type === MusicItemType.APPLE_MUSIC);
    if (!link) {
      return;
    }

    // Store in case the window loads instead of opens
    localStorage.setItem("presavedUrl", link.url);
    localStorage.setItem("isPresaveSingleElement", isSingleElement.toString());

    const musicUserToken = await authorizeMusicKit();

    setAddingAppleMusic(true);
    presave(musicUserToken);
    setAddingAppleMusic(false);

    // If we've made it this far, we don't need the localStorage items
    localStorage.removeItem("presavedUrl");
    localStorage.removeItem("isPresaveSingleElement");
  }, [isSingleElement, musicItem.links, presave]);

  /**
   * Presave the music item using Spotify.
   * This may be extractable outside of the component
   *
   * @return {any} The dispatcher for the presave action
   */
  const presaveSpotify = useCallback(async () => {
    const spotifyRefreshToken = Cookies.get(KOMI_SPOTIFY_REFRESH_TOKEN);
    const marketingPermissions = Cookies.get(MARKETING_PERMISSIONS.SPOTIFY);

    if (spotifyRefreshToken && marketingPermissions) {
      presave(spotifyRefreshToken);
    } else {
      const link = musicItem.links.find((link) => link.type === MusicItemType.SPOTIFY);
      if (!link) {
        return;
      }

      localStorage.setItem("presavedUrl", link.url);
      localStorage.setItem("isPresaveSingleElement", isSingleElement.toString());

      await handleSpotifySignIn();
    }
  }, [isSingleElement, musicItem.links, presave]);

  /**
   * Click handler for executing the appropriate presave strategy
   *
   * @returns {Promise} The presave strategy promise
   */
  const handlePreSaveClick = useCallback(() => {
    setCurrentPreSaveType(music.type);

    if ((window as any)?.analytics) {
      contextualTrackEvent(SEGMENT_EVENT.CLICK_PRE_SAVE_PROVIDER);
    }

    // Handle Komi managed presaves
    if (musicItem.releaseType === "KOMI") {
      switch (music.type) {
        case MusicItemType.APPLE_MUSIC:
          return presaveAppleMusic();

        case MusicItemType.SPOTIFY:
          return presaveSpotify();

        // TODO: Throw an error when we have error boundaries
        // default:
        //   throw new Error(`Unrecognized music item type "${music.type}"`);
      }
    }

    // Handle custom presaves
    if (!isPreview) {
      router.push(music?.url);
    }

    // TODO: What if it meets none of the above criteria? Should the `!isPreview` block return,
    //   and there be some form of throw here?
  }, [
    setCurrentPreSaveType,
    music.type,
    music?.url,
    musicItem.releaseType,
    isPreview,
    contextualTrackEvent,
    presaveAppleMusic,
    presaveSpotify,
    router,
  ]);

  const preSaveButton = useMemo(
    () =>
      music.type === MusicItemType.CUSTOM_LINK ? (
        <Button
          className={`d--flex ant-btn--border btn-presave--custom music-item--single height--${isMobile ? "32" : "36"
            } m__t--0`}
          shape="round"
          onClick={handlePreSaveClick}
        >
          <Text preset={"semibold14"}>Pre-Save / Pre-Add</Text>
        </Button>
      ) : (
        <Button
          className={`d--flex music-item${isSingleElement ? "--single" : ""}`}
          aria-label={(music.type + "").toLowerCase() + "-pre-release-button"}
          shape="round"
          disabled={music?.type === MusicItemType.SPOTIFY ? isPreSavedSpotify : isPreSavedApple}
          loading={(isActive && loading) || addingAppleMusic}
          onClick={handlePreSaveClick}
        >
          <Text preset={isMobile ? "medium12" : "semibold14"}>
            {music.type === MusicItemType.SPOTIFY
              ? isPreSavedSpotify
                ? "Pre-saved"
                : "Pre-save"
              : isPreSavedApple
                ? "Pre-added"
                : "Pre-add"}
          </Text>
        </Button>
      ),
    [
      addingAppleMusic,
      handlePreSaveClick,
      isActive,
      isMobile,
      isPreSavedApple,
      isPreSavedSpotify,
      isSingleElement,
      loading,
      music.type,
    ],
  );

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  return (
    <Row
      className={`music-item ${isSingleElement ? "" : "p__y--16"}`}
      justify={music.type !== MusicItemType.CUSTOM_LINK ? "space-between" : "center"}
      align="middle"
    >
      {music.type !== MusicItemType.CUSTOM_LINK && (
        <Col className="d--flex align__items--center" data-testid="prerelease--logo">
          <SVG
            className={`${isDarken ? "darken" : ""} ${music.type.toLowerCase()}`}
            src={`${LOGOS[music.type]}${isSingleElement ? "-white.svg" : ".svg"}`}
            width={music.type === MusicItemType.SPOTIFY ? 80 : 84}
          />
        </Col>
      )}
      <Col>{preSaveButton}</Col>
    </Row>
  );
};

export default PreReleaseMusicItem;
