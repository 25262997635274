import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageProgessive from "components/ImageProgessive";
import PodcastModal from "components/PodcastModal";
import PodcastItemComponent from "components/PodcastModal/PodcastItem";
import Swiper from "components/Swiper";
import { Paragraph, Text } from "components/Typography";
import { PODCAST_LOGOS } from "constants/music";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { ReactComponent as PauseIcon } from "public/static/assets/icons/pause2.svg";
import { ReactComponent as PlayIcon } from "public/static/assets/icons/play2.svg";
import { ReactComponent as PlayIconOpacity } from "public/static/assets/icons/play5.svg";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { PodcastItem, TalentProfileModule, PodcastItemType } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { hexToRgbA } from "utils/color";
import { convertToUrl } from "utils/string";

const MINIMUM_ELEMENTS_SLIDER = 3;

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

interface IPodcastCardProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  podcast: PodcastItem;
  activePodcast: PodcastItem | null;
  setActivePodcast(podcast: PodcastItem | null): void;
  isBig?: boolean;
  isPreview?: boolean;
  isSingleElement?: boolean;
  talent?: Talent;
}

const PodcastCard = ({
  isMobile,
  isPreview,
  className = "",
  setActivePodcast,
  podcast,
  activePodcast,
  isBig,
  module,
  isSingleElement,
  talent,
}: IPodcastCardProps) => {
  const ref = useRef<any>();
  const router = useRouter();

  const user = useTypedSelector(selectUserData);

  const [isPlaying, setPlaying] = useState(false);
  const [showModal, toggleModal] = useState(false);

  const artists = useMemo(
    () => podcast?.metadata?.artists?.map((artist: any) => artist?.name)?.join(", "),
    [podcast?.metadata?.artists],
  );

  const publisher = useMemo(() => podcast?.metadata?.publisher, [podcast]);

  const owner = useMemo(() => podcast?.metadata?.owner?.display_name, [podcast]);

  const image = useMemo(() => podcast?.metadata?.images?.[0]?.url, [podcast?.metadata?.images]);

  const links = useMemo(() => podcast?.links?.filter((link) => link?.url?.length).slice(0, 3), [podcast?.links]);

  const handlePlayOrPause = useCallback(() => {
    if (isPreview) return;
    setActivePodcast(podcast);
    if (ref.current.paused) {
      if ((window as any)?.analytics) {
        AnalyticServices.track(SEGMENT_EVENT.CLICK_PREVIEW_PODCAST, {
          "User id": user?.id,
          Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
          Location: Cookies.get(KOMI_USER_LOCATION),
          "Talent ID": talent?.id,
          "Module ID": module?.id,
          "Module Name": module?.name,
          "Element ID": podcast?.id,
          "Element Name": podcast?.metadata?.name,
          "Element URL": podcast?.metadata?.externalUrls?.spotify,
          Platform: isMobile ? "Responsive" : "Web",
          "Page ID": module?.localizationId || null,
          "Page Name": module?.localizationName || "Default",
          "Is Automated": "No",
        });
      }
      ref.current.play();
      setPlaying(true);
    } else {
      ref.current.pause();
      setPlaying(false);
    }
  }, [
    isPreview,
    setActivePodcast,
    podcast,
    user,
    talent?.id,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    isMobile,
  ]);

  const handleStop = useCallback(() => {
    ref.current.pause();
    setPlaying(false);
    ref.current.currentTime = 0;
  }, []);

  const handlePlayFullSong = useCallback(() => {
    if ((window as any)?.analytics && !isPreview) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_PLAY_FULL_PODCAST, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": podcast?.id,
        "Element Name": podcast?.metadata?.name,
        Platform: isMobile ? "Responsive" : "Web",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
        "Is Automated": "No",
      });
    }
    setActivePodcast(null);
    handleStop();
    toggleModal(true);
  }, [
    isPreview,
    setActivePodcast,
    handleStop,
    user,
    talent?.id,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    podcast?.id,
    podcast?.metadata?.name,
    isMobile,
  ]);

  const backgroundColor = useMemo(
    () => talent?.talentProfile?.themeColor?.backgroundColor || "#121212",
    [talent?.talentProfile?.themeColor?.backgroundColor],
  );

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  const handlePlayMusicProvider = useCallback(
    (link: any) => {
      if ((window as any)?.analytics) {
        AnalyticServices.track(SEGMENT_EVENT.CLICK_PODCAST_PROVIDER, {
          "User id": user?.id,
          Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
          Location: Cookies.get(KOMI_USER_LOCATION),
          "Talent ID": talent?.id,
          "Module ID": module?.id,
          "Module Name": module?.name,
          "Element ID": podcast?.id,
          "Element Name": podcast?.metadata?.name,
          Destination: link?.type,
          "Smart Link URL": link?.url,
          Platform: isMobile ? "Responsive" : "Web",
          "Page ID": module?.localizationId || null,
          "Page Name": module?.localizationName || "Default",
          "Is Automated": "No",
        });
      }
      window.open(link?.url, "_blank");
    },
    [
      isMobile,
      module?.id,
      module?.localizationId,
      module?.localizationName,
      module?.name,
      podcast?.id,
      podcast?.metadata?.name,
      talent?.id,
      user,
    ],
  );

  useEffect(() => {
    if (
      !isPreview &&
      process.browser &&
      module &&
      !router?.query?.id &&
      router.asPath.includes("podcast") &&
      router?.query?.slug?.[0] === (podcast?.customUrl || convertToUrl(podcast.metadata.name))
    ) {
      setTimeout(() => {
        const moduleEl = document.getElementById(module.id);
        if (moduleEl) {
          moduleEl.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
      handlePlayFullSong();
    }
  }, [
    handlePlayFullSong,
    isPreview,
    module,
    podcast?.customUrl,
    podcast.id,
    podcast.metadata.name,
    router,
    router?.query?.id,
    router?.query?.slug,
  ]);

  useEffect(() => {
    if (activePodcast?.id !== podcast?.id) {
      handleStop();
    }
  }, [activePodcast?.id, handleStop, podcast?.id]);

  return (
    <>
      {isSingleElement ? (
        <div
          className={`link-card music-card single ${isDarken && "darken"} ${
            isMobile ? "p--16 p__t--14 m__t--24" : "p--24 p__b--24 m__t--32"
          } overflow--visible ${isBig && "big"} ${className}`}
        >
          <Row>
            <Col className="music-card__left">
              <ImageProgessive
                ratio={0.25}
                threshold={[0.25, 1]}
                borderRadius={16}
                src={image}
                width={isMobile ? 120 : 160}
                height={isMobile ? 120 : 160}
              />
              {podcast?.metadata?.previewUrl && (
                <Button type="text" className="ant-btn-md p--0" onClick={handlePlayOrPause}>
                  {isPlaying ? <PauseIcon /> : <PlayIconOpacity />}
                </Button>
              )}
            </Col>
            <Col className={`music-card__right flex--1 ${isMobile ? "p__l--16" : "p__l--24"}`}>
              <Paragraph ellipsis={{ rows: 1 }} preset={isMobile ? "semibold18" : "semibold24"}>
                {podcast?.metadata?.name}
              </Paragraph>
              {(publisher || owner || artists) && (
                <Paragraph
                  ellipsis={{ rows: 1 }}
                  className={`${isMobile ? "m__t--4" : "m__t--8"} d--block opacity--05`}
                  preset={isMobile ? "regular14" : "regular18"}
                >
                  {publisher || owner || artists}
                </Paragraph>
              )}
            </Col>
          </Row>
          {links?.length === 3 ? (
            <Row
              className={`music-card__provider m__t--24`}
              style={{ background: hexToRgbA(backgroundColor, 0.8) }}
              align="middle"
              justify="center"
            >
              {links?.map(
                (link, i) =>
                  i < 2 && (
                    <React.Fragment key={i}>
                      <Col className={"flex--1"} key={i}>
                        <a
                          onClick={() => handlePlayMusicProvider(link)}
                          className="d--flex justify__content--center"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SVG
                            src={
                              link.type === PodcastItemType.AUDIBLE
                                ? `${PODCAST_LOGOS[link.type]}.svg`
                                : `${PODCAST_LOGOS[link.type]}-white.svg`
                            }
                          />
                        </a>
                      </Col>
                      {i + 1 < links?.length && (
                        <Col>
                          <div className={`music-card__provider__divider`} />
                        </Col>
                      )}
                    </React.Fragment>
                  ),
              )}
              {links?.length === 3 && (
                <Col className="text__align--center flex--1">
                  <Button
                    className={`p--0 ${isMobile ? "height--20" : "height--24"}`}
                    type="text"
                    onClick={handlePlayFullSong}
                  >
                    <Text
                      className={isMobile ? "line-height--16" : "line-height--24"}
                      preset={isMobile ? "semibold12" : "semibold18"}
                    >
                      More
                    </Text>
                  </Button>
                </Col>
              )}
            </Row>
          ) : (
            <div className="m__t--24">
              {links?.map((link: any, i: number) => (
                <React.Fragment key={i}>
                  <PodcastItemComponent
                    key={i}
                    talent={talent}
                    module={module}
                    podcast={link}
                    podcastItem={podcast}
                    isMobile={isMobile}
                    isSingleElement
                  />
                  {i + 1 < links?.length && <Divider className={`d--block m__y--${isMobile ? 20 : 24}`} />}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Row className={`link-card music-card ${isBig && "big"} ${className}`} align="middle">
          <Col className="music-card__left">
            <ImageProgessive
              ratio={0.25}
              threshold={[0.25, 1]}
              src={image}
              width={isMobile ? (isBig ? 115 : 96) : 140}
              height={isMobile ? (isBig ? 115 : 96) : 140}
            />
            {podcast?.metadata?.previewUrl && (
              <Button type="text" className="p--0" onClick={handlePlayOrPause}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </Button>
            )}
          </Col>
          <Col
            className={`music-card__right flex--1 ${
              isMobile ? (isBig ? "p__x--16" : "p__x--12") : isBig ? "p__x--24" : "p__x--16"
            }`}
          >
            <Paragraph
              ellipsis={{ rows: 1 }}
              preset={isMobile ? "semibold14" : isBig ? "semibold20" : "semibold16"}
              className={`${isMobile ? (isBig ? "line-height--22" : "line-height--20") : ""}`}
            >
              {podcast?.metadata?.name}
            </Paragraph>
            {(publisher || owner || artists) && (
              <Paragraph
                ellipsis={{ rows: 1 }}
                className={`${!isBig && !isMobile ? "" : "m__t--4"} music-card__singer opacity--05`}
                preset={isMobile ? (isBig ? "regular14" : "regular12") : isBig ? "regular18" : "regular14"}
              >
                {publisher || owner || artists}
              </Paragraph>
            )}
            <Button
              className={`btn-play-full ${
                isMobile ? "m__t--12 p__x--8" : isBig ? "m__t--16 p__x--16" : "m__t--12 p__x--16"
              }`}
              onClick={handlePlayFullSong}
            >
              <Text preset={isMobile ? "semibold10" : "semibold16"}>Play</Text>
            </Button>
          </Col>
        </Row>
      )}
      <audio ref={ref} src={podcast?.metadata?.previewUrl} onEnded={() => handleStop()} controls className="d--none" />
      {showModal && (
        <PodcastModal
          talent={talent}
          podcastItem={podcast}
          isMobile={isMobile}
          module={module}
          links={podcast?.links}
          artists={publisher || owner || artists}
          name={podcast?.metadata?.name}
          image={image}
          show={showModal}
          toggleModal={toggleModal}
          isPreview={isPreview}
        />
      )}
    </>
  );
};

const TalentDetailPodcast: React.FC<IProps> = ({ className = "", isMobile, isPreview, module, talent, isGroup }) => {
  const [activePodcast, setActivePodcast] = useState<PodcastItem | null>(null);

  const songs = useMemo(() => module?.items as PodcastItem[], [module?.items]);

  const params: any = useMemo(
    () => ({
      slidesPerView: songs?.length <= MINIMUM_ELEMENTS_SLIDER ? 1 : isMobile ? 1.2508 : 1.5123,
      slidesPerColumn: songs?.length <= MINIMUM_ELEMENTS_SLIDER ? songs?.length : 2,
      spaceBetween: songs?.length <= MINIMUM_ELEMENTS_SLIDER ? 16 : isMobile ? 0 : 24,
      noSwiping: true,
      navigation: !isMobile,
      centeredSlides: !isMobile,
      centeredSlidesBounds: !isMobile,
      slidesOffsetAfter: isMobile ? 16 : 0,
    }),
    [isMobile, songs?.length],
  );

  return songs?.length > 0 ? (
    <div id={module.id} className={`talent-detail__music ${className}`}>
      <>
        <Swiper
          key={songs?.length}
          showShadow={!isMobile}
          className={classNames({
            "multiple-row": songs?.length > MINIMUM_ELEMENTS_SLIDER,
            "p__x--16": isMobile && songs?.length <= MINIMUM_ELEMENTS_SLIDER,
            "p__l--16": isMobile && songs?.length > MINIMUM_ELEMENTS_SLIDER,
            "fix-width": songs?.length <= MINIMUM_ELEMENTS_SLIDER,
          })}
          isMobile={isMobile}
          title={module?.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          items={songs
            ?.filter((x) => x.visible)
            .map((song, i) => (
              <PodcastCard
                talent={talent}
                isSingleElement={songs?.length === 1}
                module={module}
                isMobile={isMobile}
                isPreview={isPreview}
                key={song?.id}
                podcast={song}
                setActivePodcast={setActivePodcast}
                activePodcast={activePodcast}
                isBig={songs?.length <= MINIMUM_ELEMENTS_SLIDER}
                className={isMobile && songs?.length > MINIMUM_ELEMENTS_SLIDER ? "m__r--16" : ""}
              />
            ))}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailPodcast;
