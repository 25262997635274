import { Col, Row } from "antd/lib/grid";
import ImageProgessive from "components/ImageProgessive";
import React from "react";
import { Paragraph } from "components/Typography";
import classnames from "classnames";
import { ReactComponent as EmptyStateLighten } from "public/static/assets/icons/empty-state-lighten.svg";
import { ReactComponent as EmptyStateDarken } from "public/static/assets/icons/empty-state-darken.svg";

interface IProps {
  layout: "left" | "center";
  image: string | null | undefined;
  isDraken: boolean;
  isImageOn: boolean;
  title: string;
  subtitle: string;
  isMobile?: boolean;
  className?: string;
  isGroup?: boolean;
}

const DataCaptureLayout: React.FC<IProps> = ({
  layout,
  image,
  isMobile,
  isDraken,
  className = "",
  isImageOn,
  title = "Add a title",
  subtitle,
  isGroup,
}) => {
  return (
    <div className={className}>
      {isImageOn ? (
        layout === "left" ? (
          <Row
            align="middle"
            className={classnames({
              "m__l--80 p__y--20 p__x--32": !isMobile,
              "m__l--60 p--16": isMobile,
            })}
            style={{
              borderRadius: isGroup ? "8px" : "16px",
              background:
                "linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color)",
            }}
          >
            <Col style={{ marginLeft: isMobile ? "-76px" : "-112px" }}>
              {image ? (
                <ImageProgessive
                  ratio={0.25}
                  threshold={[0.25, 1]}
                  borderRadius={16}
                  src={image || ""}
                  width={isMobile ? 120 : 160}
                  height={isMobile ? 120 : 160}
                />
              ) : (
                <div
                  className="empty-state m__t--0"
                  style={{
                    width: isMobile ? 120 : 160,
                    height: isMobile ? 120 : 160,
                    background: isDraken
                      ? "linear-gradient(0deg, rgba(18, 18, 18, 0.2), rgba(18, 18, 18, 0.2)), var(--ant-primary-color)"
                      : "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), var(--ant-primary-color)",
                  }}
                >
                  {isDraken ? <EmptyStateDarken /> : <EmptyStateLighten />}
                </div>
              )}
            </Col>
            <Col
              className={classnames("flex--1", {
                "m__l--32": !isMobile,
                "m__l--16": isMobile,
              })}
            >
              <Paragraph ellipsis={{ rows: 2 }} preset={isMobile ? "semibold18" : "semibold24"}>
                {title}
              </Paragraph>
              {subtitle && (
                <Paragraph
                  className={classnames({
                    "opacity--05": true,
                    "m__t--8": !isMobile,
                    "m__t--2": isMobile,
                  })}
                  ellipsis={{ rows: isMobile ? 3 : 2 }}
                  preset={isMobile ? "regular14" : "regular18"}
                >
                  {subtitle}
                </Paragraph>
              )}
            </Col>
          </Row>
        ) : (
          <div
            className="d--flex flex__direction--column align__items--center"
            style={{
              borderRadius: isGroup ? "8px" : "16px",
              background:
                "linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "69px",
                background: isDraken ? "rgba(18, 18, 18, 0.1)" : "rgba(255, 255, 255, 0.1)",
                borderRadius: isGroup ? "6px 6px 0 0" : "16px 16px 0 0",
              }}
            ></div>
            <div style={{ marginTop: isMobile ? "-54px" : "-49px" }}>
              {image ? (
                <ImageProgessive
                  shape="circle"
                  ratio={0.25}
                  threshold={[0.25, 1]}
                  src={image || ""}
                  width={isMobile ? 100 : 120}
                  height={isMobile ? 100 : 120}
                />
              ) : (
                <div
                  className="empty-state m__t--0"
                  style={{
                    width: isMobile ? 120 : 160,
                    height: isMobile ? 120 : 160,
                    background: "linear-gradient(0deg, var(--ant-primary-color), var(--ant-primary-color))",
                    borderRadius: "100%",
                  }}
                >
                  {isDraken ? <EmptyStateDarken /> : <EmptyStateLighten />}
                </div>
              )}
            </div>
            <div
              style={{ width: "100%", padding: subtitle ? "0 32px 20px" : isMobile ? "0 32px 40px" : "0 32px 56px" }}
            >
              <Paragraph
                className={classnames({
                  "text__align--center": true,
                  "m__t--20": !isMobile,
                  "m__t--16": isMobile,
                })}
                ellipsis={{ rows: 1 }}
                preset={isMobile ? "semibold18" : "semibold24"}
              >
                {title}
              </Paragraph>
              {subtitle && (
                <Paragraph
                  className={classnames({
                    "opacity--05 text__align--center": true,
                    "m__t--8": !isMobile,
                    "m__t--2": isMobile,
                  })}
                  ellipsis={{ rows: 2 }}
                  preset={isMobile ? "regular14" : "regular18"}
                >
                  {subtitle}
                </Paragraph>
              )}
            </div>
          </div>
        )
      ) : (
        <Row
          align="middle"
          justify={layout === "left" ? "start" : "center"}
          className={classnames({
            "p--32": !isMobile,
            "p--24": isMobile,
            "p__y--36": isMobile && !subtitle,
            "p__y--50": !isMobile && !subtitle,
          })}
          style={{
            borderRadius: isGroup ? "8px" : "16px",
            background: "linear-gradient(var(--ant-success-color), var(--ant-success-color)), var(--ant-primary-color)",
          }}
        >
          <Col
            className={classnames({
              "text__align--center": layout === "center",
            })}
          >
            <Paragraph ellipsis={{ rows: 1 }} preset={isMobile ? "semibold18" : "semibold24"}>
              {title}
            </Paragraph>
            {subtitle && (
              <Paragraph
                className={classnames({
                  "opacity--05": true,
                  "m__t--8": !isMobile,
                  "m__t--2": isMobile,
                })}
                ellipsis={{ rows: 2 }}
                preset={isMobile ? "regular14" : "regular18"}
              >
                {subtitle}
              </Paragraph>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DataCaptureLayout;
