import { KomiLogo } from "@komi-app/components";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Divider from "antd/lib/divider";
import AvatarProfile from "components/AvatarProfile";
import ImageProgessive from "components/ImageProgessive";
import ImageSkeleton from "components/ImageSkeleton";
import Modal from "components/Modal";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Experience } from "redux/Experience/types";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { joinFanClubActions, updateUserProfileActions } from "redux/User/actions";
import {
  selectJoinFanClubError,
  selectJoinFanClubSuccess,
  selectUserData,
  selectUserLoading,
} from "redux/User/selector";
import { getTalentName } from "utils/experience";
import notification from "utils/notification";
import { Paragraph, Text } from "../Typography";

interface IProps {
  show: boolean;
  onOk(): void;
  onCancel(): void;
  talent?: Talent;
  experience?: Experience;
  setIsJoiningFan?(status: boolean): void;
  onJoinFanSuccess?(): void;
}

const JoinFanClubModal: React.FC<IProps> = ({
  show,
  onOk,
  onCancel,
  talent,
  experience,
  setIsJoiningFan,
  onJoinFanSuccess,
}) => {
  const dispatch = useDispatch();

  const user = useTypedSelector(selectUserData);
  const loading = useTypedSelector(selectUserLoading);
  const isJoinSuccess = useTypedSelector(selectJoinFanClubSuccess);
  const error = useTypedSelector(selectJoinFanClubError);

  const [allowedMarketingEmailTalents, setAllowedMarketingEmailTalents] = useState<any>([
    "allowedMarketingEmailTalents",
  ]);

  const fanClubName = useMemo(
    () =>
      talent?.talentProfile?.fanClubName?.length
        ? talent?.talentProfile?.fanClubName
        : `${getTalentName(talent, true)}’s fan club`,
    [talent],
  );

  const fanClubBenefits = useMemo(
    () => talent?.talentProfile?.fanClubBenefits || "exclusive benefits",
    [talent?.talentProfile?.fanClubBenefits],
  );

  const titleEl = useMemo(
    () => (
      <div className="d--flex avatar-items">
        <div className="avatar-items__item">
          <div className="avatar-items__item__background"></div>
          <ImageProgessive
            src={talent?.talentProfile?.avatar as string}
            shape="circle"
            width={isMobile ? 54 : 62}
            height={isMobile ? 54 : 62}
          />
        </div>
        <div className="avatar-items__item">
          <div className="avatar-items__item__background"></div>
          <AvatarProfile
            avatar={user?.avatar as string}
            name={`${user?.firstName} ${user?.lastName}`}
            shape="circle"
            avatarSize={isMobile ? 54 : 62}
          />
        </div>
      </div>
    ),
    [talent?.talentProfile?.avatar, user?.avatar, user?.firstName, user?.lastName],
  );

  const termOptions = useMemo(() => {
    const terms: any = [];

    if (talent) {
      terms.push({
        label: (
          <Text preset="regular14">{`I would like to occasionally receive special offers, promotions and news from ${getTalentName(
            talent,
          )}`}</Text>
        ),
        value: "allowedMarketingEmailTalents",
      });
    }
    return terms;
  }, [talent]);

  const handleConnect = useCallback(() => {
    setIsJoiningFan?.(true);
    if (
      allowedMarketingEmailTalents.includes("allowedMarketingEmailTalents") &&
      !user?.allowedMarketingEmailTalents?.map((item: any) => item?.id)?.includes(talent?.id)
    ) {
      dispatch(
        updateUserProfileActions.REQUEST({
          allowedMarketingEmailTalents: [...(user?.allowedMarketingEmailTalents || []), { id: talent?.id }],
        }),
      );
    }
    dispatch(
      joinFanClubActions.REQUEST({
        talentProfileId: talent?.talentProfile?.id,
        onJoinFanSuccess,
      }),
    );
  }, [
    setIsJoiningFan,
    allowedMarketingEmailTalents,
    dispatch,
    talent?.id,
    talent?.talentProfile?.id,
    user?.allowedMarketingEmailTalents,
  ]);

  useEffect(() => {
    if (isJoinSuccess) {
      setIsJoiningFan?.(false);
      onOk();
    }
  }, [isJoinSuccess, onOk, setIsJoiningFan]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
      });
    }
  }, [error, onCancel]);

  return (
    <Modal
      className="join-fan-club-modal"
      closable
      title={titleEl}
      visible={show}
      onOk={onOk}
      footer={
        <>
          <div className="divider"></div>
          <KomiLogo height={isMobile ? 20 : 24} />
        </>
      }
      onCancel={onCancel}
      width={558}
      centered
      closeIcon={<CloseIcon />}
    >
      <Paragraph preset={"semibold20"}>{`Welcome, ${user?.firstName}!`}</Paragraph>
      <Paragraph preset={isMobile ? "regular14" : "regular16"} className="desc">
        {experience
          ? `To access ${fanClubName} and unlock ${
              experience?.name
            } you’ll need to connect your Komi account with ${getTalentName(talent, true)}’s fan club`
          : `To access ${fanClubName} and unlock ${fanClubBenefits} you’ll need to connect your Komi account with ${fanClubName}`}
      </Paragraph>

      {isMobile && <Divider className="m__y--16" />}

      {termOptions?.length > 0 &&
        !user?.allowedMarketingEmailTalents?.map((item: any) => item?.id)?.includes(talent?.id) && (
          <Checkbox.Group
            defaultValue={["allowedMarketingEmailTalents"]}
            onChange={(val) => {
              setAllowedMarketingEmailTalents(val);
            }}
            className="term-checkbox"
            options={termOptions}
          />
        )}

      <Button
        loading={loading}
        type="primary"
        block
        className={isMobile ? "m__t--16" : "m__t--24"}
        onClick={handleConnect}
      >
        connect
      </Button>
    </Modal>
  );
};

export default JoinFanClubModal;
