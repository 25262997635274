import { Button, Divider } from "antd";
import { Col, Row } from "antd/lib/grid";
import classNames from "classnames";
import AvatarProfile from "components/AvatarProfile";
import ImageSkeleton from "components/ImageSkeleton";
import Modal from "components/Modal";
import { Option, Select } from "components/Select";
import { Text } from "components/Typography";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { isEqual } from "lodash";
import router from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { TalentProfileModule, TalentProfileModuleType } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { Client } from "shopify-buy";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AnalyticServices } from "utils/analytics";
import { copyStringToClipboard } from "utils/clipboard";
import { formatCurrencyNonExchange } from "utils/currency";
import { getTalentName } from "utils/experience";
import notification from "utils/notification";
import { ReactComponent as ProductDefaultIcon } from "public/static/assets/icons/product-default.svg";
import { useResize } from "utils/responsive";
import { userService } from "services";

SwiperCore.use([Pagination]);

interface IProps {
  show: boolean;
  toggleModal(show: boolean): void;
  isMobile: boolean;
  isPreview?: boolean;
  talent?: Talent;
  product: any;
  module: TalentProfileModule;
  shopifyClient: Client | null;
  pricings: any | null;
}

const ProductModal: React.FC<IProps> = ({
  show,
  toggleModal,
  talent,
  product,
  isPreview,
  module,
  shopifyClient,
  isMobile,
  pricings,
}) => {
  const { width } = useResize();
  const user = useTypedSelector(selectUserData);

  const [amount, setAmount] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [availableOptions, setAvailableOptions] = useState(new Map<string, boolean>());
  const [loading, setLoading] = useState(false);

  const selectedVariant = useMemo(() => {
    return product?.variants?.find((variant: any) => {
      return isEqual(
        selectedOptions,
        variant?.selectedOptions?.reduce((acc: any, val: any) => ({ ...acc, [val.name]: val.value }), {}),
      );
    });
  }, [product?.variants, selectedOptions]);

  const isXSmall = useMemo(() => width < 481, [width]);

  const isSoldOut = !product?.availableForSale || !selectedVariant?.available;

  const productPrice = useMemo(() => {
    if (!availableOptions?.size) return "Sold Out";
    if (selectedVariant) {
      if (pricings) {
        return formatCurrencyNonExchange(pricings.currencyCode).format(pricings.amount);
      }
      return formatCurrencyNonExchange(selectedVariant?.priceV2.currencyCode).format(selectedVariant.priceV2.amount);
    }
  }, [availableOptions?.size, selectedVariant]);

  const imageSize = useMemo(() => {
    if (width >= 1174) return 495;
    if (width >= 799 && width <= 1173) return (width - 120) / 2;
    if (width >= 481 && width <= 798) return 448;
    if (width < 481) return width;
  }, [width]);

  const colSpan = useMemo(() => {
    if (width >= 1174) return 12;
    if (width >= 799 && width <= 1173) return 12;
    if (width >= 481 && width <= 798) return 24;
    if (width < 481) return 24;
  }, [width]);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  const handleShare = useCallback(() => {
    copyStringToClipboard(
      `${window.location.origin}/products/${btoa(product?.id)}${
        module?.type === TalentProfileModuleType.SHOPIFY_COLLECTION
          ? `?collection=1&moduleId=${module?.id}`
          : `?moduleId=${module.id}`
      }`,
    );
  }, [module.id, module?.type, product?.id]);

  const handleBuy = useCallback(async () => {
    if (isSoldOut) return;

    if ((window as any)?.analytics && !isPreview) {
      AnalyticServices.track(SEGMENT_EVENT.VIEW_CHECKOUT_SHOPIFY_PRODUCT, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Talent Name": getTalentName(talent, true),
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": product?.id,
        "Element Name": product?.title,
        Platform: isMobile ? "Responsive" : "Web",
        Currency: selectedVariant?.priceV2?.currencyCode,
        Price: productPrice,
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      });
    }
    setLoading(true);
    try {
      let [checkout, country] = await Promise.all([shopifyClient?.checkout?.create(), userService.getCountry()]);

      const customAttributes = [
        { key: "Talent ID", value: `${talent?.id}` },
        // { key: "Talent Name", value: getTalentName(talent, true) },
        { key: "Module ID", value: module?.id },
        // { key: "Module Name", value: module?.name },
        // { key: "Element ID", value: product?.id },
        // { key: "Element Name", value: product?.title },
        { key: "Platform", value: isMobile ? "Responsive" : "Web" },
        // { key: "Location", value: Cookies.get(KOMI_USER_LOCATION) },
        { key: "Location", value: country },
        // { key: "Currency", value: selectedVariant?.priceV2.currencyCode || "" },
        // { key: "Price", value: productPrice },
      ];

      checkout = await (shopifyClient?.checkout as any)?.updateAttributes(checkout?.id, { customAttributes });

      if (checkout) {
        const res: any = await shopifyClient?.checkout?.addLineItems(checkout?.id, [
          {
            variantId: selectedVariant?.id,
            quantity: amount,
          },
        ]);
        if (res) {
          setLoading(false);
          if (navigator.userAgent.match(/instagram|messenger/i) || isMobile) {
            router.push(res?.webUrl);
          } else {
            router.push("/", undefined, { shallow: true });
            toggleModal(false);
            window.open(res?.webUrl, "__blank");
          }
        }
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message:
          "Oops, we are currently experiencing some unexpected problems. Please come back to the site a bit later on",
      });
    }
  }, [
    amount,
    isMobile,
    isPreview,
    isSoldOut,
    module?.id,
    module?.localizationId,
    module?.localizationName,
    module?.name,
    product?.id,
    product?.title,
    productPrice,
    selectedVariant?.id,
    selectedVariant?.priceV2?.currencyCode,
    shopifyClient?.checkout,
    talent,
    toggleModal,
    user,
  ]);

  useEffect(() => {
    if (product?.variants) {
      let minPrice = 999999999;
      let initVariant = null;
      for (let i = 0; i < product?.variants?.length; i++) {
        const variant = product?.variants[i];
        if (variant?.available) {
          variant.selectedOptions?.forEach((option: any) => {
            setAvailableOptions((options) => options.set(JSON.stringify({ [option?.name]: option.value }), true));
          });
        }
        if (variant?.available && parseFloat(variant.price) < minPrice) {
          minPrice = variant.price;
          initVariant = variant;
        }
      }
      if (initVariant) {
        setSelectedOptions(
          initVariant?.selectedOptions?.reduce((acc: any, val: any) => ({ ...acc, [val?.name]: val.value }), {}),
        );
      }
    }
  }, [product?.variants]);

  return (
    <Modal
      maskClosable={false}
      centered
      footer={false}
      className={`product-modal p__x--0 p__y--0 ${isDarken && "darken"}`}
      visible={show}
      onCancel={() => {
        if (!isPreview) {
          router.push("/", undefined, { shallow: true });
        }
        toggleModal(false);
      }}
    >
      <Row gutter={colSpan === 24 ? 0 : 40}>
        <Col span={colSpan}>
          <Swiper
            pagination={{
              clickable: true,
              bulletClass: isDarken ? "swiper-pagination-bullet darken" : "swiper-pagination-bullet",
            }}
          >
            {product?.images?.length ? (
              product.images.map((image: any) => (
                <SwiperSlide key={`${module?.id}-${image?.id}`}>
                  <ImageSkeleton
                    imageClassName="bg--white"
                    src={image?.src as string}
                    borderRadius={16}
                    width={imageSize}
                    height={imageSize}
                  />
                </SwiperSlide>
              ))
            ) : (
              <div className={classNames("default-image-wrapper", { darken: isDarken })}>
                <ProductDefaultIcon />
              </div>
            )}
          </Swiper>
        </Col>
        <Col className="product-info" span={colSpan}>
          {width <= 798 ? (
            <Divider className={classNames({ "m__t--32 m__b--16": true, darken: isDarken })} />
          ) : (
            <div className="m__t--16" />
          )}
          <AvatarProfile
            showInfo
            avatarSize={24}
            avatar={talent?.talentProfile?.avatar || ""}
            avatarText={getTalentName(talent, true)}
            name={getTalentName(talent, true)}
          />
          <Text preset={isXSmall ? "semibold24" : "semibold32"} className="product-title d--block m__t--16">
            {product?.title}
          </Text>
          <Text preset={isXSmall ? "regular18" : "regular20"} className="product-price d--block m__t--8">
            {productPrice}
          </Text>
          <Divider className={classNames({ "m__t--16 m__b--24": true, darken: isDarken })} />
          {availableOptions?.size > 0 &&
            product?.options?.map((option: any, i: number) => (
              <React.Fragment key={i}>
                <Text preset="regular14" className={`d--block m__t--${i === 0 ? 0 : 24}`}>
                  {option?.name}
                </Text>
                <Select
                  className="full-width m__t--8"
                  value={selectedOptions?.[option?.name]}
                  dropdownClassName="product-selector"
                  placement="bottomRight"
                  onSelect={(targetValue) =>
                    setSelectedOptions((val: any) => ({ ...val, [option?.name]: targetValue }))
                  }
                >
                  {option?.values?.map(({ value }: any) => {
                    const hasVariant = availableOptions?.has(JSON.stringify({ [option?.name]: value }));
                    return (
                      <Option
                        className={classNames({
                          disabled: !hasVariant,
                        })}
                        key={value}
                        value={value}
                      >
                        {`${value}${hasVariant ? "" : " - Not Available"}`}
                      </Option>
                    );
                  })}
                </Select>
              </React.Fragment>
            ))}
          {availableOptions?.size > 0 && (
            <Row className="m__t--24" gutter={16}>
              <Col>
                <div className="product-amount">
                  <Button type="text" className="p--0" onClick={() => setAmount((val) => (val > 1 ? val - 1 : val))}>
                    <SVG src={`/static/assets/icons/minus.svg`} />
                  </Button>
                  <Text preset="semibold16" className="d--block m__x--12">
                    {amount}
                  </Text>
                  <Button type="text" className="p--0" onClick={() => setAmount((val) => val + 1)}>
                    <SVG src={`/static/assets/icons/plus.svg`} />
                  </Button>
                </div>
              </Col>
              <Col className="flex--1">
                <Button
                  type="primary"
                  block
                  className={classNames({
                    "ant-btn-md btn-buy-now": true,
                    darken: isDarken,
                  })}
                  loading={loading}
                  onClick={handleBuy}
                  disabled={isSoldOut}
                >
                  {isSoldOut ? `SOLD OUT` : `BUY NOW`}
                </Button>
              </Col>
            </Row>
          )}
          <Button
            type="text"
            className="btn-share p--0 d--flex align__items--center m--auto m__t--28"
            onClick={handleShare}
          >
            <SVG src={`/static/assets/icons/link.svg`} />
            <Text preset="semibold16" className="d--block m__l--4">
              Share Link
            </Text>
          </Button>
          <Divider className={classNames({ "m__t--28 m__b--24": true, darken: isDarken })} />
          <div
            className="ant-typography text--regular16 opacity--08"
            dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }}
          ></div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ProductModal;
