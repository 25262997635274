import Layout from "antd/lib/layout/layout";
import classNames from "classnames";
import CreateAccountModal from "components/CreateAccountModal";
import Footer from "components/Footer";
import LoginModal from "components/LoginModal";
import MobileMainMenu from "components/MobileMainMenu";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { ReactNode, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Experience } from "redux/Experience/types";
import { MODAL_CREATE_ACCOUNT, MODAL_LOGIN, toggleModalActions } from "redux/Modal/actions";
import { selectModalData, selectModalStatus } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { setUserValueAction } from "redux/User/actions";
import { transformImageSize } from "utils/photo";

import { Col, Row } from "antd";
import { Paragraph } from "components/Typography";

interface IProps {
  children: ReactNode;
  title: string;
  description?: string;
  images?: any;
  isMobile: boolean;
  isPreview?: boolean;
  backgroundImage?: string;
  mobileMenuVisible?: boolean;
  toggleMenuVisible?(): void;
  isFullLayout?: boolean;
  showFooter?: boolean;
  talent?: Talent;
  footerClassName?: string;
  experience?: Experience;
  hideDescription?: boolean;
  loading?: boolean;
  loadingModule?: boolean;
  notFound?: boolean;
}

const DetailLayout: React.FC<IProps> = ({
  children,
  title,
  description,
  images = [
    {
      url: "favicon.png",
    },
  ],
  isMobile,
  isPreview = false,
  backgroundImage = "",
  mobileMenuVisible = false,
  toggleMenuVisible,
  isFullLayout = false,
  showFooter = false,
  talent,
  footerClassName = "",
  experience,
  loading,
  loadingModule,
  notFound
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const loginModalStatus = useTypedSelector((state) => selectModalStatus(state, MODAL_LOGIN));
  const createAccountModalStatus = useTypedSelector((state) => selectModalStatus(state, MODAL_CREATE_ACCOUNT));
  const loginModalData = useTypedSelector((state) => selectModalData(state, MODAL_LOGIN));
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));

  const [showEmailLogin, setShowEmailLogin] = React.useState(false);
  const [useDefaultTheme, setUseDefaultTheme] = React.useState(true);

  const toggleModalLogin = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_LOGIN,
          status: status,
          data: signUpModalData,
        }),
      );
    },
    [dispatch, signUpModalData],
  );

  const toggleModalCreateAccount = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
          data: loginModalData,
        }),
      );
    },
    [dispatch, loginModalData],
  );

  useEffect(() => {
    let bgColor = null;

    const interval = setInterval(() => {
      bgColor = getComputedStyle(document.body).getPropertyValue("--ant-primary-color");
      if (bgColor) {
        setUseDefaultTheme(false);
      }
    }, 100);

    if (bgColor) {
      clearInterval(interval);
    }
  }, []);

  return (
    <Layout>
      <Layout>
        <div
          className={classNames({
            "detail-layout": true,
            "detail-layout--talent-profile": !useDefaultTheme && talent && !experience,
            "full-layout": isFullLayout,
            "detail-layout--preview": isPreview,
            "detail-layout--preview-empty": !talent?.talentProfile?.avatar?.length,
            "detail-layout--loading": loading,
            "detail-layout--loading-module": loadingModule,
          })}
          // style={{ height: isMobile ? height : "auto" }}
        >
          {notFound
            ? (
              <div className="snippet" data-title=".dot-flashing">
                <div className="stage">
                  <Row gutter={16} align="middle" justify="center" className="full-width">
                    <Col>
                      <Paragraph preset="bold18" className="text--white">
                        404
                      </Paragraph>
                    </Col>
                    <Col>
                      <Paragraph className="text--white">This page could not be found.</Paragraph>
                    </Col>
                  </Row>
                </div>
              </div>
              )
            : loading
            ? (
              <div className="snippet" data-title=".dot-flashing">
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div>
              </div>
            )
            : (
              <>
                {!isMobile && backgroundImage?.length > 0 && (
                  <img
                    className="detail-layout__background"
                    src={transformImageSize(backgroundImage, 366, 461, true)}
                    alt="avatar"
                  />
                )}
                <div
                  className={classNames({
                    "detail-layout__content": true,
                    "detail-layout__content--preview": isPreview,
                  })}
                >
                  {children}{" "}
                  {showFooter && !loadingModule && (
                    <Footer
                      isMobile={isMobile}
                      isTalentProfile={!experience}
                      className={footerClassName}
                      talent={talent}
                    />
                  )}
                </div>
              </>
            )}
        </div>
      </Layout>
      {toggleMenuVisible && process.browser && talent?.hasBooking && (
        <MobileMainMenu mobileMenuVisible={mobileMenuVisible} closeMenu={toggleMenuVisible} talent={talent} />
      )}
      {!!loginModalStatus && (
        <LoginModal
          show={!!loginModalStatus}
          onOk={() => toggleModalLogin(false)}
          onCancel={() => {
            if (experience) {
              const { experienceName, talentName, id } = router?.query;
              router.replace(`/experience/${talentName}/${experienceName}?id=${id}`, undefined, { shallow: true });
            } else if (talent) {
              router.push(`/`, undefined, { shallow: true });
            }
            toggleModalLogin(false);
            setShowEmailLogin(false);
            Cookies.set("IS_BOOKING_FREE", "false");
          }}
          showCreateAccountModal={() => {
            toggleModalLogin(false);
            toggleModalCreateAccount(true);
            setShowEmailLogin(false);
          }}
          talent={talent}
          experience={experience}
        />
      )}

      {createAccountModalStatus && (
        <CreateAccountModal
          title={"Welcome to Komi"}
          description={"Sign up to continue exploring"}
          show={createAccountModalStatus}
          onOk={() => toggleModalCreateAccount(false)}
          onCancel={() => {
            if (experience) {
              const { experienceName, talentName, id } = router?.query;
              router.replace(`/experience/${talentName}/${experienceName}?id=${id}`, undefined, { shallow: true });
            } else if (talent) {
              router.push(`/`, undefined, { shallow: true });
            }
            toggleModalCreateAccount(false);
            setShowEmailLogin(false);
            Cookies.set("IS_BOOKING_FREE", "false");
            dispatch(
              setUserValueAction({
                isRequestJoinTalent: false,
              }),
            );
          }}
          showEmail={showEmailLogin}
          showLoginModal={() => {
            toggleModalCreateAccount(false);
            toggleModalLogin(true);
            setShowEmailLogin(false);
          }}
          talent={talent}
          experience={experience}
        />
      )}
    </Layout>
  );
};

export default DetailLayout;
