import Divider from "antd/lib/divider";
import Swiper from "components/Swiper";
import { Paragraph } from "components/Typography";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { ExperienceItem, OnDemandVideoItem, TalentProfileModule } from "redux/User/types";
import { getFirstAvatar } from "utils/experience";
import { convertToUrl } from "utils/string";
import { useTypedSelector } from "redux/rootReducer";
import { selectTalent } from "redux/Talent/selector";
import classNames from "classnames";
import { useResize } from "utils/responsive";
import { EXPERIENCE_STATUS } from "redux/Experience/types";
import { selectUserData, selectUserCurrency } from "redux/User/selector";
import { SEGMENT_EVENT } from "constants/segment";
import { selectExchangeRates } from "redux/Payment/selectors";
import { KOMI_USER_LOCATION } from "services/UserService";
import Cookies from "js-cookie";
import { getPrice } from "utils/currency";
import { ReactComponent as LockIcon } from "public/static/assets/icons/lock2.svg";
import { AnalyticServices } from "utils/analytics";
import ImageProgessive from "components/ImageProgessive";
import { komiConsumerUrl } from "services/DomainService";

interface IProps {
  isMobile: boolean;
  isPreview?: boolean;
  className?: string;
  module: TalentProfileModule;
  showDivider: boolean;
  isGroup?: boolean;
}

interface ExperienceCardIProps {
  module: TalentProfileModule;
  isMobile: boolean;
  isPreview?: boolean;
  className?: string;
  experience: OnDemandVideoItem;
  isBig: boolean;
}

const OnDemandCard = ({ experience, isMobile, isPreview = false, className, isBig, module }: ExperienceCardIProps) => {
  const user = useTypedSelector(selectUserData);
  const talent = useTypedSelector(selectTalent);
  const router = useRouter();
  const { width } = useResize();
  const currency = useTypedSelector(selectUserCurrency);
  const exchangeRates = useTypedSelector(selectExchangeRates);

  const handlePress = React.useCallback(() => {
    if (isPreview) return;

    const url = `/experience/${talent?.username}/${convertToUrl(experience?.name)}?id=${experience?.id}`;
    if ((window as any)?.analytics) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_EXPERIENCE_ELEMENT, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": experience?.id,
        "Element Name": experience?.name,
        "Experience type": experience?.type,
        "Experience name": experience?.name,
        "Experience ID": experience?.id,
        "Element URL": `${komiConsumerUrl}${url}`,
        "Talent name": `${talent?.talentProfile?.firstName || ""} ${talent?.talentProfile?.lastName || ""}`,
        price: getPrice(experience.gbpExperiencePrice || 0, currency, exchangeRates, true).substring(1),
        currency: currency,
        Platform: isMobile ? "Responsive" : "Web",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      });
    }
    router.push(url);
  }, [
    isPreview,
    talent?.username,
    talent?.id,
    talent?.talentProfile?.firstName,
    talent?.talentProfile?.lastName,
    experience?.name,
    experience?.id,
    experience?.type,
    experience.gbpExperiencePrice,
    router,
    user,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    currency,
    exchangeRates,
    isMobile,
  ]);

  return (
    <div className={`talent-detail__on-demand-card cursor-pointer ${className}`} onClick={handlePress}>
      <ImageProgessive
        ratio={isMobile ? 0.05 : 0.25}
        threshold={isMobile ? [0.05, 0.25, 1] : [0.25, 1]}
        src={getFirstAvatar(experience)}
        width={isMobile ? (isBig ? width - 32 : 327) : isBig ? (isPreview ? width - 32 : 587) : 387}
        height={isMobile ? (isBig ? 210 : 200) : isBig ? 343 : 226}
      />
      <LockIcon
        width={isMobile ? 48 : isBig ? 58 : 48}
        height={isMobile ? 48 : isBig ? 58 : 48}
        className="talent-detail__on-demand-card__icon"
      />
      <Paragraph
        className="talent-detail__on-demand-card__name text__align--left"
        preset={isMobile ? "bold16" : "medium20"}
        ellipsis={{ rows: 1 }}
      >
        {experience?.name || ""}
      </Paragraph>
    </div>
  );
};

const TalentDetailOnDemands = ({
  className = "",
  isMobile,
  isPreview = false,
  module,
  showDivider,
  isGroup,
}: IProps) => {
  const [itemsRender, setItemsRender] = useState<ExperienceItem[]>([]);

  const experiences = useMemo(
    () =>
      (module?.items as OnDemandVideoItem[])?.filter((experience) => experience.status === EXPERIENCE_STATUS.PUBLISHED),
    [module?.items],
  );

  const params: any = useMemo(
    () => ({
      slidesPerView: experiences?.length === 1 ? 1 : isMobile ? 1.0929 : 1.488,
      spaceBetween: 16,
      navigation: !isMobile,
      noSwiping: isMobile,
      centeredSlides: true,
      centeredSlidesBounds: true,
      slidesOffsetAfter: isMobile ? 16 : 0,
    }),
    [experiences?.length, isMobile],
  );

  useEffect(() => {
    if (experiences?.length) {
      setItemsRender([...experiences]);
    }
  }, [experiences]);

  return experiences?.length > 0 ? (
    <div id={module.id} className={`${className}  ${isGroup && "border-card-inside-group"} `}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "p__x--16": isMobile && experiences?.length === 1,
            "p__l--16": isMobile && experiences?.length > 1,
            "fix-width": experiences?.length === 1,
          })}
          isMobile={isMobile}
          title={module?.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          typeModule={module.type}
          items={itemsRender?.map((experience, i) => (
            <OnDemandCard
              module={module}
              key={experience?.id}
              isBig={experiences?.length === 1}
              experience={experience}
              isMobile={isMobile}
              isPreview={isPreview}
            />
          ))}
        />
        {showDivider && !isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailOnDemands;
