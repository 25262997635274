import React, { useCallback, useMemo, useState } from "react";
import DataCaptureModal from "components/DataCaptureComponents/DataCaptureModal";
import DataCaptureLayout from "components/DataCaptureComponents/DataCaptureLayout";
import { FormDataItem, SubmitDataCaptureFormRequest, TalentProfileModule } from "redux/User/types";
import Divider from "antd/lib/divider";
import notification from "utils/notification";
import { userService } from "services";
import { SEGMENT_EVENT } from "constants/segment";
import { useAnalytics } from "hooks/useAnalytics";
import { Talent } from "redux/Talent/types";
import { getTalentName } from "utils/experience";
import LabelTooltip from "components/LabelTooltip/LabelTooltip";

export interface IDataCaptureModuleProps {
  isMobile: boolean;
  isDarken: boolean;
  showDivider: boolean;
  module: TalentProfileModule;
  className?: string;
  isGroup?: boolean;
  talent?: Talent;
}

const DataCaptureModule: React.FC<IDataCaptureModuleProps> = ({
  isMobile,
  isDarken,
  module,
  showDivider,
  className,
  talent,
  isGroup,
}) => {
  const [showModal, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sendSegmentEvent } = useAnalytics();
  const formData = useMemo(() => module?.items?.[0] as FormDataItem, [module?.items]);

  const handleSubmit = useCallback(
    (values) => {
      if (formData?.id) {
        const payload: SubmitDataCaptureFormRequest = {
          moduleId: module.id,
          formId: formData.id,
          answers: values,
        };
        setIsLoading(true);
        userService
          .submitDataCaptureForm(payload)
          .then((res: any) => {
            if (res.ok) {
              notification.success({ message: "The form was submitted successfully. Thank you!" });
              toggleModal(false);
              sendSegmentEvent(SEGMENT_EVENT.SUBMIT_DATA_CAPTURE_FORM, {
                "Talent Name": getTalentName(talent, true),
                "Talent ID": talent?.id,
                "Module ID": module?.id,
                "Module Name": module?.name,
                "Element ID": formData?.id,
                "Element Name": formData?.title,
                Platform: isMobile ? "Responsive" : "Web",
                "Page ID": module?.localizationId || null,
                "Page Name": module?.localizationName || "Default",
              });
            } else {
              notification.error({ message: "There was an error submitting the form. Please try again." });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [
      formData?.id,
      formData?.title,
      isMobile,
      module.id,
      module?.localizationId,
      module?.localizationName,
      module?.name,
      sendSegmentEvent,
      talent,
    ],
  );
  const handleClickDataCaptureLayout = useCallback(() => {
    toggleModal(true);
    sendSegmentEvent(SEGMENT_EVENT.CLICK_DATA_CAPTURE_FORM, {
      "Talent Name": getTalentName(talent, true),
      "Talent ID": talent?.id,
      "Module ID": module?.id,
      "Module Name": module?.name,
      "Element ID": formData?.id,
      "Element Name": formData?.title,
      Platform: isMobile ? "Responsive" : "Web",
      "Page ID": module?.localizationId || null,
      "Page Name": module?.localizationName || "Default",
    });
  }, [
    sendSegmentEvent,
    talent,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    formData?.id,
    formData?.title,
    isMobile,
  ]);
  return formData && formData.visible ? (
    <>
      <div id={module.id} className={`${isMobile ? "p__x--16" : ""} ${className}`}>
        <div
          className={` ${
            !isGroup ? "" : isGroup && (module.name.length === 0 || module.showTitle === false) ? "" : "p__b--16"
          }`}
        >
          {(isGroup ? module.showTitle : true) && (
            <LabelTooltip
              className={`my-swiper__title

           `}
              preset={isGroup ? (isMobile ? "semibold18" : "semibold20") : isMobile ? "semibold24" : "semibold28"}
              label={module.name}
              ellipsis={{ rows: 1 }}
              maxLength={isMobile ? module.name.length : 30}
            />
          )}
        </div>

        <div
          className={`cursor-pointer ${isMobile ? !isGroup && "m__t--16" : !isGroup && "m__t--20"}`}
          onClick={handleClickDataCaptureLayout}
        >
          <DataCaptureLayout
            layout={formData.layout as any}
            isDraken={isDarken}
            isImageOn={formData?.enableImage}
            image={formData?.image}
            isMobile={isMobile}
            title={formData?.title}
            subtitle={formData.subTitle || ""}
            isGroup={isGroup}
          />
        </div>
      </div>
      {showDivider && !isGroup && <Divider className="m__t--24 m__b--0" />}
      {showModal && (
        <DataCaptureModal
          title={formData.title}
          subtitle={formData.subTitle || ""}
          isDarken={isDarken}
          fields={formData.form.fields}
          show={showModal}
          toggleModal={toggleModal}
          handleSubmitForm={handleSubmit}
          isLoading={isLoading}
        />
      )}
    </>
  ) : null;
};

export default DataCaptureModule;
