import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ExperienceTypeTag from "components/ExperienceTypeTag";
import ImageProgessive from "components/ImageProgessive";
import Swiper from "components/Swiper";
import { Paragraph } from "components/Typography";
import { komiConsumerUrl } from "services/DomainService";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { Experience, EXPERIENCE_STATUS, EXPERIENCE_TYPE } from "redux/Experience/types";
import { selectExchangeRates } from "redux/Payment/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserCurrency, selectUserData } from "redux/User/selector";
import { ExperienceItem, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { getPrice } from "utils/currency";
import { getFirstAvatar } from "utils/experience";
import { convertToUrl } from "utils/string";

interface IProps {
  isMobile: boolean;
  isPreview?: boolean;
  className?: string;
  module: TalentProfileModule;
  talent?: Talent;
  isGroup?: boolean;
}

interface ExperienceCardIProps {
  module: TalentProfileModule;
  isMobile: boolean;
  isPreview?: boolean;
  className?: string;
  experience: Experience;
  isLongCard?: boolean;
  talent?: Talent;
}

const ExperienceCard = ({
  experience,
  isMobile,
  isPreview = false,
  className,
  isLongCard,
  module,
  talent,
}: ExperienceCardIProps) => {
  const router = useRouter();
  const user = useTypedSelector(selectUserData);
  const currency = useTypedSelector(selectUserCurrency);
  const exchangeRates = useTypedSelector(selectExchangeRates);

  const handlePress = React.useCallback(() => {
    if (isPreview) return; // cannot click any on talent preview
    let url = "";
    if (experience?.isPOA) {
      url = `/special-request/${experience?.creator?.talentProfile?.id}?isPoa=true`;
    } else if (experience?.type === EXPERIENCE_TYPE.BUNDLE) {
      url = `/bundle/${talent?.username}/${convertToUrl(experience?.name)}?id=${experience?.id}`;
    } else {
      url = `/experience/${talent?.username}/${convertToUrl(experience?.name)}?id=${experience?.id}`;
    }
    if ((window as any)?.analytics) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_EXPERIENCE_ELEMENT, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": experience?.id,
        "Element Name": experience?.name,
        "Experience type": experience?.type,
        "Experience name": experience?.name,
        "Experience ID": experience?.id,
        "Element URL": `${komiConsumerUrl}${url}`,
        "Talent name": `${talent?.talentProfile?.firstName || ""} ${talent?.talentProfile?.lastName || ""}`,
        price: getPrice(experience.gbpExperiencePrice || 0, currency, exchangeRates, true).substring(1),
        currency: currency,
        Platform: isMobile ? "Responsive" : "Web",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      });
    }
    router.push(url);
  }, [
    isPreview,
    experience?.isPOA,
    experience?.type,
    experience?.creator?.talentProfile?.id,
    experience?.name,
    experience?.id,
    experience.gbpExperiencePrice,
    router,
    talent?.username,
    talent?.id,
    talent?.talentProfile?.firstName,
    talent?.talentProfile?.lastName,
    user,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    currency,
    exchangeRates,
    isMobile,
  ]);

  return (
    <div
      className={`talent-detail__experience-card cursor-pointer ${isLongCard && "long full-width"} ${className}`}
      onClick={handlePress}
    >
      {isLongCard ? (
        <Row className={`link-card ${className}`} align="middle">
          <Col>
            <ImageProgessive
              ratio={0.25}
              threshold={[0.25, 1]}
              src={getFirstAvatar(experience)}
              width={isMobile ? 115 : 140}
              height={isMobile ? 115 : 140}
            />
          </Col>
          <Col className={`flex--1 ${isMobile ? "p__x--12 p__t--12 p__b--10" : "p__x--24"}`}>
            <ExperienceTypeTag className="large" experience={experience} />
            <Paragraph ellipsis={{ rows: 3 }} className="m__t--8" preset={isMobile ? "semibold16" : "semibold20"}>
              {experience?.name}
            </Paragraph>
          </Col>
        </Row>
      ) : (
        <>
          <ImageProgessive
            ratio={0.25}
            threshold={[0.25, 1]}
            borderRadius={8}
            src={getFirstAvatar(experience)}
            width={isMobile ? 115 : 140}
            height={isMobile ? 115 : 140}
          />
          <ExperienceTypeTag experience={experience} />
          <Paragraph
            className={isMobile ? "m__t--8" : "m__t--16"}
            preset={isMobile ? "bold14" : "semibold16"}
            ellipsis={{ rows: 3 }}
          >
            {experience?.name}
          </Paragraph>
        </>
      )}
    </div>
  );
};

const TalentDetailExperiences: React.FC<IProps> = ({
  className = "",
  isMobile,
  isPreview = false,
  module,
  talent,
  isGroup,
}) => {
  const [itemsRender, setItemsRender] = useState<ExperienceItem[]>([]);

  const experiences = useMemo(
    () =>
      (module?.items as ExperienceItem[])?.filter((experience) => experience.status === EXPERIENCE_STATUS.PUBLISHED),
    [module?.items],
  );

  const minElements = useMemo(() => (isMobile ? 2 : 3), [isMobile]);

  const params: any = useMemo(
    () => ({
      slidesPerView: experiences?.length <= minElements ? 1 : isMobile ? "auto" : 3.725,
      slidesPerGroup: isMobile ? 1 : 3,
      slidesPerColumn: experiences?.length <= minElements ? minElements : 1,
      spaceBetween: experiences?.length <= minElements ? 16 : isMobile ? 16 : 24,
      navigation: !isMobile,
      noSwiping: isMobile,
      slidesOffsetAfter: isMobile ? 16 : 0,
    }),
    [experiences?.length, isMobile, minElements],
  );

  useEffect(() => {
    if (experiences?.length) {
      setItemsRender([...experiences]);
    }
  }, [experiences]);

  return experiences?.length > 0 ? (
    <div id={module.id} className={`${className}`}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "p__x--16": isMobile && experiences?.length <= minElements,
            "p__l--16": isMobile && experiences?.length > minElements,
            "fix-width": experiences?.length <= minElements,
          })}
          isMobile={isMobile}
          title={module?.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          items={itemsRender?.map((experience) => (
            <ExperienceCard
              module={module}
              isLongCard={experiences?.length <= minElements}
              key={experience?.id}
              experience={experience}
              isMobile={isMobile}
              isPreview={isPreview}
              talent={talent}
            />
          ))}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailExperiences;
