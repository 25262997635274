import { RootState } from "./../rootReducer";

export const selectClientSecret = (rootState: RootState) => rootState.paymentState?.clientSecret;

export const selectPaymentLoading = (rootState: RootState) => rootState.paymentState?.isLoading;

export const selectPaymentError = (rootState: RootState) => rootState.paymentState?.error;

export const selectClientToken = (rootState: RootState) => rootState.paymentState?.token;

export const selectCheckoutResult = (rootState: RootState) =>
  rootState.paymentState?.checkoutResult;

export const selectSendTipResult = (rootState: RootState) => rootState.paymentState?.sendTipResult;

export const selectExchangeRates = (rootState: RootState) => rootState.paymentState?.exchangeRates;

export const selectExchangeRatesUSD = (rootState: RootState) =>
  rootState.paymentState?.exchangeRatesUSD;

export const selectExchangeRatesTip = (rootState: RootState) =>
  rootState.paymentState?.exchangeRatesTip;

export const selectTransactions = (rootState: RootState) => rootState.paymentState?.transactions;

export const selectTransactionData = (rootState: RootState) =>
  rootState.paymentState?.transactionData;

export const selectPaymentMethods = (rootState: RootState) =>
  rootState.paymentState?.paymentMethods;
