import Button from "antd/lib/button";
import ImageSkeleton from "components/ImageSkeleton";
import { Text } from "components/Typography";
import { LIGHT_THEME } from "constants/profile-theme";
import moment from "moment";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import useCountDown from "react-countdown-hook";
import { useTypedSelector } from "redux/rootReducer";
import { selectIsPreSaveSuccess, selectTalentError } from "redux/Talent/selector";
import { Talent } from "redux/Talent/types";
import {
  MusicItem as MusicItemElement,
  MusicItemLink,
  MusicItemType,
  ReleaseScope,
  TalentProfileModule,
} from "redux/User/types";
import { convertTimeLeftToDate } from "utils/date";
import notification from "utils/notification";
import PreReleaseMusicItem from "./PreReleaseMusicItem";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Modal = dynamic(() => import("components/Modal"));

interface IProps {
  module: TalentProfileModule;
  show: boolean;
  onCancel(): void;
  artists: string;
  name: string;
  image: string;
  links: MusicItemLink[];
  isMobile: boolean;
  musicItem: MusicItemElement;
  isPreview?: boolean;
  releaseTime?: string;
  talent?: Talent;
  saveMarketingPermissions: (userID: string, platform: string, email?: string) => void;
}

const userTz = moment.tz.guess();
const userUtcOffset = moment.tz(userTz).utcOffset();

const PreReleaseModal: React.FC<IProps> = ({
  show,
  onCancel,
  name,
  artists,
  image,
  links,
  module,
  isMobile,
  musicItem,
  releaseTime,
  talent,
  isPreview,
  saveMarketingPermissions,
}) => {
  const router = useRouter();
  const error = useTypedSelector(selectTalentError);
  const isPreSaveSuccess = useTypedSelector(selectIsPreSaveSuccess);

  const [currentPreSaveType, setCurrentPreSaveType] = useState<
    MusicItemType.SPOTIFY | MusicItemType.APPLE_MUSIC | null
  >(null);

  const isoCompliantDate = new Date(musicItem?.releaseDate || "");
  const adjustedReleaseTime =
    musicItem.releaseScope === ReleaseScope.GLOBAL ? moment(isoCompliantDate) : moment.utc(isoCompliantDate);

  const timeLeft = useMemo(() => {
    if (!adjustedReleaseTime) return 0;
    return adjustedReleaseTime.diff(moment.utc().add({ minutes: userUtcOffset }), "seconds");
  }, [adjustedReleaseTime]);

  const [timer, { start }] = useCountDown(timeLeft * 1000, 1000);

  useEffect(() => {
    if (timeLeft) {
      start();
    }
  }, [start, timeLeft]);

  const time = useMemo(() => convertTimeLeftToDate({ diff: timer }), [timer]);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  useEffect(() => {
    if (error) {
      notification.error({
        message: "An error occurred whilst presaving. Please try again",
      });
    }
  }, [error]);

  useEffect(() => {
    if (isPreSaveSuccess) {
      notification.success({
        message: "Save successfully",
      });
    }
  }, [isPreSaveSuccess]);

  return (
    <Modal
      centered
      closable={!isMobile}
      closeIcon={<></>}
      footer={false}
      className="music-modal music-modal--pre-release  p--0"
      visible={show}
      onCancel={onCancel}
    >
      <Row
        className={`music-modal__header p--${isMobile ? 16 : 24} ${
          musicItem.releaseType === "CUSTOM" && "border__bottom--none"
        }`}
        justify="space-between"
        align="middle"
        style={{
          background: isDarken
            ? "linear-gradient(rgba(18, 18, 18, 0.05), rgba(18, 18, 18, 0.05)), var(--ant-primary-color)"
            : "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), var(--ant-primary-color)",
        }}
      >
        <Col>
          <ImageSkeleton src={image} width={isMobile ? 110 : 140} height={isMobile ? 110 : 140} />
        </Col>
        <Col className={`flex--1  ${isMobile ? "m__l--16" : "m__x--24"}`}>
          <Text preset={isMobile ? "semibold16" : "semibold20"} className="d--block">
            {name}
          </Text>
          <Text preset={isMobile ? "regular14" : "regular18"} className="d--block opacity--05">
            {artists}
          </Text>
        </Col>
      </Row>
      {releaseTime && musicItem.releaseType !== "CUSTOM" ? (
        <div
          className={`music-modal__sub-header p--${isMobile ? 16 : 24} text__align--center`}
          style={{
            background: isDarken
              ? "linear-gradient(rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.1)), var(--ant-primary-color)"
              : "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--ant-primary-color)",
          }}
        >
          <Text preset={isMobile ? "regular12" : "regular18"} className="letter__spacing--1 opacity--05">
            AVAILABLE
          </Text>
          <Text preset={isMobile ? "semibold14" : "semibold18"} className="d--block music-modal__sub-header__date">
            {adjustedReleaseTime.format("MMMM Do [at] hh:mm A.")}
          </Text>

          <Row className="m__t--16" gutter={isMobile ? 12 : 32} justify="center">
            <Col flex={isMobile ? "76px" : "104px"} className="text__align--center">
              <Text preset={isMobile ? "semibold14" : "semibold16"}>{time?.day}</Text>
              <Text preset={isMobile ? "regular12" : "regular14"} className="d--block letter__spacing--1 opacity--05">
                DAY
              </Text>
            </Col>
            <Col flex={isMobile ? "76px" : "104px"} className="text__align--center">
              <Text preset={isMobile ? "semibold14" : "semibold16"}>{time?.hour}</Text>
              <Text preset={isMobile ? "regular12" : "regular14"} className="d--block letter__spacing--1 opacity--05">
                HOURS
              </Text>
            </Col>
            <Col flex={isMobile ? "76px" : "104px"} className="text__align--center">
              <Text preset={isMobile ? "semibold14" : "semibold16"}>{time?.min}</Text>
              <Text preset={isMobile ? "regular12" : "regular14"} className="d--block letter__spacing--1 opacity--05">
                MINUTES
              </Text>
            </Col>
            <Col flex={isMobile ? "76px" : "104px"} className="text__align--center">
              <Text preset={isMobile ? "semibold14" : "semibold16"}>{time.sec}</Text>
              <Text preset={isMobile ? "regular12" : "regular14"} className="d--block letter__spacing--1 opacity--05">
                SECONDS
              </Text>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className={`music-modal__sub-header music-modal__sub-header--custom text__align--center p__b--24`}
          style={{
            background: isDarken
              ? "linear-gradient(rgba(18, 18, 18, 0.05), rgba(18, 18, 18, 0.05)), var(--ant-primary-color)"
              : "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), var(--ant-primary-color)",
          }}
        >
          <span>
            <Text
              preset={isMobile ? "medium12" : "medium14"}
              className={`letter__spacing--${isMobile ? 1 : 2} opacity--08`}
            >
              {`AVAILABLE ${adjustedReleaseTime.format("MMMM Do, YYYY").toLocaleUpperCase()}`}
            </Text>
          </span>
          <Button
            className={`d--flex ant-btn--border btn-presave--custom music-item--single height--${
              isMobile ? "32" : "36"
            }`}
            shape="round"
            onClick={() => {
              if (!isPreview) router.push(links[0]?.url);
            }}
          >
            <Text preset={"semibold14"}>Pre-Save / Pre-Add</Text>
          </Button>
        </div>
      )}
      {musicItem.releaseType !== "CUSTOM" && (
        <div className="music-modal__content__wrapper">
          <div className="music-modal__content p__x--16">
            {links
              ?.filter((link) => link?.url?.length)
              ?.map((link, i) => (
                <PreReleaseMusicItem
                  key={i}
                  talent={talent}
                  module={module}
                  music={link}
                  musicItem={musicItem}
                  isMobile={isMobile}
                  isActive={link?.type === currentPreSaveType}
                  setCurrentPreSaveType={setCurrentPreSaveType}
                  isPreview={isPreview}
                  saveMarketingPermissions={saveMarketingPermissions}
                />
              ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PreReleaseModal;
