import { Col, Divider, Row } from "antd";
import ImageProgessive from "components/ImageProgessive";
import JoinFanClubModal from "components/JoinFanClubModal";
import { Text } from "components/Typography";
import { LIGHT_THEME } from "constants/profile-theme";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MODAL_CREATE_ACCOUNT, toggleModalActions } from "redux/Modal/actions";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { setUserValueAction } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { FanClubItem, TalentProfileModule } from "redux/User/types";
import { getTalentName } from "utils/experience";
import { transformImageSize } from "utils/photo";
import { convertToUrl } from "utils/string";
import { ReactComponent as EmptyState } from "public/static/assets/icons/empty-state.svg";
import classNames from "classnames";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
}

const TalentDetailFanClub: React.FC<IProps> = ({ isMobile, module, className, isPreview, talent }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const user = useTypedSelector(selectUserData);

  const [showFanClubModal, toggleFanClubModal] = useState(false);

  const fanClub = useMemo(() => module?.items?.[0] as FanClubItem, [module?.items]);

  const toggleModalLogin = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
        }),
      );
    },
    [dispatch],
  );

  const handleShowFanClubModal = useCallback(() => {
    if (!isPreview) {
      if (user) {
        toggleFanClubModal(true);
      } else {
        router.push(`/?join-fan=1`, undefined, { shallow: true });
        toggleModalLogin(true);
      }
    }
  }, [isPreview, router, toggleModalLogin, user]);

  const fanClubName = useMemo(
    () =>
      talent?.talentProfile?.fanClubName?.length
        ? talent?.talentProfile?.fanClubName
        : `${getTalentName(talent, true)}’s fan club`,
    [talent],
  );

  const fanClubBenefits = useMemo(
    () => talent?.talentProfile?.fanClubBenefits || "Exclusive benefits",
    [talent?.talentProfile?.fanClubBenefits],
  );

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  useEffect(() => {
    if (user && router.query?.["join-fan"] === "1") {
      toggleFanClubModal(true);
    }
  }, [handleShowFanClubModal, router, user]);

  return fanClub ? (
    <div id={module.id} className={`${className}`}>
      <div className="talent-detail__fan-banner__container cursor-pointer" onClick={handleShowFanClubModal}>
        <div className="talent-detail__fan-banner__background">
          <img
            src={transformImageSize(fanClub?.thumbnail as string, isMobile ? 112 : 200, isMobile ? 112 : 200, true)}
            alt="avatar"
          />
          <div className={`talent-detail__fan-banner__background__gradient ${isDarken && "darken"}`} />
        </div>
        <Row className="talent-detail__fan-banner" gutter={isMobile ? 0 : 40} align={isMobile ? "top" : "middle"}>
          <Col className="flex--1">
            <Text preset={isMobile ? "bold24" : "bold32"}>{`Join ${fanClubName}`}</Text>
            <Text preset={isMobile ? "regular14" : "regular18"} className="d--block m__t--8 opacity--08">
              {`Unlock ${fanClubBenefits}`}
            </Text>
          </Col>
          <Col>
            {fanClub?.thumbnail ? (
              <ImageProgessive
                ratio={isMobile ? 0.05 : 0.25}
                threshold={isMobile ? [0.05, 0.25, 1] : [0.25, 1]}
                src={fanClub?.thumbnail as string}
                shape="circle"
                width={isMobile ? 112 : 200}
                height={isMobile ? 112 : 200}
              />
            ) : (
              <div
                className={classNames({
                  "talent-detail__fan-banner__empty": true,
                  darken: isDarken,
                })}
              >
                <EmptyState width={isMobile ? 40 : 60} height={isMobile ? 40 : 60} />
              </div>
            )}
          </Col>
        </Row>
      </div>

      <Divider className="full-width m__t--24 m__b--0" />
      {showFanClubModal && (
        <JoinFanClubModal
          show={showFanClubModal}
          onOk={() => {
            router.push(`/`, undefined, { shallow: true });
            toggleFanClubModal(false);
          }}
          onCancel={() => {
            router.push(`/`, undefined, { shallow: true });
            toggleFanClubModal(false);
          }}
          talent={talent}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailFanClub;
