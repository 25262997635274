import ImageProgessive from "components/ImageProgessive";
import { Paragraph } from "components/Typography";
import dynamic from "next/dynamic";
import { ReactComponent as CloseFillIcon } from "public/static/assets/icons/close-fill.svg";
import React, { useCallback } from "react";
import { Talent } from "redux/Talent/types";
import { copyStringToClipboard } from "utils/clipboard";
import { ProductShopListItem, TalentProfileModule } from "redux/User/types";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Button = dynamic(() => import("antd/lib/button"));
const Modal = dynamic(() => import("components/Modal"));

interface IProps {
  show: boolean;
  toggleModal(show: boolean): void;
  isMobile: boolean;
  talent?: Talent;
  module: TalentProfileModule;
  product: ProductShopListItem;
}

const ShopMyShelfModal: React.FC<IProps> = ({ show, toggleModal, isMobile, talent, module, product }) => {
  const onOpenStoreUrl = useCallback(() => {
    product.url && window.open(product.url);
  }, [product.url]);

  const handleCancel = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  const handleCopyCode = useCallback(() => {
    if (product.code && product.code.display) {
      copyStringToClipboard(product.code.display, "The discount code has been copied to your clipboard.");
    }
  }, [product]);
  return (
    <Modal
      centered
      closable={false}
      footer={false}
      className={`Special__offer__modal p__x--0 p__y--24 `}
      visible={show}
      onCancel={handleCancel}
    >
      <Row
        className={`Special__offer__modal__header align__items--center position--relative ${
          isMobile ? "p--16" : "p--24"
        }`}
      >
        <CloseFillIcon className="close-icon" onClick={handleCancel} />
        <Col className="Special__offer__modal__header__left">
          <ImageProgessive
            borderRadius={16}
            src={product.image || ""}
            width={isMobile ? 110 : 140}
            height={isMobile ? 110 : 140}
            showBorder={true}
          />
        </Col>
        <Col className={`music-card__right flex--1 ${isMobile ? "p__l--16" : "p__l--24"}`}>
          <Paragraph ellipsis={{ rows: 2 }} preset={isMobile ? "semibold14" : "semibold18"}>
            {product?.title}
          </Paragraph>
          {product.code && product.code.rate && (
            <Paragraph
              ellipsis={{ rows: 1 }}
              className={`${isMobile ? "" : "m__t--4"} d--block opacity--05 cursor-pointer`}
              preset={isMobile ? "regular14" : "regular18"}
              onClick={onOpenStoreUrl}
            >
              Get {product.code.rate}% Off
            </Paragraph>
          )}
        </Col>
      </Row>
      <div className="Special__offer__modal__content__wrapper">
        <div className={`Special__offer__modal__content p__x--${isMobile ? 16 : 24} p__y--${isMobile ? 16 : 32}`}>
          <Paragraph preset={isMobile ? "regular14" : "regular16"} className="text--default opacity--06">
            Copy the code below and visit the store to claim your discount.
          </Paragraph>
          <Paragraph preset={"regular12"} className="text--default opacity--06 m__t--8">
            *Exclusions apply
          </Paragraph>
          <Paragraph
            preset={isMobile ? "regular12" : "regular14"}
            className={`${isMobile ? "m__t--16" : "m__t--24"} m__b--4 text--default`}
          >
            Discount Code
          </Paragraph>
          <div className="Special__offer__modal__copy--btn d--flex p--12 cursor-pointer" onClick={handleCopyCode}>
            <Paragraph preset="semibold16" ellipsis={{ rows: 1 }}>
              {product.code && product.code.display ? product.code.display : ""}
            </Paragraph>
            <div className="copy-text">Copy Code</div>
          </div>
          <div
            className={`Special__offer__modal__btn-wrapper m__t--${isMobile ? 24 : 32} d--flex justify__content--end`}
          >
            <Button className="cancel-btn" onClick={handleCancel}>
              CANCEL
            </Button>

            <Button onClick={onOpenStoreUrl} className="visit-store-btn">
              VISIT STORE
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShopMyShelfModal;
