import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Modal from "components/Modal";
import { Option, Select } from "components/Select";
import { Text } from "components/Typography";
import { countries } from "constants/nationality";
import { isValidPhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { ReactComponent as DatePickerIcon } from "public/static/assets/icons/datepicker.svg";
import { ReactComponent as LeftIcon } from "public/static/assets/icons/sLeft.svg";
import { ReactComponent as RightIcon } from "public/static/assets/icons/sRight.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import PhoneInput from "react-phone-input-2";
import { FieldItem, FieldType, LEGAL_TYPE } from "redux/User/types";

moment.updateLocale("en", {
  weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
});

interface Props {
  isLoading: boolean;
  show: boolean;
  toggleModal(show: boolean): void;
  title: string;
  subtitle: string;
  fields: FieldItem[];
  isDarken: boolean;
  handleSubmitForm(values: any): void;
}

const DataCaptureModal: React.FC<Props> = ({
  isLoading,
  show,
  toggleModal,
  fields,
  isDarken,
  title,
  subtitle,
  handleSubmitForm,
}) => {
  const [form] = useForm();

  const RequireMark = ({ required, children }: any) => {
    return required ? (
      <>
        {children}
        <span className="text--red">*</span>
      </>
    ) : (
      children
    );
  };

  const handleSubmit = useCallback(() => {
    const values = form.getFieldsValue();
    handleSubmitForm(values);
  }, [form, handleSubmitForm]);

  const RenderPhoneInput = ({ name, label, required }: FieldItem) => {
    const [phone, setPhone] = useState("");

    const checkPhoneNumber = () => {
      if (!required) return Promise.resolve();
      if (!phone) {
        return Promise.reject("Please enter phone number");
      } else {
        if (!isValidPhoneNumber(`+${phone}`)) {
          return Promise.reject("Please enter a valid phone number");
        }
      }
      return Promise.resolve();
    };

    return (
      <Form.Item
        required={required}
        label={<RequireMark required={required}>{label}</RequireMark>}
        name={name}
        rules={[{ validator: checkPhoneNumber }]}
      >
        <PhoneInput
          country="us"
          buttonClass="phone-button-select"
          inputClass="phone-input"
          placeholder="Enter phone number"
          countryCodeEditable={false}
          onChange={(value) => setPhone(value)}
        />
      </Form.Item>
    );
  };

  const RenderCountryInput = ({ name, label, required }: FieldItem) => {
    return (
      <Form.Item
        className="m__t--4"
        label={<RequireMark required={required}>{label}</RequireMark>}
        name={name}
        rules={[
          {
            required: required,
            message: "Please select a country",
          },
        ]}
      >
        <Select placeholder="Select a country" dropdownClassName="country-dropdown" placement="bottomRight">
          {Object.keys(countries).map((key, index) => (
            <Option className="p__y--16 p__x--20" value={key?.toLowerCase()} key={index}>
              <img
                width={20}
                height={20}
                style={{ borderRadius: "100%", objectFit: "cover" }}
                className="m__r--8"
                alt={countries[key?.toUpperCase()]}
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${key.toUpperCase()}.svg`}
              />
              <Text preset="semibold14">{countries[key?.toUpperCase()]}</Text>
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const RenderDatePicker = ({ name, label, required }: FieldItem) => {
    const [visibleDatePickerModal, setVisibleDatePicker] = useState(false);

    return (
      <Form.Item
        className="m__t--4"
        name={name}
        label={<RequireMark required={required}>{label}</RequireMark>}
        rules={[
          {
            required: required,
            message: "Please select a date",
          },
        ]}
      >
        <DatePicker
          getPopupContainer={(triggerNode: HTMLElement) =>
            isMobile ? triggerNode : (triggerNode.parentNode as HTMLElement)
          }
          open={visibleDatePickerModal}
          onOpenChange={(val) => setVisibleDatePicker(val)}
          panelRender={(panelNode) =>
            isMobile
              ? visibleDatePickerModal && (
                  <div
                    className="data-capture-form-datepicker"
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    {panelNode}
                  </div>
                )
              : panelNode
          }
          dropdownClassName={
            isMobile
              ? visibleDatePickerModal
                ? "data-capture-form-datepicker--modal"
                : ""
              : "data-capture-form-datepicker"
          }
          className="full-width p__y--0"
          placeholder="Select a date"
          format="MM/DD/YYYY"
          allowClear={false}
          suffixIcon={<DatePickerIcon />}
          showToday={false}
          nextIcon={<RightIcon />}
          prevIcon={<LeftIcon />}
          superNextIcon={<RightIcon />}
          superPrevIcon={<LeftIcon />}
          inputReadOnly
        />
      </Form.Item>
    );
  };

  const RenderCheckboxGroup = ({ name, label, required, options }: FieldItem) => {
    const optionsData: any = options?.map((option) => ({
      label: option.label,
      value: option.value || option.label,
    }));

    useEffect(() => {
      if (options && name && !form.getFieldValue(name)) {
        const res: any = [];
        options?.forEach((option: any) => {
          if (option.checked) res.push(option.value || option.label);
        });
        form.setFieldsValue({ [name]: res });
      }
    }, [name, options]);

    const isDefaultChecked = useMemo(
      () => (options?.length || 0) > 0 && options?.filter((option) => option.checked)?.length === options?.length,
      [options],
    );

    const checkCheckbox = () => {
      if (!required && !isDefaultChecked) return Promise.resolve();
      if (name) {
        const values = form.getFieldValue(name);
        if (!values?.length) {
          return Promise.reject("Please make a selection");
        } else if (isDefaultChecked && values?.length < (options?.length || 0)) {
          return Promise.reject("Please make a selection");
        }
      }
      return Promise.resolve();
    };

    return (
      <Form.Item
        className="checkbox-form"
        name={name}
        label={<RequireMark required={required}>{label}</RequireMark>}
        rules={[{ validator: checkCheckbox }]}
      >
        <Checkbox.Group options={optionsData} />
      </Form.Item>
    );
  };

  const RenderRadioGroup = ({ name, label, required, options }: FieldItem) => {
    const optionsData: any = options?.map((option) => ({
      label: option.label,
      value: option.value || option.label,
    }));

    return (
      <Form.Item
        className="radio-form"
        label={<RequireMark required={required}>{label}</RequireMark>}
        name={name}
        rules={[
          {
            required: required,
            message: "Please make a selection",
          },
        ]}
      >
        <Radio.Group size="large" options={optionsData} />
      </Form.Item>
    );
  };

  const RenderTextArea = ({ name, label, required }: FieldItem) => {
    return (
      <Form.Item
        name={name}
        label={<RequireMark required={required}>{label}</RequireMark>}
        rules={[
          {
            required: required,
            message: "Please fill this out",
          },
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} placeholder="Please fill this out" />
      </Form.Item>
    );
  };

  const RenderInput = ({ name, label, required }: FieldItem) => {
    return (
      <FormItem
        name={name}
        label={<RequireMark required={required}>{label}</RequireMark>}
        rules={[
          {
            required: required,
            message: "Please fill this out",
          },
        ]}
      >
        <Input placeholder="Please fill this out" />
      </FormItem>
    );
  };

  const RenderEmailInput = ({ name, label, required }: FieldItem) => {
    return (
      <FormItem
        name={name}
        label={<RequireMark required={required}>{label}</RequireMark>}
        rules={[
          {
            type: "email",
            message: "Please enter a valid email",
          },
          {
            required: required,
            message: "Please enter your email address",
          },
        ]}
      >
        <Input placeholder="Enter your email address" />
      </FormItem>
    );
  };

  const RenderPlainText = ({ name, title, content }: any) => {
    useEffect(() => {
      if (form && name && !form.getFieldValue(name)) {
        form.setFieldsValue({ [name]: content });
      }
    }, [content, name]);

    return (
      <Form.Item name={name}>
        <div className="m__b--16">
          <Text preset="semibold16">{title}</Text>
          {content && (
            <Text className="d--block m__t--4" preset="regular14">
              {content}
            </Text>
          )}
        </div>
      </Form.Item>
    );
  };

  return (
    <Modal
      centered
      closeIcon={<></>}
      footer={false}
      className="data-capture-modal m__y--40"
      visible={show}
      onCancel={() => toggleModal(false)}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          background: isDarken
            ? "linear-gradient(0deg, rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.1)), var(--ant-primary-color)"
            : "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--ant-primary-color)",
          height: title ? "auto" : "24px",
          padding: title ? "24px" : "0",
          borderRadius: "16px 16px 0 0",
        }}
      >
        <Text style={{ color: "var(--ant-info-color)" }} preset="semibold20">
          {title}
        </Text>
        {subtitle && (
          <Text style={{ color: "var(--ant-info-color)" }} className="d--block m__t--4 opacity-05">
            {subtitle}
          </Text>
        )}
      </div>
      <div className={isMobile ? "data-capture-modal__scroll p__x--16 p__y--24" : "data-capture-modal__scroll p--24"}>
        <Form requiredMark={false} form={form} layout="vertical" onFinish={handleSubmit}>
          {fields?.map((field) => {
            switch (field.type) {
              case FieldType.INPUT:
                return <RenderInput key={field.name} name={field.name} label={field.label} required={field.required} />;
              case FieldType.TEXTAREA:
                return (
                  <RenderTextArea key={field.name} name={field.name} label={field.label} required={field.required} />
                );
              case FieldType.EMAIL_ADDRESS:
                return (
                  <RenderEmailInput key={field.name} name={field.name} label={field.label} required={field.required} />
                );
              case FieldType.PHONE_NUMBER:
                return (
                  <RenderPhoneInput key={field.name} name={field.name} label={field.label} required={field.required} />
                );
              case FieldType.SELECT_COUNTRY:
                return (
                  <RenderCountryInput
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    required={field.required}
                  />
                );
              case FieldType.SELECT_DATE:
                return (
                  <RenderDatePicker key={field.name} name={field.name} label={field.label} required={field.required} />
                );
              case FieldType.SELECT_MULTIPLE:
                return (
                  <RenderCheckboxGroup
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    options={field.options}
                  />
                );
              case FieldType.SELECT_SINGLE:
                return (
                  <RenderRadioGroup
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    options={field.options}
                  />
                );
              case FieldType.LEGALS:
                return field?.legalType !== LEGAL_TYPE.PLAIN_TEXT ? (
                  <RenderCheckboxGroup
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    options={field.options}
                  />
                ) : (
                  <RenderPlainText key={field.name} name={field.name} title={field?.label} content={field?.text} />
                );
            }
          })}
          <div className="btn-actions m__t--24">
            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={isLoading}>
                Submit
              </Button>
            </Form.Item>

            <Button className="ant-btn-back" block onClick={() => toggleModal(false)}>
              <Text preset={isMobile ? "bold14" : "bold16"}>Cancel</Text>
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default DataCaptureModal;
