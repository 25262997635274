import Button from "antd/lib/button";
import Divider from "antd/lib/divider";
import classNames from "classnames";
import Swiper from "components/Swiper";
import { Paragraph } from "components/Typography";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import { ReactComponent as PlayIcon } from "public/static/assets/icons/play4.svg";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player/youtube";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/rootReducer";
import { setPlayerPlayingAction } from "redux/Talent/actions";
import { selectPlayerPlaying } from "redux/Talent/selector";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { TalentProfileModule, YoutubeVideoItem } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { useResize } from "utils/responsive";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

//property interface for youtube player
interface IYoutubePlayerProps {
  module: TalentProfileModule;
  item: YoutubeVideoItem;
  width: number;
  height: number;
  isFullWidth: boolean;
  isMobile: boolean;
  isPreview?: boolean;
  talent?: Talent;
}

interface IYoutubePlayerSelfProps extends IYoutubePlayerProps {
  user?: any;
  playerPlaying?: any;
  dispatch: Dispatch<any>;
}

interface IYoutubePlayerSelf {
  isPlaying: boolean;
  openPlayer: boolean;
  handlePlayVideo(): void;
  setPlaying: Dispatch<SetStateAction<boolean>>;
  mapAnalytics(): void;
}

export function useYoutubePlayerSelf({
  isPreview,
  talent,
  module,
  item,
  isMobile,
  user,
  playerPlaying,
  dispatch,
}: IYoutubePlayerSelfProps): IYoutubePlayerSelf {
  const [isPlaying, setPlaying] = useState(false);
  const [openPlayer, setOpenPlayer] = useState(false);

  useEffect(() => {
    if (isPlaying && item?.url !== playerPlaying) {
      setPlaying(false);
    }
  }, [item?.url, playerPlaying, isPlaying]);

  //map segment event
  const mapAnalytics = useCallback(() => {
    return {
      "User id": user?.id,
      Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
      Location: Cookies.get(KOMI_USER_LOCATION),
      "Talent ID": talent?.id,
      "Module ID": module?.id,
      "Module Name": module?.name,
      "Element ID": item?.id,
      "Element Name": item?.metadata?.title,
      "Element URL": item?.url,
      Platform: isMobile ? "Responsive" : "Web",
      "Page ID": module?.localizationId || null,
      "Page Name": module?.localizationName || "Default",
    };
  }, [
    isMobile,
    item?.id,
    item?.metadata?.title,
    item?.url,
    module?.id,
    module?.localizationId,
    module?.localizationName,
    module?.name,
    talent?.id,
    user,
  ]);

  const handlePlayVideo = useCallback(() => {
    setOpenPlayer(true);
    dispatch(setPlayerPlayingAction(item.url));
    setPlaying(true);

    if ((window as any)?.analytics && !isPreview) {
      const event = mapAnalytics();
      AnalyticServices.track(SEGMENT_EVENT.CLICK_YOUTUBE_ELEMENT, event);
    }
  }, [dispatch, isPreview, item.url, mapAnalytics]);

  return {
    isPlaying,
    handlePlayVideo,
    setPlaying,
    mapAnalytics,
    openPlayer,
  };
}

export function getYoutubeVideoId(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

const YoutubePlayer = (props: IYoutubePlayerProps) => {
  const playerRef = useRef(null);
  const user = useTypedSelector(selectUserData);
  const playerPlaying = useTypedSelector(selectPlayerPlaying);
  const dispatch = useDispatch();
  const { isPlaying, handlePlayVideo, setPlaying, openPlayer } = useYoutubePlayerSelf({
    ...props,
    user,
    playerPlaying,
    dispatch,
  });

  useEffect(() => {
    if (isPlaying && props.item?.url !== playerPlaying) {
      setPlaying(false);
    }
  }, [props.item?.url, playerPlaying, isPlaying, setPlaying]);
  return (
    <div
      className={classNames({ "youtube-player": true, "youtube-player--full-width": props.isFullWidth })}
      style={{ width: props.width, height: props.height }}
    >
      {!isPlaying && props.item.metadata && (
        <div className="youtube-player__overlay">
          <div className="youtube-player__overlay__name">
            <div className="position--relative full-height">
              <div className="youtube-player__overlay__name__background" />
              <Paragraph ellipsis={{ rows: 1 }} preset={props.isMobile ? "regular16" : "medium20"}>
                {props.item.metadata?.title}
              </Paragraph>
            </div>
          </div>
          <img src={props.item.metadata.thumbnail_url} alt="youtube" />
          <Button type="text" className="p--0" onClick={handlePlayVideo}>
            <PlayIcon />
          </Button>
        </div>
      )}
      {openPlayer && (
        <ReactPlayer
          ref={playerRef}
          playing={isPlaying}
          onBuffer={() => setPlaying(true)}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          url={`https://www.youtube.com/watch?v=${getYoutubeVideoId(props.item.url)}`}
          config={{ playerVars: { origin: undefined } }}
          width={props.width}
          height={props.height}
          controls
          muted
          autoPlay={false}
          onReady={(player) => {
            setTimeout(() => {
              const video = player.getInternalPlayer();
              video.playVideo();
            }, 100);
          }}
        />
      )}
    </div>
  );
};

interface ISelf {
  links: YoutubeVideoItem[];
  itemsRender: YoutubeVideoItem[];
  params: any;
  isFullWidth: boolean;
  videoWidth: number;
  videoHeight: number;
  module: TalentProfileModule;
  isMobile: boolean;
}

export function useTalentDetailYoutubeSelf({ module, isMobile }: IProps): ISelf {
  const { width } = useResize();

  const [itemsRender, setItemsRender] = useState<YoutubeVideoItem[]>([]);

  const links = useMemo(() => module?.items as YoutubeVideoItem[], [module?.items]);

  const isFullWidth = useMemo(() => links?.length === 1, [links?.length]);

  const params: any = useMemo(
    () => ({
      slidesPerView: isFullWidth ? 1 : isMobile ? 1.0929 : 1.488,
      spaceBetween: 16,
      noSwiping: isMobile,
      navigation: !isMobile,
      centeredSlides: true,
      centeredSlidesBounds: true,
      slidesOffsetAfter: isMobile ? 16 : 0,
    }),
    [isFullWidth, isMobile],
  );

  const videoWidth = useMemo(
    () => (isFullWidth ? (isMobile ? width - 32 : 587) : isMobile ? 327 : 387),
    [isFullWidth, isMobile, width],
  );

  const videoHeight = useMemo(() => (videoWidth * 9) / 16, [videoWidth]); // ratio: 16/9

  useEffect(() => {
    if (links?.length) {
      setItemsRender([...links]);
    }
  }, [links]);

  return {
    links,
    itemsRender,
    params,
    isFullWidth,
    videoWidth,
    videoHeight,
    module,
    isMobile,
  };
}

const TalentDetailYoutube: React.FC<IProps> = (props) => {
  const { links, itemsRender, params, isFullWidth, videoWidth, videoHeight, module, isMobile } =
    useTalentDetailYoutubeSelf(props);

  return links?.length > 0 ? (
    <div id={module.id} className={`${props.className}`}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "p__x--16": isMobile && links?.length === 1,
            "p__l--16": isMobile && links?.length > 1,
            "fix-width": links?.length === 1,
          })}
          isMobile={isMobile}
          title={module.name}
          isGroup={props.isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          typeModule={module.type}
          items={itemsRender
            ?.filter((x) => x.visible)
            .map((link, i) => (
              <YoutubePlayer
                talent={props.talent}
                module={module}
                isMobile={isMobile}
                isFullWidth={isFullWidth}
                key={i}
                item={link}
                width={videoWidth}
                height={videoHeight}
                isPreview={props.isPreview}
              />
            ))}
        />

        {!props.isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailYoutube;
