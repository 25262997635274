import Button from "antd/lib/button";
import { Text } from "components/Typography";
import { useRouter } from "next/router";
import React, { useMemo, useEffect, useState } from "react";
import Swiper from "components/Swiper";
import { BaseItem, TalentProfileModule, TalentProfileModuleType } from "redux/User/types";
import { calculateGroupVisible, isBaseItem } from "utils/visibilityUtils";

interface IProps {
  isMobile: boolean;
  className?: string;
  modules: TalentProfileModule[];
  isPreview: boolean;
  loading?: boolean;
}

const TalentDetailTabs = ({ className = "", isMobile, modules, isPreview, loading }: IProps) => {
  const router = useRouter();

  const [visibleModules, setVisibleModules] = useState(modules);

  useEffect(() => {
    const newVisibleModules: TalentProfileModule[] = [];
    // For each module
    modules.forEach((module) => {
      if (!module.visible) return;
      if (module.items.length === 0) return;
      // If it's a group, calculate visiblity
      if (module.type === TalentProfileModuleType.GROUP) {
        if (!calculateGroupVisible(module)) return;
      }
      // If the items aren't of baseItem then add it and return
      if (!isBaseItem(module.items[0])) {
        newVisibleModules.push(module);
        return;
      }
      // If it is of base item, cast it and check all items are visible
      const moduleItems: BaseItem[] = module.items;
      const baseItems = moduleItems.filter(isBaseItem);
      // If all items are not visible, hide the module
      if (baseItems.filter((x) => x.visible === true).length === 0) return;

      // Show
      newVisibleModules.push(module);
    });
    setVisibleModules(newVisibleModules);
  }, [modules]);

  const params: any = useMemo(
    () => ({
      slidesPerView: "auto",
      spaceBetween: 8,
      freeMode: true,
      mousewheel: true,
    }),
    [],
  );

  const tabs = useMemo(
    () =>
      visibleModules.map((module) => ({
        url: `#${module.id}`,
        title: module.name,
      })),
    [visibleModules],
  );

  useEffect(() => {
    if (window?.location?.hash && !loading) {
      let hash = window?.location?.hash;
      if (hash?.[0] === "#") {
        hash = hash.substring(1);
      }
      setTimeout(() => {
        document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" });
      }, 400);
    }
  }, [loading]);

  return (
    <Swiper
      isMobile={isMobile}
      params={params}
      items={tabs?.map((tab, i) => {
        const isActive =
          (i === 0 && router?.asPath.search("#") === -1) ||
          router?.asPath.match(`/${router.query.username || ""}(\\?countryCode=\\w{2})${tab.url}`) ||
          router?.asPath === `/${router.query.username}${tab.url}` ||
          router?.asPath === `/talent-profile-preview${tab.url}` ||
          router?.asPath === `/${tab.url}`;
        const title = tab.title.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        return title ? (
          <Button
            key={tab.url}
            type="text"
            className={`talent-detail-tab ${isActive && "active"}`}
            onClick={() => {
              document.getElementById(tab.url.substring(1))?.scrollIntoView({ behavior: "smooth" });
              router.replace(tab.url);
            }}
          >
            <Text
              className={isActive ? "" : "opacity--08"}
              preset={isActive ? (isMobile ? "semibold18" : "semibold20") : isMobile ? "regular18" : "regular20"}
            >
              {title}
            </Text>
          </Button>
        ) : (
          <></>
        );
      })}
      className={`talent-detail-tab_swiper ${isMobile ? "m__l--16" : ""} ${className}`}
    />
  );
};

export default TalentDetailTabs;
