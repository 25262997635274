import { Col, Row } from "antd";
import * as React from "react";
import Skeleton from "react-loading-skeleton";

interface IProps {
  horizontal?: boolean;
}

const LoadingModule: React.FC<IProps> = ({ horizontal }) => {
  if (horizontal) {
    return (
      <div className="loading-module">
        <Row align="middle" className="loading-module__item">
          <Col className="loading-module__item--photo">
            <Skeleton containerClassName="picture" width={140} height={140} />
          </Col>
          <Col className="p--24" flex="1">
            <Skeleton className="border__radius--8" height={20} />
            <Skeleton className="border__radius--8 m__t--16" height={20} />
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Skeleton className="border__radius--8" width={140} height={140} />
      <Skeleton className="border__radius--8 m__t--16" width={140} height={24} />
    </div>
  );
};

export default LoadingModule;
