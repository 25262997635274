import React from "react";
import { Experience, ExperienceTypeLabel } from "redux/Experience/types";

interface IProps {
  experience: Experience | undefined;
  className?: string;
}

const ExperienceTypeTag = ({ experience, className }: IProps) => {
  return (
    <div className={`experience-type-tag ${experience?.type} ${className}`}>
      {ExperienceTypeLabel.get(experience?.type as string)}
    </div>
  );
};

export default ExperienceTypeTag;
