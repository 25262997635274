import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageProgessive from "components/ImageProgessive";
import { LoadingModule } from "components/LoadingModule";
import ShopMyShelfModal from "components/ShopMyShelfComponent/ShopMyShelfModal";
import { ReactComponent as IconNoPhoto } from "public/static/assets/icons/no-photo.svg";
import Swiper from "components/Swiper";
import { Paragraph } from "components/Typography";
import { SEGMENT_EVENT } from "constants/segment";
import React, { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { ProductShopListItem, TalentProfileModule, TalentProfileModuleType } from "redux/User/types";
import { AnalyticServices } from "utils/analytics";
import { getTalentName } from "utils/experience";
import { convertToUrl } from "utils/string";
import { useTimeout } from "hooks";
import { getImageOrFallback } from "utils/photo";
interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  showDivider: boolean;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
  isDarken?: boolean;
}

interface ExperienceCardIProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  product: ProductShopListItem;
  isLongCard?: boolean;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
  isDarken?: boolean;
}

const ProductCard = ({
  product,
  isMobile,
  isPreview,
  className,
  isLongCard,
  module,
  talent,
  isGroup,
  isDarken,
}: ExperienceCardIProps) => {
  const user = useTypedSelector(selectUserData);
  const [showModal, setShowModal] = useState(false);
  const [simulateLoading, setSimulateLoading] = useState(true);
  const [image, setImage] = useState(product?.image);

  useTimeout(() => setSimulateLoading(false), 500);

  const handleClickProduct = React.useCallback(() => {
    if ((window as any)?.analytics && !isPreview) {
      AnalyticServices.track(
        module.type === TalentProfileModuleType.SHOP_LIST
          ? SEGMENT_EVENT.CLICK_SHOPLIST_PRODUCT
          : SEGMENT_EVENT.CLICK_SHOP_MY_SHELF_PRODUCT,
        {
          "Module ID": module?.id,
          "Module Name": module?.name,
          "Element Name": product?.title,
          "Talent ID": talent?.id,
          "Talent Name": getTalentName(talent, true),
          "Page Name": module?.localizationName || "Default",
          "User id": user?.id,
          "Element ID": product?.id,
          "Element URL": product?.url,
          Platform: isMobile ? "Responsive" : "Web",
          "Page ID": module?.localizationId || null,
        },
      );
    }
    if (product.code) {
      setShowModal(true);
    } else {
      window.open(product?.url, "_blank");
    }
  }, [isPreview, product?.url, product?.id, product?.title, user, talent?.id, isMobile]);

  useEffect(() => {
    const getImage = async () => {
      const image = await getImageOrFallback(product?.image, null);
      setImage(image as any);
    };
    getImage().catch(console.error);
  }, [product?.image]);

  if (simulateLoading) {
    return <LoadingModule horizontal={isLongCard} />;
  }

  return (
    <>
      <div
        className={`talent-detail__experience-card product-card cursor-pointer ${isLongCard && "long full-width"} ${
          isGroup && "border-card-inside-group"
        }  ${className}`}
        onClick={handleClickProduct}
      >
        {isLongCard ? (
          <Row className={`link-card ${className}`} align="middle">
            <Col>
              {image ? (
                <ImageProgessive
                  ratio={0.25}
                  threshold={[0.25, 1]}
                  src={image as string}
                  width={isMobile ? 115 : 140}
                  height={isMobile ? 115 : 140}
                  borderTopLeftRadius={8}
                  borderBottomLeftRadius={8}
                  showBorder={true}
                />
              ) : (
                <div
                  className={classNames("product-card__no-image", { "product-card__no-image--dark": isDarken })}
                  style={{ width: isMobile ? 115 : 140, height: isMobile ? 115 : 140 }}
                >
                  <IconNoPhoto />
                </div>
              )}
            </Col>
            <Col className={`flex--1 ${isMobile ? "p__x--16" : "p__x--24"}`}>
              <Paragraph preset={isMobile ? "semibold14" : "semibold20"} ellipsis={{ rows: 3 }}>
                {product?.title}
              </Paragraph>
            </Col>
          </Row>
        ) : (
          <>
            {image ? (
              <ImageProgessive
                ratio={0.25}
                threshold={[0.25, 1]}
                src={image as string}
                width={isMobile ? 115 : 140}
                height={isMobile ? 115 : 140}
                borderRadius={8}
                showBorder={true}
              />
            ) : (
              <div
                className={classNames("product-card__no-image product-card__no-image--vertical", {
                  "product-card__no-image--dark": isDarken,
                })}
                style={{ width: isMobile ? 115 : 140, height: isMobile ? 115 : 140 }}
              >
                <IconNoPhoto />
              </div>
            )}
            <Paragraph className="m__t--16" preset={isMobile ? "semibold14" : "semibold16"} ellipsis={{ rows: 2 }}>
              {product?.title}
            </Paragraph>
          </>
        )}
      </div>
      <ShopMyShelfModal
        show={showModal}
        toggleModal={() => setShowModal(false)}
        isMobile={isMobile}
        product={product}
        module={module}
        talent={talent}
      />
    </>
  );
};

const TalentDetailShopMyShelf = ({
  className = "",
  isMobile,
  isPreview,
  module,
  showDivider,
  talent,
  isGroup,
  isDarken,
}: IProps) => {
  const [itemsRender, setItemsRender] = useState<ProductShopListItem[]>([]);

  const products = useMemo(() => module?.items as ProductShopListItem[], [module?.items]);

  const minElements = useMemo(() => (isMobile ? 2 : 3), [isMobile]);

  const params: any = useMemo(
    () => ({
      slidesPerView: products?.length <= minElements ? 1 : isMobile ? "auto" : 3.725,
      slidesPerGroup: isMobile ? 1 : 3,
      slidesPerColumn: products?.length <= minElements ? minElements : 1,
      spaceBetween: products?.length <= minElements ? 16 : isMobile ? 16 : 24,
      navigation: !isMobile,
      noSwiping: isMobile,
      slidesOffsetAfter: 16,
    }),
    [isMobile, minElements, products?.length],
  );

  useEffect(() => {
    if (products?.length) {
      setItemsRender([...products]);
    }
  }, [products, isPreview]);

  return products?.length > 0 ? (
    <div id={module.id} className={`${className}`}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "p__x--16": isMobile && products?.length <= minElements,
            "p__l--16": isMobile && products?.length > minElements,
          })}
          isMobile={isMobile}
          title={module?.name}
          params={{ ...params }}
          isGroup={isGroup}
          showTitle={module.showTitle}
          items={itemsRender
            ?.filter((x) => x.visible)
            .map((product, i) => (
              <ProductCard
                talent={talent}
                module={module}
                isLongCard={products?.length <= minElements}
                key={i}
                product={product}
                isMobile={isMobile}
                isPreview={isPreview}
                isGroup={isGroup}
                isDarken={isDarken}
              />
            ))}
        />
        {showDivider && !isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailShopMyShelf;
