import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import Swiper from "components/Swiper";
import { Paragraph, Text } from "components/Typography";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import { useWindowSize } from "hooks";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { selectActiveModule } from "redux/Talent/selector";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { EventItem, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

interface IEventCardProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  link: EventItem;
  isBig: boolean;
  isPreview?: boolean;
  talent?: Talent;
}

const EventCard = ({ link, isMobile, className = "", isBig, isPreview, module, talent }: IEventCardProps) => {
  const user = useTypedSelector(selectUserData);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  const handleOpenLink = useCallback(
    (segmentEvent) => {
      if ((window as any)?.analytics && !isPreview) {
        AnalyticServices.track(segmentEvent, {
          "User id": user?.id,
          Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
          Location: Cookies.get(KOMI_USER_LOCATION),
          "Talent ID": talent?.id,
          "Talent Name": `${talent?.talentProfile?.firstName} ${talent?.talentProfile?.lastName}`,
          "Module ID": module?.id,
          "Module Name": module?.name,
          "Element ID": link?.id,
          "Element Name": link?.venueName,
          "Element URL": link?.ticketLink,
          Platform: isMobile ? "Responsive" : "Web",
          "Page ID": module?.localizationId || null,
          "Page Name": module?.localizationName || "Default",
        });
      }
      window.open(link?.ticketLink, "_blank");
    },
    [
      isPreview,
      link?.ticketLink,
      link?.id,
      link?.venueName,
      user,
      talent?.id,
      talent?.talentProfile?.firstName,
      talent?.talentProfile?.lastName,
      module?.id,
      module?.name,
      module?.localizationId,
      module?.localizationName,
      isMobile,
    ],
  );

  const eventDate = useMemo(() => {
    return moment.utc(link?.eventDate);
  }, [link?.eventDate]);
  const presetTitle = useMemo(() => {
    if (isBig) {
      return isMobile ? "semibold14" : "semibold20";
    }
    return isMobile ? "semibold14" : "semibold16";
  }, [isMobile, isBig]);
  const presetSubTitle = useMemo(() => {
    if (isBig) {
      return isMobile ? "regular14" : "regular18";
    }
    return isMobile ? "regular12" : "regular14";
  }, [isMobile, isBig]);

  return (
    <Row
      className={`link-card link-card--bandsintown cursor-pointer ${className}`}
      align="middle"
      onClick={() => handleOpenLink(SEGMENT_EVENT.CLICK_CUSTOM_EVENT_TICKET_LINK)}
    >
      <Col>
        <div className="link-card__date">
          <SVG
            stroke={isDarken ? "#121212" : "#ffffff"}
            strokeOpacity={0.1}
            src="/static/assets/icons/bandsintown.svg"
          />
          <div>
            <Text preset={isMobile ? "medium12" : "medium14"} className="opacity--05">
              {eventDate?.format("MMM")}
            </Text>
            <Text preset={isMobile ? "bold32" : "bold40"}>{moment(eventDate)?.date()}</Text>
            <Text preset={isMobile ? "medium12" : "medium14"} className="opacity--05">
              {moment(eventDate)?.year()}
            </Text>
          </div>
        </div>
      </Col>
      <Col className={`flex--1 ${isMobile ? (isBig ? "p__l--16" : "p__x--12") : "p__l--24"}`}>
        <Paragraph ellipsis={{ rows: 1 }} className="m__b--0" preset={presetTitle}>
          {link?.venueName}
        </Paragraph>
        <Paragraph
          ellipsis={{ rows: 1 }}
          className={`m__b--0 m__t--${isMobile ? 0 : 4} opacity--05`}
          preset={presetSubTitle}
        >
          {link?.location || ""}
        </Paragraph>
        {link.soldOut ? (
          <Text
            preset={isMobile ? "semibold12" : "medium18"}
            className={`d--block opacity--08 ${isMobile ? "m__t--12" : "m__t--16"}`}
          >
            Sold Out
          </Text>
        ) : (
          <Row gutter={isMobile ? 12 : 16} className={`m__t--${isMobile ? 12 : 16}`}>
            <Col>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenLink(SEGMENT_EVENT.CLICK_CUSTOM_EVENT_TICKET_LINK);
                }}
              >
                <Text preset={isMobile ? "medium12" : "semibold16"}>Tickets</Text>
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

const TalentDetailEvents: React.FC<IProps> = ({ className = "", isMobile, isPreview, module, talent, isGroup }) => {
  const [itemsRender, setItemsRender] = useState<EventItem[]>([]);
  const { width } = useWindowSize();

  const activeModule = useTypedSelector(selectActiveModule);

  const params: any = useMemo(
    () => ({
      slidesPerView: module.items?.length <= 3 ? 1 : isMobile ? ((width || 0) < 350 ? 1.0592 : 1.2508) : 1.5049,
      slidesPerColumn: module.items?.length <= 3 ? module.items?.length : 2,
      spaceBetween: module.items?.length <= 3 ? 16 : isMobile ? 0 : 24,
      freeMode: isMobile,
      navigation: !isMobile,
      centeredSlides: !isMobile,
      centeredSlidesBounds: !isMobile,
      slidesOffsetAfter: isMobile ? 16 : 0,
      noSwiping: true,
    }),
    [isMobile, module.items?.length, width],
  );

  useEffect(() => {
    if (module.items?.length) {
      setItemsRender([...module.items] as EventItem[]);
    }
  }, [module.items]);

  useEffect(() => {
    if (module.items?.length && activeModule === module?.id && isPreview) {
      document.getElementById(module.id)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeModule, isPreview, module?.id, module.items?.length, module?.name, module?.order]);

  return module.items?.length > 0 ? (
    <div id={module.id} className={`talent-detail__links ${className}`}>
      <div>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "multiple-row": module.items?.length > 3,
            "p__x--16": isMobile && module.items?.length <= 3,
            "p__l--16": isMobile && module.items?.length > 3,
            "fix-width": module.items?.length <= 3,
          })}
          isMobile={isMobile}
          title={module.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          items={itemsRender
            ?.filter((x) => x.visible)
            .map((link, i) => (
              <EventCard
                talent={talent}
                module={module}
                isMobile={isMobile}
                isPreview={isPreview}
                key={i}
                link={link}
                isBig={module.items?.length <= 3}
                className={isMobile && module.items?.length > 3 ? "m__r--16" : ""}
              />
            ))}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailEvents;
