import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { SocialProfileLink } from "redux/User/types";
import { LIST_SOCIAL_ICONS } from "constants/social";

interface IProps {
  isMobile: boolean;
  className?: string;
  links: SocialProfileLink[];
}

const TalentDetailSocialLinks = ({ isMobile, className = "", links }: IProps) => {
  return (
    <Row
      gutter={{ xs: 24, md: 32, lg: 32 }}
      align="middle"
      justify="center"
      className={`talent-detail__social ${className}`}
    >
      {links?.map(
        (item) =>
          LIST_SOCIAL_ICONS[item.type] && (
            <Col key={item.type}>
              {item.type === "EMAIL" ? (
                <Button type="text" className="p--0">
                  <a href={"mailto:" + item?.link}> {LIST_SOCIAL_ICONS[item.type]}</a>
                </Button>
              ) : (
                <Button type="text" className="p--0" onClick={() => window.open(item.link, "_blank")}>
                  {LIST_SOCIAL_ICONS[item.type]}
                </Button>
              )}
            </Col>
          ),
      )}
    </Row>
  );
};

export default TalentDetailSocialLinks;
