import React from "react";

import { SocialProfileLinkTypes } from "redux/User/types";

import { ReactComponent as AmazonMusic } from "public/static/assets/icons/socials/amazon.svg";
import { ReactComponent as Apple } from "public/static/assets/icons/socials/apple.svg";
import { ReactComponent as Deezer } from "public/static/assets/icons/socials/deezer.svg";
import { ReactComponent as Facebook } from "public/static/assets/icons/socials/facebook.svg";
import { ReactComponent as Instagram } from "public/static/assets/icons/socials/instagram.svg";
import { ReactComponent as LinkedIn } from "public/static/assets/icons/socials/linkedIn.svg";
import { ReactComponent as Medium } from "public/static/assets/icons/socials/medium.svg";
import { ReactComponent as Pinterest } from "public/static/assets/icons/socials/pinterest.svg";
import { ReactComponent as Reddit } from "public/static/assets/icons/socials/reddit.svg";
import { ReactComponent as Snapchat } from "public/static/assets/icons/socials/snapchat.svg";
import { ReactComponent as Soundcloud } from "public/static/assets/icons/socials/soundcloud.svg";
import { ReactComponent as Spotify } from "public/static/assets/icons/socials/spotify.svg";
import { ReactComponent as Tiktok } from "public/static/assets/icons/socials/tiktok.svg";
import { ReactComponent as Twitch } from "public/static/assets/icons/socials/twitch.svg";
import { ReactComponent as Twitter } from "public/static/assets/icons/socials/twitter.svg";
import { ReactComponent as Globe } from "public/static/assets/icons/socials/globe.svg";
import { ReactComponent as Youtube } from "public/static/assets/icons/socials/youtube.svg";
import { ReactComponent as YoutubeMusic } from "public/static/assets/icons/socials/youtube-music.svg";
import { ReactComponent as Email } from "public/static/assets/icons/socials/email.svg";
import { ReactComponent as Discord } from "public/static/assets/icons/socials/discord.svg";
import { ReactComponent as Bandsintown } from "public/static/assets/icons/socials/bandsintown.svg";
export const LIST_SOCIAL_ICONS: any = {
  [SocialProfileLinkTypes.AMAZON_MUSIC]: <AmazonMusic width={32} height={32} />,
  [SocialProfileLinkTypes.APPLE_MUSIC]: <Apple className="fill-first-path-only" />,
  [SocialProfileLinkTypes.DEEZER]: <Deezer />,
  [SocialProfileLinkTypes.FACEBOOK]: <Facebook />,
  [SocialProfileLinkTypes.INSTAGRAM]: <Instagram />,
  [SocialProfileLinkTypes.LINKEDIN]: <LinkedIn />,
  [SocialProfileLinkTypes.MEDIUM]: <Medium className="fill-first-path-only" />,
  [SocialProfileLinkTypes.PINTEREST]: <Pinterest className="fill-first-path-only" />,
  [SocialProfileLinkTypes.REDDIT]: <Reddit className="fill-first-path-only" />,
  [SocialProfileLinkTypes.SNAPCHAT]: <Snapchat className="fill-first-path-only" />,
  [SocialProfileLinkTypes.SOUNDCLOUD]: <Soundcloud />,
  [SocialProfileLinkTypes.SPOTIFY]: <Spotify />,
  [SocialProfileLinkTypes.TIKTOK]: <Tiktok />,
  [SocialProfileLinkTypes.TWITCH]: <Twitch />,
  [SocialProfileLinkTypes.TWITTER]: <Twitter />,
  [SocialProfileLinkTypes.WEBSITE]: <Globe />,
  [SocialProfileLinkTypes.YOUTUBE]: <Youtube />,
  [SocialProfileLinkTypes.YOUTUBE_MUSIC]: <YoutubeMusic className="fill-first-path-only" />,
  [SocialProfileLinkTypes.EMAIL]: <Email />,
  [SocialProfileLinkTypes.BANDSINTOWN]: <Bandsintown />,
  [SocialProfileLinkTypes.DISCORD]: <Discord />,
};
