import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageProgessive from "components/ImageProgessive";
import Swiper from "components/Swiper";
import { Paragraph, Text } from "components/Typography";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { ProductItem, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { formatCurrencyNonExchange } from "utils/currency";
import { convertToUrl } from "utils/string";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  showDivider: boolean;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

interface ExperienceCardIProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  product: ProductItem;
  isLongCard?: boolean;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

//return interface for product cards
interface IProductCardSelf {
  handlePress(): void;
  productPrice: string;
  mapAnalytics(): void;
}

//extended property interface for product cards
interface useProductCardSelfProps extends ExperienceCardIProps {
  user?: any;
}

export function useProductCardSelf({
  product,
  isMobile,
  isPreview,
  module,
  talent,
  user,
}: useProductCardSelfProps): IProductCardSelf {
  const productPrice = useMemo(() => {
    if (product.currency) {
      return formatCurrencyNonExchange(product.currency, 2).format(product.price);
    }

    return `$${product?.price}`;
  }, [product]);

  const mapAnalytics = () => {
    return {
      "User id": user?.id,
      Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
      Location: Cookies.get(KOMI_USER_LOCATION),
      "Talent ID": talent?.id,
      "Module ID": module?.id,
      "Module Name": module?.name,
      "Element ID": product?.id,
      "Element Name": product?.title,
      "Element URL": product?.url,
      Platform: isMobile ? "Responsive" : "Web",
      "Page ID": module?.localizationId || null,
      "Page Name": module?.localizationName || "Default",
    };
  };

  const handlePress = React.useCallback(() => {
    if ((window as any)?.analytics && !isPreview) {
      const segmentEvent = mapAnalytics();
      AnalyticServices.track(SEGMENT_EVENT.CLICK_PRODUCT_ELEMENT, segmentEvent);
    }
    window.open(product?.url, "_blank");
  }, [
    isPreview,
    product?.url,
    product?.id,
    product?.title,
    user,
    talent?.id,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    isMobile,
  ]);

  return {
    handlePress,
    productPrice,
    mapAnalytics,
  };
}

const ProductCard = ({
  product,
  isMobile,
  isPreview,
  className,
  isLongCard,
  module,
  talent,
  isGroup,
}: ExperienceCardIProps) => {
  const user = useTypedSelector(selectUserData);

  const { handlePress, productPrice } = useProductCardSelf({
    product,
    isMobile,
    isPreview,
    module,
    talent,
    user,
  });

  return (
    <div
      className={`talent-detail__experience-card product-card cursor-pointer ${isLongCard && "long full-width"} ${
        isGroup && "border-card-inside-group"
      }  ${className}`}
      onClick={handlePress}
    >
      {isLongCard ? (
        <Row className={`link-card ${className}`} align="middle">
          <Col>
            <ImageProgessive
              ratio={0.25}
              threshold={[0.25, 1]}
              src={product?.thumbnail as string}
              width={isMobile ? 115 : 140}
              height={isMobile ? 115 : 140}
            />
          </Col>
          <Col className={`flex--1 ${isMobile ? "p__x--16" : "p__x--24"}`}>
            <Paragraph preset={isMobile ? "semibold14" : "semibold20"} ellipsis={{ rows: 2 }}>
              {product?.title}
            </Paragraph>
            <Text preset={isMobile ? "regular12" : "regular18"} className="m__t--4 opacity--08">
              {productPrice}
            </Text>
          </Col>
        </Row>
      ) : (
        <>
          <ImageProgessive
            ratio={0.25}
            threshold={[0.25, 1]}
            src={product?.thumbnail as string}
            width={isMobile ? 115 : 140}
            height={isMobile ? 115 : 140}
            borderRadius={8}
          />
          <Paragraph
            className="m__t--16"
            preset={isMobile ? "semibold14" : "semibold16"}
            ellipsis={{ rows: isMobile ? 2 : 1 }}
          >
            {product?.title}
          </Paragraph>
          <Text preset={isMobile ? "regular12" : "regular14"} className="m__t--4 opacity--08">
            {productPrice}
          </Text>
        </>
      )}
    </div>
  );
};

interface ITalentDetailProductsSelf {
  products: ProductItem[];
  itemsRender: ProductItem[];
  minElements: number;
  params(): void;
}

export function useTalentDetailProductsSelf(isMobile: boolean, module: TalentProfileModule): ITalentDetailProductsSelf {
  const [itemsRender, setItemsRender] = useState<ProductItem[]>([]);

  const products = useMemo(() => module?.items as ProductItem[], [module?.items]);

  const minElements = useMemo(() => (isMobile ? 2 : 3), [isMobile]);

  const params: any = useMemo(
    () => ({
      slidesPerView: products?.length <= minElements ? 1 : isMobile ? "auto" : 3.725,
      slidesPerGroup: isMobile ? 1 : 3,
      slidesPerColumn: products?.length <= minElements ? minElements : 1,
      spaceBetween: products?.length <= minElements ? 16 : isMobile ? 16 : 24,
      navigation: !isMobile,
      noSwiping: isMobile,
      slidesOffsetAfter: 16,
    }),
    [isMobile, minElements, products?.length],
  );

  useEffect(() => {
    if (products?.length) {
      setItemsRender([...products]);
    }
  }, [products]);

  return {
    products,
    itemsRender,
    minElements,
    params,
  };
}

const TalentDetailProducts = ({
  className = "",
  isMobile,
  isPreview,
  module,
  showDivider,
  talent,
  isGroup,
}: IProps) => {
  const { products, itemsRender, minElements, params } = useTalentDetailProductsSelf(isMobile, module);

  return products?.length > 0 ? (
    <div id={module.id} className={`${className}`}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "p__x--16": isMobile && products?.length <= minElements,
            "p__l--16": isMobile && products?.length > minElements,
          })}
          isMobile={isMobile}
          title={module?.name}
          params={{ ...params }}
          isGroup={isGroup}
          showTitle={module.showTitle}
          items={itemsRender
            ?.filter((x) => x.visible)
            .map((product, i) => (
              <ProductCard
                talent={talent}
                module={module}
                isLongCard={products?.length <= minElements}
                key={i}
                product={product}
                isMobile={isMobile}
                isPreview={isPreview}
                isGroup={isGroup}
              />
            ))}
        />
        {showDivider && !isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailProducts;
