import ImageSkeleton from "components/ImageSkeleton";
import { Paragraph } from "components/Typography";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { ReactComponent as PauseIcon } from "public/static/assets/icons/pause2.svg";
import { ReactComponent as PlayIconOpacity } from "public/static/assets/icons/play5.svg";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { PodcastItem as PodcastItemElement, PodcastItemLink, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import PodcastItem from "./PodcastItem";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Button = dynamic(() => import("antd/lib/button"));
const Modal = dynamic(() => import("components/Modal"));

interface IProps {
  module: TalentProfileModule;
  show: boolean;
  toggleModal(show: boolean): void;
  artists: string;
  name: string;
  image: string;
  links: PodcastItemLink[];
  isMobile: boolean;
  podcastItem: PodcastItemElement;
  isPreview?: boolean;
  talent?: Talent;
}

const PodcastModal: React.FC<IProps> = ({
  show,
  toggleModal,
  name,
  artists,
  image,
  links,
  module,
  isMobile,
  podcastItem,
  isPreview,
  talent,
}) => {
  const ref = useRef<any>();
  const router = useRouter();

  const user = useTypedSelector(selectUserData);

  const [isPlaying, setPlaying] = useState(false);

  const handleStop = useCallback(() => {
    ref.current.pause();
    setPlaying(false);
    ref.current.currentTime = 0;
  }, []);

  const handlePlayOrPause = useCallback(() => {
    if (ref.current.paused) {
      if ((window as any)?.analytics && !isPreview) {
        AnalyticServices.track(SEGMENT_EVENT.CLICK_PREVIEW_MUSIC, {
          "User id": user?.id,
          Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
          Location: Cookies.get(KOMI_USER_LOCATION),
          "Talent ID": talent?.id,
          "Module ID": module?.id,
          "Module Name": module?.name,
          "Element ID": podcastItem?.id,
          "Element Name": podcastItem?.metadata?.name,
          "Element URL": podcastItem?.metadata?.externalUrls?.spotify,
          Platform: isMobile ? "Responsive" : "Web",
          "Page ID": module?.localizationId || null,
          "Page Name": module?.localizationName || "Default",
          "Is Automated": "No",
        });
      }
      ref.current.play();
      setPlaying(true);
    } else {
      ref.current.pause();
      setPlaying(false);
    }
  }, [
    isPreview,
    user,
    talent?.id,
    module?.id,
    module?.name,
    module?.localizationId,
    module?.localizationName,
    podcastItem?.id,
    podcastItem?.metadata?.name,
    podcastItem?.metadata?.externalUrls?.spotify,
    isMobile,
  ]);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  return (
    <Modal
      centered
      closable={false}
      footer={false}
      className={`music-modal p__x--0 ${isDarken && "darken"}`}
      visible={show}
      onCancel={() => {
        if (!isPreview) {
          router.push("/", undefined, { shallow: true });
        }
        toggleModal(false);
      }}
    >
      <Row className={`music-modal__header ${isMobile ? "p--16 p__t--24 m__t--24" : "p--24 m__t--24"}`}>
        <Col className="music-modal__header__left">
          <ImageSkeleton borderRadius={16} src={image} width={isMobile ? 120 : 160} height={isMobile ? 120 : 160} />
          {podcastItem?.metadata?.previewUrl && (
            <Button type="text" className="ant-btn-md p--0" onClick={handlePlayOrPause}>
              {isPlaying ? <PauseIcon /> : <PlayIconOpacity />}
            </Button>
          )}
        </Col>
        <Col className={`music-card__right flex--1 ${isMobile ? "p__l--16" : "p__l--24"}`}>
          <Paragraph ellipsis={{ rows: 2 }} preset={isMobile ? "semibold16" : "semibold24"}>
            {name}
          </Paragraph>
          {artists && (
            <Paragraph
              ellipsis={{ rows: 1 }}
              className={`${isMobile ? "m__t--4" : "semibold24 m__t--8"} d--block opacity--05`}
              preset={isMobile ? "regular14" : "regular18"}
            >
              {artists}
            </Paragraph>
          )}
        </Col>
      </Row>
      <audio
        ref={ref}
        src={podcastItem?.metadata?.previewUrl}
        onEnded={() => handleStop()}
        controls
        className="d--none"
      />
      <div className="music-modal__content__wrapper">
        <div className={`music-modal__content p__x--${isMobile ? 16 : 24} p__t--8`}>
          {links
            ?.filter((link) => link?.url?.length)
            ?.map((link, i) => (
              <PodcastItem
                key={i}
                talent={talent}
                module={module}
                podcast={link}
                podcastItem={podcastItem}
                isMobile={isMobile}
              />
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default PodcastModal;
