import { createPreSaveMusicActions } from "redux/Talent/actions";
import { MusicItemType } from "redux/User/types";
import { store } from "redux/store";
import { PresaveResponse } from "redux/Talent/types";

export interface PresavePayload {
  moduleId: string;
  musicId: string;
  token: string;
  utcOffset: number;
  link: string;
  linkType: MusicItemType;
  country?: string;
}

export type PresaveSuccessCallback = (user: PresaveResponse) => void;
export type PresaveFailureCallback = () => Promise<void>;

export const dispatchPresave = (
  payload: PresavePayload,
  onSuccess?: PresaveSuccessCallback,
  onFailure?: PresaveFailureCallback,
): any => {
  return store.dispatch(
    createPreSaveMusicActions.REQUEST({
      payload,
      onSuccess,
      onFailure,
    }),
  );
};
