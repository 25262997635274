import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageProgessive from "components/ImageProgessive";
import ProductModal from "components/ProductModal";
import Swiper from "components/Swiper";
import { Paragraph, Text } from "components/Typography";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import min from "lodash/min";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { ShopifyProductItem, TalentProfileModule, TalentProfileModuleType, PricingMap, User } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import ShopifyBuy, { Client } from "shopify-buy";
import { AnalyticServices } from "utils/analytics";
import { formatCurrencyNonExchange } from "utils/currency";
import { getTalentName } from "utils/experience";
import { convertToUrl } from "utils/string";
import { ReactComponent as ProductDefaultIcon } from "public/static/assets/icons/product-default.svg";
import { LIGHT_THEME } from "constants/profile-theme";
import { selectActiveModule } from "redux/Talent/selector";
import { userService, shopifyService } from "services";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

interface ExperienceCardIProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  product: any;
  isLongCard?: boolean;
  isPreview?: boolean;
  shopifyClient: Client | null;
  talent?: Talent;
  isLoading?: boolean;
  productPrices?: any | null;
}

interface ISelf {
  productPricings?: PricingMap;
  productDisplay: ShopifyBuy.Product[];
  minElements: number;
  loading: boolean;
  client: ShopifyBuy.Client | null;
  params: any;
}

interface ShopifyCardISelf {
  showModal: boolean;
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  isSoldOut: boolean;
  productPrice?: string;
  click: VoidFunction;
  isDarken: boolean;
  intlPrice: any | null;
}

function normalised(url: string): string {
  return url.replace(/\/\//g, "/");
}

function toGid(id: string): string {
  return id && id.startsWith("gid://") ? id : Buffer.from(id, "base64").toString();
}

function toId(gid: string): string {
  return gid && gid.startsWith("gid://") ? Buffer.from(gid).toString("base64") : gid;
}

function isSameProduct(a: any, b: any): boolean {
  return toGid(a.id) === toGid(b.id);
}

const loadingImage: ShopifyBuy.Image = {
  id: 0,
  position: 0,
  product_id: "",
  src: "",
  created_at: "",
  updated_at: "",
  variant_ids: [],
};

const loadingProduct: ShopifyBuy.Product = {
  id: 0,
  description: "",
  images: [],
  options: [],
  selectedVariant: {
    id: 0,
    available: false,
    checkoutUrl: (qty) => "",
    compareAtPrice: "",
    formattedPrice: "",
    grams: 0,
    image: loadingImage,
    imageVariant: [],
    optionValues: [],
    price: "",
    productId: 0,
    productTitle: "",
    title: "",
  },
  selectedVariantImage: loadingImage,
  selections: [],
  title: "",
  variants: [],
  vendor: "",
};

const loadingProducts: ShopifyBuy.Product[] = [loadingProduct, loadingProduct, loadingProduct];

const loadingCollection: ShopifyBuy.Product[] = [loadingProduct, loadingProduct, loadingProduct, loadingProduct];

const ProductCard = (props: ExperienceCardIProps) => {
  const { product, isMobile, isPreview, className, isLongCard, module, shopifyClient, talent, isLoading } = props;

  const { showModal, toggleModal, isSoldOut, productPrice, click, isDarken, intlPrice } = useProductCardSelf(props);

  return (
    <>
      <div
        className={`talent-detail__experience-card product-card cursor-pointer ${isLongCard && "long full-width"} ${
          isSoldOut && "disabled"
        } ${className}`}
        onClick={click}
      >
        {isLongCard ? (
          <Row className={`link-card ${className}`} align="middle">
            <Col>
              {product?.images?.[0]?.src ? (
                <ImageProgessive
                  className="bg--white"
                  ratio={0.25}
                  threshold={[0.25, 1]}
                  src={product?.images?.[0]?.src as string}
                  width={isMobile ? 115 : 140}
                  height={isMobile ? 115 : 140}
                />
              ) : (
                <div
                  className={classNames("default-image long", {
                    darken: isDarken,
                  })}
                >
                  <ProductDefaultIcon />
                </div>
              )}
            </Col>
            <Col className={`flex--1 ${isMobile ? "p__x--16" : "p__x--24"}`}>
              {isLoading ? (
                <>
                  <div className="line1" />
                  <div className="line2" />
                </>
              ) : (
                <>
                  <Paragraph preset={isMobile ? "semibold14" : "semibold20"} ellipsis={{ rows: 2 }}>
                    {product?.title}
                  </Paragraph>
                  <Text preset={isMobile ? "regular12" : "regular18"} className="m__t--4 opacity--08">
                    {productPrice}
                  </Text>
                </>
              )}
            </Col>
          </Row>
        ) : (
          <>
            {product?.images?.[0]?.src ? (
              <ImageProgessive
                className="bg--white"
                ratio={0.25}
                threshold={[0.25, 1]}
                src={product?.images?.[0]?.src as string}
                width={isMobile ? 115 : 140}
                height={isMobile ? 115 : 140}
                borderRadius={8}
              />
            ) : (
              <div
                className={classNames("default-image", {
                  darken: isDarken,
                })}
              >
                <ProductDefaultIcon />
              </div>
            )}

            <Paragraph
              className="m__t--16"
              preset={isMobile ? "semibold14" : "semibold16"}
              ellipsis={{ rows: isMobile ? 2 : 1 }}
            >
              {product?.title}
            </Paragraph>
            <Text preset={isMobile ? "regular12" : "regular14"} className="m__t--4 opacity--08">
              {productPrice}
            </Text>
          </>
        )}
      </div>
      {showModal && (
        <ProductModal
          shopifyClient={shopifyClient}
          show={showModal}
          toggleModal={toggleModal}
          isMobile={isMobile}
          product={product}
          talent={talent}
          isPreview={isPreview}
          module={module}
          pricings={intlPrice}
        />
      )}
    </>
  );
};

const TalentDetailProductShopify: React.FC<IProps> = (props) => {
  const { className = "", isMobile, isPreview, module, talent, isGroup } = props;

  const { productPricings, productDisplay, minElements, loading, client, params } = useSelf(props);

  // forces the card to be long when appropriate
  const isLongCard = productDisplay?.length < 4;

  return productDisplay?.length > 0 ? (
    <div id={module.id} className={`${className}`}>
      <div>
        <Swiper
          key={productDisplay?.length}
          showShadow={!isMobile}
          className={classNames({
            "p__x--16": isMobile && productDisplay?.length <= minElements,
            "p__l--16": isMobile && productDisplay?.length > minElements,
          })}
          isMobile={isMobile}
          title={module?.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          items={productDisplay?.map((product, i) => (
            <ProductCard
              module={module}
              isLoading={loading}
              isLongCard={isLongCard}
              key={i}
              product={product}
              isMobile={isMobile}
              isPreview={isPreview}
              shopifyClient={client}
              talent={talent}
              productPrices={productPricings?.[product.id]}
            />
          ))}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailProductShopify;

function useProductCardSelf({
  module,
  talent,
  product,
  isMobile,
  isPreview,
  isLoading,
  productPrices,
}: ExperienceCardIProps): ShopifyCardISelf {
  const router = useRouter();
  const user = useTypedSelector(selectUserData);
  const [showModal, toggleModal] = useState(false);
  const isSoldOut = !product?.availableForSale;
  const intlPrice = productPrices;

  const productPrice = useMemo(() => {
    if (isLoading) return "";
    if (isSoldOut) return "Sold Out";
    if (product?.variants) {
      if (intlPrice && isSameProduct(intlPrice, product)) {
        const testPrice = parseFloat(intlPrice.amount) as number;
        return formatCurrencyNonExchange(intlPrice.currencyCode, 2).format(testPrice);
      }
      const minPrice = min(product?.variants?.map((variant: any) => parseFloat(variant.priceV2.amount))) as number;
      return formatCurrencyNonExchange(product?.variants?.[0]?.priceV2.currencyCode, 2).format(minPrice);
    }
  }, [isSoldOut, product?.variants, productPrices]);

  useEffect(() => {
    if (!isPreview && process.browser && module) {
      if (
        (module?.type === TalentProfileModuleType.SHOPIFY_PRODUCT &&
          normalised(`/products/${toId(product?.id)}?moduleId=${module?.id}`) === router?.asPath) ||
        (module?.type === TalentProfileModuleType.SHOPIFY_COLLECTION &&
          normalised(`/products/${toId(product?.id)}?collection=1&moduleId=${module?.id}`) === router?.asPath)
      ) {
        toggleModal(true);
      }
    }
  }, [isPreview, module, product?.id, router]);

  const click = React.useCallback(() => {
    if (isSoldOut) return;
    if ((window as any)?.analytics && !isPreview) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_SHOPIFY_PRODUCT_ELEMENT, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Talent Name": getTalentName(talent, true),
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": product?.id,
        "Element Name": product?.title,
        Platform: isMobile ? "Responsive" : "Web",
        Currency: intlPrice ? intlPrice.currencyCode : product?.variants?.[0]?.priceV2?.currencyCode,
        Price: intlPrice ? intlPrice.amount : productPrice,
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      });
    }

    isPreview
      ? toggleModal(true)
      : router.push(
          `/products/${toId(product.id)}${
            module?.type === TalentProfileModuleType.SHOPIFY_COLLECTION
              ? `?collection=1&moduleId=${module?.id}`
              : `?moduleId=${module.id}`
          }`,
          undefined,
          { shallow: true },
        );
  }, [
    isMobile,
    isPreview,
    isSoldOut,
    module.id,
    module?.localizationId,
    module?.localizationName,
    module?.name,
    module?.type,
    product.id,
    product?.title,
    product?.variants,
    productPrice,
    router,
    talent,
    user,
  ]);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  return {
    showModal,
    toggleModal,
    isSoldOut,
    productPrice,
    click,
    isDarken,
    intlPrice,
  };
}

function useSelf({ isMobile, module, isPreview, talent }: IProps): ISelf {
  const activeModule = useTypedSelector(selectActiveModule);

  const client = useMemo(() => {
    const domain = (module?.items as ShopifyProductItem[])?.[0]?.shop;

    if (domain) {
      const storefrontAccessToken = talent?.talentProfile?.shopifyMerchants?.[domain];
      const accessToken =
        typeof storefrontAccessToken === "string" ? storefrontAccessToken : storefrontAccessToken?.accessToken;

      if (!accessToken) return null;
      return ShopifyBuy.buildClient({
        domain,
        storefrontAccessToken: accessToken as string,
      });
    }
    return null;
  }, [module?.items, talent?.talentProfile?.shopifyMerchants]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isCollection, setIsCollection] = useState<boolean>(false);
  const [productsData, setProductsData] = useState<ShopifyBuy.Product[]>([]);

  const [productPricings, setProductPricing] = useState<PricingMap>();

  const minElements = 3; //useMemo(() => (isMobile ? 2 : 3), [isMobile]);
  const productDisplay = loading ? (isCollection ? loadingCollection : loadingProducts) : productsData;

  const params: any = useMemo(
    () => ({
      slidesPerView: productDisplay?.length <= minElements ? 1 : isMobile ? "auto" : 3.725,
      slidesPerGroup: isMobile ? 1 : 3,
      slidesPerColumn: productDisplay?.length <= minElements ? minElements : 1,
      spaceBetween: productDisplay?.length <= minElements ? 16 : isMobile ? 16 : 24,
      navigation: !isMobile,
      noSwiping: isMobile,
      slidesOffsetAfter: 16,
    }),
    [isMobile, minElements, productDisplay, productDisplay?.length],
  );

  const getInternationalPrice = async function (
    gid: string,
    countryCode: string,
    shop: string,
    storefrontToken: string,
    itemId: string,
  ) {
    const productsList = await shopifyService.getProductList(storefrontToken, shop, countryCode, gid);

    if (productsList.data && productsList.data.data) {
      const product = { gid: "", id: "", amount: "", currencyCode: "" };
      product.id = itemId;
      product.gid = productsList.data.data.product?.id;
      product.amount = productsList.data.data.product?.variants?.nodes[0].priceV2.amount;
      product.currencyCode = productsList.data.data.product?.variants?.nodes[0].priceV2.currencyCode;
      return product;
    }
  };

  const fetchProductPrices = async function (client: any, itemIds: any) {
    setLoading(true);
    const product = client?.product;
    const config = client?.config;

    if (!product) return;
    let countryCode = "";
    let items: ShopifyBuy.Product[] = [];

    try {
      [countryCode, items] = await Promise.all([userService.getCountryCode(), product.fetchMultiple(itemIds)]);
    } catch (error) {
      console.log(error);
    }

    if (countryCode) {
      const productPrice: PricingMap = {};
      const internationalPrices = await Promise.all(
        itemIds.map((id: any) =>
          getInternationalPrice(toGid(id), countryCode, config?.domain, config?.storefrontAccessToken, toId(id)),
        ),
      );
      internationalPrices.forEach((product) => {
        productPrice[toGid(product.id)] = product;
      });
      setProductPricing(productPrice);
      setProductsData(items?.filter((item: any) => !!item));
    } else {
      setProductsData(items?.filter((item: any) => !!item));
    }
    setLoading(false);
  };

  const fetchCollectionPrices = async function (client: any, collectionId: any) {
    const collection = client?.collection;

    if (!collection) return;
    const collectionData = await collection.fetchWithProducts(collectionId);
    const productIDs = collectionData.products.map((product: any) => {
      return toGid(product.id);
    });
    fetchProductPrices(client, productIDs);
  };

  useEffect(() => {
    const itemIds = (module?.items as ShopifyProductItem[])?.[0]?.itemIds;
    const collectionId = (module?.items as ShopifyProductItem[])?.[0]?.collectionId;

    if (itemIds?.length && module.type === TalentProfileModuleType.SHOPIFY_PRODUCT) {
      const asCollection = itemIds.length > 3;

      setIsCollection(asCollection);

      fetchProductPrices(client, itemIds);
    } else if (collectionId && module.type === TalentProfileModuleType.SHOPIFY_COLLECTION) {
      setIsCollection(true);
      fetchCollectionPrices(client, collectionId);
    }
  }, [module?.items, module.type, client]);

  useEffect(() => {
    if (productDisplay?.length && activeModule === module?.id && isPreview) {
      const el = document.getElementById(module.id);
      el?.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeModule, isPreview, productDisplay, module?.name, module?.order, module?.id]);

  return {
    productPricings,
    productDisplay,
    minElements,
    loading,
    client,
    params,
  };
}
