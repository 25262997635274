import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import Swiper from "components/Swiper";
import { Paragraph, Text } from "components/Typography";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { SeatedItem, TalentProfileModule } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import SVG from "react-inlinesvg";
import moment from "moment";
import Button from "antd/lib/button";
import { LIGHT_THEME } from "constants/profile-theme";
import { useWindowSize } from "hooks";
import { selectActiveModule } from "redux/Talent/selector";

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
  isHorizontal?: boolean;
}

interface ISeatedCardProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  item: SeatedItem;
  isBig: boolean;
  isPreview?: boolean;
  talent?: Talent;
}

const SeatedCard = ({ item, isMobile, className = "", isBig, isPreview, module, talent }: ISeatedCardProps) => {
  const user = useTypedSelector(selectUserData);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  const ticketsOffer = item.attributes["primary-link-url"];

  const specialTickets = item.attributes["promoted-button-text"] ? item.attributes["promoted-link-url"] : null;
  const specialLabel = useMemo(() => {
    const label = item.attributes["promoted-button-text"] || "";
    if (!isBig) {
      return label.length <= 7 ? label : `${label.substring(0, 7)}...`;
    }
    return label;
  }, [isBig, item.attributes]);
  const handleOpenLink = useCallback(
    (segmentEvent: SEGMENT_EVENT | null, url: string) => {
      if ((window as any)?.analytics && !isPreview && segmentEvent) {
        AnalyticServices.track(segmentEvent, {
          "User id": user?.id,
          Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
          Location: Cookies.get(KOMI_USER_LOCATION),
          "Talent ID": talent?.id,
          "Talent Name": `${talent?.talentProfile?.firstName} ${talent?.talentProfile?.lastName}`,
          "Module ID": module?.id,
          "Module Name": module?.name,
          "Element ID": item?.id,
          "Element Name": item?.attributes["venue-name"],
          Platform: isMobile ? "Responsive" : "Web",
          "Page ID": module?.localizationId || null,
          "Page Name": module?.localizationName || "Default",
        });
      }
      window.open(url, "_blank");
    },
    [
      isPreview,
      user,
      talent?.id,
      talent?.talentProfile?.firstName,
      talent?.talentProfile?.lastName,
      module?.id,
      module?.name,
      module?.localizationId,
      module?.localizationName,
      item?.id,
      item?.attributes,
      isMobile,
    ],
  );
  const presetTitle = useMemo(() => {
    if (isBig) {
      return isMobile ? "semibold14" : "semibold20";
    }
    return isMobile ? "semibold14" : "semibold16";
  }, [isMobile, isBig]);
  const presetSubTitle = useMemo(() => {
    if (isBig) {
      return isMobile ? "regular14" : "regular18";
    }
    return isMobile ? "regular12" : "regular14";
  }, [isMobile, isBig]);

  return (
    <Row
      className={`link-card link-card--bandsintown cursor-pointer ${className}`}
      align="middle"
    // onClick={() => handleOpenLink(null, link?.url)}
    >
      <Col>
        <div className="link-card__date">
          <SVG
            stroke={isDarken ? "#121212" : "#ffffff"}
            strokeOpacity={0.1}
            src="/static/assets/icons/bandsintown.svg"
          />
          <div>
            <Text preset={isMobile ? "medium12" : "medium14"} className="opacity--05">
              {moment(item.attributes["starts-at-date-local"])?.format("MMM")}
            </Text>
            <Text preset={isMobile ? "bold32" : "bold40"}>
              {moment(item.attributes["starts-at-date-local"])?.date()}
            </Text>
            <Text preset={isMobile ? "medium12" : "medium14"} className="opacity--05">
              {moment(item.attributes["starts-at-date-local"])?.year()}
            </Text>
          </div>
        </div>
      </Col>
      <Col className={`flex--1 ${isMobile ? (isBig ? "p__l--16" : "p__x--12") : "p__l--24"}`}>
        <Paragraph ellipsis={{ rows: 1 }} className="m__b--0" preset={presetTitle}>
          {item.attributes["venue-name"]}
        </Paragraph>
        <Paragraph
          ellipsis={{ rows: 1 }}
          className={`m__b--0 m__t--${isMobile ? 0 : 4} opacity--05`}
          preset={presetSubTitle}
        >
          {item.attributes["venue-formatted-address"]}
        </Paragraph>
        {item.attributes["is-sold-out"] ? (
          <Text
            preset={isMobile ? "semibold12" : "medium18"}
            className={`d--block opacity--08 ${isMobile ? "m__t--12" : "m__t--16"}`}
          >
            Sold Out
          </Text>
        ) : (
          <Row wrap={false} gutter={isMobile ? 12 : 16} className={`m__t--${isMobile ? 12 : 16}`}>
            {ticketsOffer && (
              <Col>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenLink(SEGMENT_EVENT.CLICK_THE_SEATED_TICKET_LINK, ticketsOffer);
                  }}
                >
                  <Text preset={isMobile ? "medium12" : "semibold16"}>Tickets</Text>
                </Button>
              </Col>
            )}
            {specialTickets && (
              <Col>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenLink(SEGMENT_EVENT.CLICK_THE_SEATED_PROMOTED_LINK, specialTickets);
                  }}
                >
                  <Text ellipsis={true} preset={isMobile ? "medium12" : "semibold16"}>
                    {specialLabel}
                  </Text>
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Col>
    </Row>
  );
};

const TalentDetailSeated: React.FC<IProps> = ({ className = "", isMobile, isPreview, module, talent, isGroup }) => {
  const [items, setItems] = useState([]);
  const [itemsRender, setItemsRender] = useState<SeatedItem[]>([]);
  const { width } = useWindowSize();

  const activeModule = useTypedSelector(selectActiveModule);

  const params: any = useMemo(
    () => ({
      slidesPerView: items?.length <= 3 ? 1 : isMobile ? ((width || 0) < 350 ? 1.0592 : 1.2508) : 1.5049,
      slidesPerColumn: items?.length <= 3 ? items?.length : 2,
      spaceBetween: items?.length <= 3 ? 16 : isMobile ? 0 : 24,
      freeMode: isMobile,
      navigation: !isMobile,
      centeredSlides: !isMobile,
      centeredSlidesBounds: !isMobile,
      slidesOffsetAfter: isMobile ? 16 : 0,
      noSwiping: true,
    }),
    [isMobile, items?.length, width],
  );

  useEffect(() => {
    setItemsRender(items?.length ? [...items] : []);
  }, [items]);

  useEffect(() => {
    (async () => {
      try {
        setItems((module?.items as any) || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [module?.items]);

  useEffect(() => {
    if (items?.length && activeModule === module?.id && isPreview) {
      document.getElementById(module.id)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeModule, isPreview, items?.length, module?.name, module?.id, module?.order]);

  return items?.length > 0 ? (
    <div id={module.id} className={`talent-detail__links ${className}`}>
      <div>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "multiple-row": items?.length > 3,
            "p__x--16": isMobile && items?.length <= 3,
            "p__l--16": isMobile && items?.length > 3,
            "fix-width": items?.length <= 3,
          })}
          isMobile={isMobile}
          title={module.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          items={itemsRender?.map((item, i) => (
            <SeatedCard
              talent={talent}
              module={module}
              isMobile={isMobile}
              isPreview={isPreview}
              key={i}
              item={item}
              isBig={items?.length <= 3}
              className={isMobile && items?.length > 3 ? "m__r--16" : ""}
            />
          ))}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailSeated;
