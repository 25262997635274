import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import classNames from "classnames";
import ImageProgessive from "components/ImageProgessive";
import MusicModal from "components/MusicModal";
import MusicItemComponent from "components/MusicModal/MusicItem";
import PreReleaseModal from "components/PreReleaseModal";
import PreReleaseMusicItem from "components/PreReleaseModal/PreReleaseMusicItem";
import Swiper from "components/Swiper";
import { Paragraph, Text } from "components/Typography";
import { MUSIC_LOGOS } from "constants/music";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import moment from "moment";
import ct from "countries-and-timezones";
import { NextRouter, useRouter } from "next/router";
import { ReactComponent as PauseIcon } from "public/static/assets/icons/pause2.svg";
import { ReactComponent as PlayIcon } from "public/static/assets/icons/play2.svg";
import { ReactComponent as PlayIconOpacity } from "public/static/assets/icons/play5.svg";
import React, { Dispatch, Ref, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { PreSaveMarketingPermission, PresaveResponse, Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import {
  MusicItem,
  MusicItemLink,
  MusicItemType,
  MusicItemTypes,
  ReleaseScope,
  ReleaseType,
  TalentProfileModule,
} from "redux/User/types";
import { dispatchPresave } from "services/PresaveService";
import { KOMI_APPLE_MUSIC_TOKEN, MARKETING_PERMISSIONS } from "constants/auth";
import { KOMI_SPOTIFY_REFRESH_TOKEN } from "services/SpotifyService";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import { hexToRgbA } from "utils/color";
import { trackEvent } from "utils/trackEvent";

import PreReleaseMarketingModal from "components/PreReleaseMarketingModal";
const MINIMUM_ELEMENTS_SLIDER = 3;

interface IProps {
  isMobile: boolean;
  className?: string;
  module: TalentProfileModule;
  isPreview?: boolean;
  talent?: Talent;
  isGroup?: boolean;
}

//interface for head logic in music card item
interface IMusicCardProps {
  module: TalentProfileModule;
  isMobile: boolean;
  className?: string;
  music: MusicItem;
  activeMusic: MusicItem | null;
  setActiveMusic(music: MusicItem | null): void;
  isBig?: boolean;
  isPreview?: boolean;
  isSingleElement?: boolean;
  talent?: Talent;
  index?: number;
}

export const slugify = (text: string) =>
  String(text)
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

const getSpotifyRedirectedRefreshToken = (): string | null => {
  const url = new URL(window.location.href);
  const { searchParams } = url;

  const refreshToken = searchParams.get("refresh_token") || "";

  Cookies.set(KOMI_SPOTIFY_REFRESH_TOKEN, refreshToken, { expires: 180 });

  return refreshToken;
};

const getAppleMusicRedirectedRefreshToken = (): string | null => {
  const hash = window.location.hash;

  try {
    // Taken from MusicKit.js
    const tokenRegex = /^#([a-zA-Z0-9+/]{200,}={0,2})$/;
    const decodedToken = hash.replace(tokenRegex, "$1");
    const tokenData = JSON.parse(atob(decodedToken));
    const { musicUserToken } = tokenData;

    Cookies.set(KOMI_APPLE_MUSIC_TOKEN, musicUserToken, { expires: 180 });

    return musicUserToken;
  } catch (error) {
    console.error("Failed to parse Apple Music user token. Details:", error);
    return "";
  }
};

const getRedirectedAccessToken = (linkType: MusicItemType): string | null => {
  switch (linkType) {
    case MusicItemType.SPOTIFY:
      return getSpotifyRedirectedRefreshToken();
    case MusicItemType.APPLE_MUSIC:
      return getAppleMusicRedirectedRefreshToken();
    default:
      throw new Error(`Unhandled link type: ${linkType}`);
  }
};

const userTz = moment.tz.guess();
const userUtcOffset = moment.tz(userTz).utcOffset();
const country = ct.getTimezone(userTz)?.countries;

//head logic for music cards
interface IMusicCardSelf {
  isPreRelease: boolean;
  image: string;
  isPlaying: boolean;
  publisher: string;
  owner: string;
  artists: string;
  links: MusicItemLink[];
  backgroundColor: string;
  handlePlayMusicProvider(link: any): void;
  handlePlayOrPause(): void;
  handlePlayFullSong(): void;
  currentPreSaveType: MusicItemType.SPOTIFY | MusicItemType.APPLE_MUSIC | null;
  setCurrentPreSaveType: Dispatch<SetStateAction<MusicItemType.SPOTIFY | MusicItemType.APPLE_MUSIC | null>>;
  handleStop(): void;
  showModal: boolean;
  showMarketingModal: boolean;
  toggleMarketingModal: Dispatch<SetStateAction<boolean>>;
  userMarketingProps: PreSaveMarketingPermission | null;
  setUserMarketingProps: Dispatch<SetStateAction<PreSaveMarketingPermission | null>>;
  saveMarketingPermissions: (userID: string, platform: string, email?: string) => void;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  isDarken: boolean;
  ref: Ref<any>;
  mapAnalytics(segmentEvent: SEGMENT_EVENT, link?: any): void;
  slugify(text: string): string;
}

interface MusicCardSelfProps extends IMusicCardProps {
  user?: any;
  router?: NextRouter;
}

export function useMusicCardSelf({
  music,
  setActiveMusic,
  activeMusic,
  isPreview,
  user,
  talent,
  isMobile,
  module,
  router,
}: MusicCardSelfProps): IMusicCardSelf {
  const ref = useRef<HTMLMediaElement>();
  const [isPlaying, setPlaying] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [showMarketingModal, toggleMarketingModal] = useState(false);
  const [currentPreSaveType, setCurrentPreSaveType] = useState<
    MusicItemType.SPOTIFY | MusicItemType.APPLE_MUSIC | null
  >(null);

  //state for marketing permissions
  const [userMarketingProps, setUserMarketingProps] = useState<PreSaveMarketingPermission | null>(null);

  const artists = useMemo(
    () => music?.metadata?.artists?.map((artist: any) => artist?.name)?.join(", "),
    [music?.metadata?.artists],
  );

  /* Function that sets up the save for marketing permissions */
  const saveMarketingPermissions = useCallback((userID, platform, email?: string) => {
    /* if the user was captured, set the marketing permissions */
    if (userID) {
      /* set the correct user id, retireved from where the presave is occuring (modal, single) */
      const marketingPermissions: PreSaveMarketingPermission = {
        userId: userID,
        talentProfileId: talent?.talentProfile?.id,
        agreeToMarketing: null,
        platform: platform,
      };

      if (email) marketingPermissions.email = email;

      /* Set/Check for the marketing permissions cookie */
      const cookieName =
        platform === MusicItemType.SPOTIFY ? MARKETING_PERMISSIONS.SPOTIFY : MARKETING_PERMISSIONS.APPLE;
      const cookieValue = Cookies.get(cookieName);

      /* If the cookie doesn't exist save a new one, set the permissions and open the modal */
      if (!cookieValue) {
        setUserMarketingProps(marketingPermissions);
        Cookies.set(cookieName, JSON.stringify(marketingPermissions), { expires: 180 });
      }
    }
  }, []);

  /* When the marketing props are set from the presave, trigger the marketing permissions modal */
  useEffect(() => {
    //ensure the props are set and the marketing permissions haven't already been submitted
    if (userMarketingProps !== null && userMarketingProps.agreeToMarketing === null) {
      toggleMarketingModal(true);
    }
  }, [userMarketingProps]);

  //map segment event based on event type
  const mapAnalytics = useCallback(
    (segmentEvent: SEGMENT_EVENT, link?: any) => {
      const eventFields: any = {};

      switch (segmentEvent) {
        case SEGMENT_EVENT.CLICK_PREVIEW_MUSIC:
          eventFields["Element URL"] = music?.metadata?.externalUrls?.spotify;
          break;

        case SEGMENT_EVENT.CLICK_PLAY_FULL_SONG:
          break;

        case SEGMENT_EVENT.CLICK_MUSIC_PROVIDER:
          eventFields["Destination"] = link?.type;
          eventFields["Smart Link URL"] = link?.url;
          break;
      }

      return {
        ...eventFields,
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": music?.id,
        "Element Name": music?.metadata?.name,
        Platform: isMobile ? "Responsive" : "Web",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
      };
    },
    [
      isMobile,
      module?.id,
      module?.localizationId,
      module?.localizationName,
      module?.name,
      music?.id,
      music?.metadata?.externalUrls?.spotify,
      music?.metadata?.name,
      talent?.id,
      user,
    ],
  );

  const publisher = useMemo(() => music?.metadata?.publisher, [music]);

  const owner = useMemo(() => music?.metadata?.owner?.display_name, [music]);

  const image = useMemo(() => music?.metadata?.images?.[0]?.url, [music?.metadata?.images]);

  const isPreRelease = useMemo(
    () => [MusicItemTypes.PRE_SAVE]?.includes(music?.type) || [MusicItemTypes.CUSTOM_PRE_SAVE]?.includes(music?.type),
    [music?.type],
  );

  const links = useMemo(() => music?.links?.filter((link) => link?.url?.length).slice(0, 3), [music?.links]);

  const contextualTrackEvent = useCallback(
    (eventType: SEGMENT_EVENT, link: MusicItemLink) => {
      trackEvent(eventType, isMobile, user, module, music, link, talent);
    },
    [isMobile, module, music, talent, user],
  );

  const handlePlayOrPause = useCallback(() => {
    setActiveMusic(music);
    if (ref.current?.paused) {
      if ((window as any)?.analytics && !isPreview) {
        const segmentEvent = mapAnalytics(SEGMENT_EVENT.CLICK_PREVIEW_MUSIC);
        AnalyticServices.track(SEGMENT_EVENT.CLICK_PREVIEW_MUSIC, segmentEvent);
      }
      ref.current.play();
      setPlaying(true);
    } else {
      ref.current?.pause();
      setPlaying(false);
    }
  }, [setActiveMusic, music, isPreview, mapAnalytics]);

  const handleStop = useCallback(() => {
    ref?.current?.pause();
    setPlaying(false);

    if (ref.current) ref.current.currentTime = 0;
  }, []);

  const handlePlayFullSong = useCallback(() => {
    if ((window as any)?.analytics && !isPreview) {
      if (!isPreRelease) {
        const segmentEvent = mapAnalytics(SEGMENT_EVENT.CLICK_PLAY_FULL_SONG);
        AnalyticServices.track(SEGMENT_EVENT.CLICK_PLAY_FULL_SONG, segmentEvent);
      }
    }
    setActiveMusic(null);
    handleStop();
    toggleModal(true);

    const slug = music?.urlSlug || slugify(music?.metadata?.name);

    if (!isPreview) router?.push(`/music/${slug}`, undefined, { shallow: true });

    return slug;
  }, [
    isPreview,
    setActiveMusic,
    handleStop,
    music?.urlSlug,
    music?.metadata?.name,
    router,
    isPreRelease,
    mapAnalytics,
  ]);

  useEffect(() => {
    // Next may run this for SSR, so check for browser
    if (!process.browser) {
      return;
    }

    if (
      `/music/${music?.urlSlug}` === router?.asPath ||
      `/music/${slugify(music?.metadata?.name)}` === router?.asPath
    ) {
      toggleModal(true);
    }

    const presavedUrl = localStorage.getItem("presavedUrl");
    const isSingleElement = localStorage.getItem("isPresaveSingleElement") === "true";

    if (!presavedUrl) {
      return;
    }

    const url = new URL(window.location.href);
    const { pathname } = url;

    const filenameSlashIndex = pathname.lastIndexOf("/");
    const musicItemSlug = pathname.substring(filenameSlashIndex + 1);

    if (isPreview || !module) {
      return;
    }

    const link = music.links.find((link) => link.url === presavedUrl);
    if (!link) {
      return;
    }

    const accessToken = getRedirectedAccessToken(link.type);
    if (!accessToken) {
      return;
    }

    dispatchPresave(
      {
        moduleId: module?.id,
        musicId: music.id,
        utcOffset: userUtcOffset,
        token: accessToken,
        link: link.url,
        linkType: link.type,
        country: country ? country[0] : "",
      },
      (userPreSave: PresaveResponse) => {
        /* Trigger the marketing permission modal logic after presave is complete and returns the user ID */
        saveMarketingPermissions(userPreSave.response.userID, link.type, userPreSave.response?.email);
        return contextualTrackEvent(SEGMENT_EVENT.SUCCESSFULL_PRE_SAVE, link);
      },
    );

    localStorage.removeItem("presavedUrl");
    localStorage.removeItem("isPresaveSingleElement");

    if (isSingleElement && musicItemSlug) {
      router?.push("/");
    } else {
      router?.push(`/music/${musicItemSlug}`, undefined, { shallow: true });
    }
  }, [isPreview, module, music.id, music?.urlSlug, music?.metadata?.name, router, music.links, contextualTrackEvent]);

  const backgroundColor = useMemo(
    () => talent?.talentProfile?.themeColor?.backgroundColor || "#121212",
    [talent?.talentProfile?.themeColor?.backgroundColor],
  );

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  const handlePlayMusicProvider = useCallback(
    (link: any) => {
      if ((window as any)?.analytics) {
        const segmentEvent = mapAnalytics(SEGMENT_EVENT.CLICK_MUSIC_PROVIDER, link);
        AnalyticServices.track(SEGMENT_EVENT.CLICK_MUSIC_PROVIDER, segmentEvent);
      }
      window.open(link?.url, "_blank");
    },
    [mapAnalytics],
  );

  useEffect(() => {
    if (!isPreview && process.browser && module && router?.query?.id === music?.id) {
      setTimeout(() => {
        const moduleEl = document.querySelector(`#${module.id}`);
        if (moduleEl) {
          moduleEl.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
      handlePlayFullSong();
    }
  }, [handlePlayFullSong, isPreview, module, music?.id, router?.query?.id]);

  useEffect(() => {
    if (activeMusic?.id !== music?.id) {
      handleStop();
    }
  }, [activeMusic?.id, handleStop, music?.id]);

  return {
    isDarken,
    isPreRelease,
    image,
    isPlaying,
    publisher,
    owner,
    artists,
    links,
    backgroundColor,
    handlePlayMusicProvider,
    handlePlayOrPause,
    handlePlayFullSong,
    currentPreSaveType,
    setCurrentPreSaveType,
    handleStop,
    showModal,
    toggleModal,
    showMarketingModal,
    toggleMarketingModal,
    userMarketingProps,
    setUserMarketingProps,
    saveMarketingPermissions,
    ref,
    mapAnalytics,
    slugify,
  };
}

const MusicCard = ({
  music,
  isMobile,
  isPreview,
  className = "",
  setActiveMusic,
  activeMusic,
  isBig,
  module,
  isSingleElement,
  talent,
  index,
}: IMusicCardProps) => {
  const router = useRouter();
  const user = useTypedSelector(selectUserData);

  // For conforming to ISO compliancy for Safari
  const isoCompliantDate = new Date(music?.releaseDate || "");
  let releaseDate = moment(isoCompliantDate);

  // For offsetting the apparent added timezone for the user by Date
  // Custom links should show the same date they were set in everywhere
  if (music.releaseScope === ReleaseScope.LOCAL || music.releaseType === ReleaseType.CUSTOM) {
    const timezoneOffset = releaseDate.utcOffset();
    releaseDate = releaseDate.subtract(timezoneOffset, "minutes");
  }

  const {
    isDarken,
    isPreRelease,
    image,
    isPlaying,
    publisher,
    owner,
    artists,
    links,
    backgroundColor,
    handlePlayMusicProvider,
    handlePlayOrPause,
    handlePlayFullSong,
    currentPreSaveType,
    setCurrentPreSaveType,
    handleStop,
    showModal,
    showMarketingModal,
    toggleMarketingModal,
    userMarketingProps,
    setUserMarketingProps,
    saveMarketingPermissions,
    toggleModal,
    ref,
  } = useMusicCardSelf({
    music,
    setActiveMusic,
    activeMusic,
    isPreview,
    user,
    talent,
    isMobile,
    module,
    router,
  });

  return (
    <>
      {isSingleElement ? (
        <div
          role="region"
          className={`link-card music-card single ${isDarken && "darken"} ${isPreRelease && "pre-release"} ${isMobile
              ? `p__x--24 p__b--${isPreRelease && music?.releaseType === "CUSTOM" ? "16" : "24"} p__t--14 m__t--24`
              : "p--24 m__t--32"
            } overflow--visible ${isBig && "big"} ${className}`}
        >
          <Row>
            <Col className="music-card__left">
              <ImageProgessive
                ratio={0.25}
                threshold={[0.25, 1]}
                borderRadius={16}
                src={image}
                width={isMobile ? 120 : 160}
                height={isMobile ? 120 : 160}
              />
              {music?.metadata?.previewUrl && (
                <Button
                  aria-label={`preview-button-${index}`}
                  type="text"
                  className="ant-btn-md p--0"
                  onClick={handlePlayOrPause}
                >
                  {isPlaying ? <PauseIcon /> : <PlayIconOpacity />}
                </Button>
              )}
            </Col>
            <Col className={`music-card__right ${isMobile ? "p__l--16" : "p__l--24"}`}>
              <Paragraph ellipsis={{ rows: 1 }} preset={isMobile ? "semibold18" : "semibold24"}>
                {music?.metadata?.name}
              </Paragraph>
              {(publisher || owner || artists) && (
                <Paragraph
                  ellipsis={{ rows: 1 }}
                  className={`${isMobile ? "m__t--4" : "m__t--8"} d--block opacity--05`}
                  preset={isMobile ? "regular14" : "regular18"}
                >
                  {publisher || owner || artists}
                </Paragraph>
              )}
            </Col>
          </Row>
          {isPreRelease &&
            music?.releaseDate &&
            (music?.releaseType === "CUSTOM" ? (
              <Text
                preset={isMobile ? "medium12" : "medium14"}
                className={`d--block ${isMobile
                    ? "m__t--16 line-height--16 letter__spacing--1"
                    : "m__t--24 line-height--22 letter__spacing--2"
                  } text__align--center  opacity--08`}
              >
                {`AVAILABLE ${releaseDate.format("MMMM Do, YYYY").toLocaleUpperCase()}`}
              </Text>
            ) : (
              <Text
                preset={isMobile ? "medium12" : "medium14"}
                className={`d--block ${isMobile
                    ? "m__t--16 line-height--16 letter__spacing--1"
                    : "m__t--24 line-height--22 letter__spacing--2"
                  } text__align--center  opacity--08`}
              >
                {`AVAILABLE ${releaseDate.format("Do MMMM [at] hh:mm A.").toLocaleUpperCase()}`}
              </Text>
            ))}

          {links?.length === 3 ? (
            <Row
              className={`music-card__provider m__t--24`}
              style={{ background: hexToRgbA(backgroundColor, 0.8) }}
              align="middle"
              justify="center"
            >
              {links?.map(
                (link: any, i: number) =>
                  i < 2 && (
                    <React.Fragment key={i}>
                      <Col className={"flex--1"} key={i}>
                        <a
                          onClick={() => handlePlayMusicProvider(link)}
                          className="d--flex justify__content--center"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SVG src={`${MUSIC_LOGOS[link.type]}-white.svg`} />
                        </a>
                      </Col>
                      {i + 1 < links?.length && (
                        <Col>
                          <div className={`music-card__provider__divider`} />
                        </Col>
                      )}
                    </React.Fragment>
                  ),
              )}

              <Col className="text__align--center flex--1">
                <Button
                  className={`p--0 ${isMobile ? "height--20" : "height--24"}`}
                  type="text"
                  onClick={handlePlayFullSong}
                >
                  <Text
                    className={isMobile ? "line-height--16" : "line-height--24"}
                    preset={isMobile ? "semibold12" : "semibold18"}
                  >
                    More
                  </Text>
                </Button>
              </Col>
            </Row>
          ) : (
            <div className={isPreRelease && music?.releaseType === "CUSTOM" && isMobile ? "m__t--16" : "m__t--24"}>
              {links?.map((link: any, i: number) => (
                <React.Fragment key={i}>
                  {isPreRelease ? (
                    <PreReleaseMusicItem
                      key={i}
                      talent={talent}
                      module={module} // TalentProfileModule
                      music={link} // MusicItemLink
                      musicItem={music} // MusicItemElement
                      isSingleElement
                      isMobile={isMobile}
                      isActive={link?.type === currentPreSaveType}
                      setCurrentPreSaveType={setCurrentPreSaveType}
                      isPreview={isPreview}
                      saveMarketingPermissions={saveMarketingPermissions}
                    />
                  ) : (
                    <MusicItemComponent
                      key={i}
                      talent={talent}
                      module={module}
                      music={link}
                      musicItem={music}
                      isMobile={isMobile}
                      isSingleElement
                    />
                  )}
                  {i + 1 < links?.length && <Divider className={`d--block m__y--${isMobile ? 20 : 24}`} />}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Row role="region" className={`link-card music-card ${isBig && "big"} ${className}`} align="middle">
          <Col className="music-card__left">
            <ImageProgessive
              ratio={0.25}
              threshold={[0.25, 1]}
              src={image}
              width={isMobile ? (isBig ? 115 : 96) : 140}
              height={isMobile ? (isBig ? 115 : 96) : 140}
            />
            {music?.metadata?.previewUrl && (
              <Button type="text" className="p--0" onClick={handlePlayOrPause} aria-label={`preview-button-${index}`}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </Button>
            )}
          </Col>
          <Col
            className={`music-card__right flex--1 ${isMobile ? (isBig ? "p__x--16" : "p__x--12") : isBig ? "p__x--24" : "p__x--16"
              }`}
          >
            <Paragraph
              ellipsis={{ rows: 1 }}
              preset={isMobile ? "semibold14" : isBig ? "semibold20" : "semibold16"}
              className={`${isMobile ? (isBig ? "line-height--22" : "line-height--20") : ""}`}
            >
              {music?.metadata?.name}
            </Paragraph>
            {(publisher || owner || artists) && (
              <Paragraph
                ellipsis={{ rows: 1 }}
                className={`${!isBig && !isMobile ? "" : "m__t--4"} music-card__singer opacity--05`}
                preset={isMobile ? (isBig ? "regular14" : "regular12") : isBig ? "regular18" : "regular14"}
              >
                {publisher || owner || artists}
              </Paragraph>
            )}
            <Button
              aria-label={`play-button-${index}`}
              className={`btn-play-full ${isMobile ? "m__t--12 p__x--8" : isBig ? "m__t--16 p__x--16" : "m__t--12 p__x--16"
                }`}
              onClick={handlePlayFullSong}
            >
              <Text preset={isMobile ? "semibold10" : "semibold16"}>{isPreRelease ? `Pre-save` : `Play`}</Text>
            </Button>
          </Col>
        </Row>
      )}
      <audio ref={ref} src={music?.metadata?.previewUrl} onEnded={() => handleStop()} controls className="d--none" />

      {isPreRelease ? (
        <PreReleaseModal
          talent={talent}
          musicItem={music}
          isMobile={isMobile}
          module={module}
          links={music?.links}
          artists={artists}
          name={music?.metadata?.name}
          releaseTime={music?.releaseDate}
          image={image}
          show={showModal}
          isPreview={isPreview}
          onCancel={() => {
            router.push("/", undefined, { shallow: true });
            toggleModal(false);
          }}
          saveMarketingPermissions={saveMarketingPermissions}
        />
      ) : (
        showModal && (
          <MusicModal
            talent={talent}
            musicItem={music}
            isMobile={isMobile}
            module={module}
            links={music?.links}
            subtitle={publisher || owner || artists}
            name={music?.metadata?.name}
            image={image}
            show={showModal}
            toggleModal={toggleModal}
            isPreview={isPreview}
          />
        )
      )}

      {/* Only collect Market Permissions for artist, if not collected before */}
      {userMarketingProps && (
        <PreReleaseMarketingModal
          artists={artists}
          isMobile={isMobile}
          musicItem={music}
          talent={talent}
          userMarketingProps={userMarketingProps}
          setUserMarketingProps={setUserMarketingProps}
          showMarketingModal={showMarketingModal}
          toggleMarketingModal={toggleMarketingModal}
        />
      )}
    </>
  );
};

//return interface for Music swiper
interface ITalentDetailMusicSelf {
  songs: MusicItem[];
  itemsRender: MusicItem[];
  params: any;
  activeMusic: MusicItem | null;
  setActiveMusic: Dispatch<SetStateAction<MusicItem | null>>;
}

export function useTalentDetailMusicSelf(isMobile: boolean, module: TalentProfileModule): ITalentDetailMusicSelf {
  const [activeMusic, setActiveMusic] = useState<MusicItem | null>(null);
  const [itemsRender, setItemsRender] = useState<MusicItem[]>([]);

  const songs = useMemo(() => module?.items as MusicItem[], [module?.items]);

  const params: any = useMemo(
    () => ({
      slidesPerView: songs?.length <= MINIMUM_ELEMENTS_SLIDER ? 1 : isMobile ? 1.2508 : 1.5123,
      slidesPerColumn: songs?.length <= MINIMUM_ELEMENTS_SLIDER ? songs?.length : 2,
      spaceBetween: songs?.length <= MINIMUM_ELEMENTS_SLIDER ? 16 : isMobile ? 0 : 24,
      noSwiping: true,
      navigation: !isMobile,
      centeredSlides: !isMobile,
      centeredSlidesBounds: !isMobile,
      slidesOffsetAfter: isMobile ? 16 : 0,
    }),
    [isMobile, songs?.length],
  );

  useEffect(() => {
    if (songs?.length) {
      setItemsRender([...songs]);
    }
  }, [songs]);

  return {
    songs,
    itemsRender,
    params,
    activeMusic,
    setActiveMusic,
  };
}

const TalentDetailMusic: React.FC<IProps> = ({ className = "", isMobile, isPreview, module, talent, isGroup }) => {
  const { songs, itemsRender, params, activeMusic, setActiveMusic } = useTalentDetailMusicSelf(isMobile, module);

  return songs?.length > 0 ? (
    <div id={module.id} className={`talent-detail__music ${className}`}>
      <>
        <Swiper
          key={itemsRender?.length}
          showShadow={!isMobile}
          className={classNames({
            "multiple-row": songs?.length > MINIMUM_ELEMENTS_SLIDER,
            "p__x--16": isMobile && songs?.length <= MINIMUM_ELEMENTS_SLIDER,
            "p__l--16": isMobile && songs?.length > MINIMUM_ELEMENTS_SLIDER,
            "fix-width": songs?.length <= MINIMUM_ELEMENTS_SLIDER,
          })}
          isMobile={isMobile}
          title={module?.name}
          isGroup={isGroup}
          showTitle={module.showTitle}
          params={{ ...params }}
          items={itemsRender
            ?.filter((x) => x.visible)
            .map((song, i) => {
              return (
                <MusicCard
                  talent={talent}
                  isSingleElement={songs?.length === 1}
                  module={module}
                  isMobile={isMobile}
                  isPreview={isPreview}
                  key={i}
                  index={i}
                  music={song}
                  setActiveMusic={setActiveMusic}
                  activeMusic={activeMusic}
                  isBig={songs?.length <= MINIMUM_ELEMENTS_SLIDER}
                  className={isMobile && songs?.length > MINIMUM_ELEMENTS_SLIDER ? "m__r--16" : ""}
                />
              );
            })}
        />
        {!isGroup && <Divider className="m__t--24 m__b--0" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default TalentDetailMusic;
