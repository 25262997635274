import Button from "antd/lib/button";
import { Text } from "components/Typography";
import dynamic from "next/dynamic";
import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
import { useTypedSelector } from "redux/rootReducer";
import { selectIsPreSaveSuccess } from "redux/Talent/selector";
import { PreSaveMarketingPermission, Talent } from "redux/Talent/types";
import { MusicItem as MusicItemElement, MusicItemType } from "redux/User/types";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import FormItem from "antd/lib/form/FormItem";
import Input from "antd/lib/input";
import { Form } from "antd";
import { Tag } from "antd";
import ImageProgessive from "components/ImageProgessive";
import { setPreSaveMarketingPermissionActions } from "redux/Talent/actions";
import { useDispatch } from "react-redux";
import { MARKETING_PERMISSIONS } from "constants/auth";
import Cookies from "js-cookie";

const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Modal = dynamic(() => import("components/Modal"));

interface IProps {
  artists: string;
  isMobile: boolean;
  musicItem: MusicItemElement;
  talent?: Talent;
  userMarketingProps: PreSaveMarketingPermission;
  setUserMarketingProps: Dispatch<SetStateAction<PreSaveMarketingPermission | null>>;
  showMarketingModal: boolean;
  toggleMarketingModal: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * Modal that collects marketing preferences after a pre-release occurs
 */
const PreReleaseMarketingModal: React.FC<IProps> = ({
  artists,
  isMobile,
  musicItem,
  talent,
  userMarketingProps,
  setUserMarketingProps,
  showMarketingModal,
  toggleMarketingModal,
}) => {
  const dispatch = useDispatch();
  const isPreSaveSuccess = useTypedSelector(selectIsPreSaveSuccess);

  useEffect(() => {
    if (isPreSaveSuccess) {
      toggleMarketingModal(true);
    }
  }, [isPreSaveSuccess]);

  /**
   * Function that sets the marketing permissions and closes the modal
   * @param email
   * @param agree
   */
  const handlePermissions = (email: string | null = userMarketingProps.email || null, agree = false) => {
    const marketingProps = { ...userMarketingProps, agreeToMarketing: agree };

    if (email) marketingProps.email = email;

    setUserMarketingProps(marketingProps);

    const cookieName =
      userMarketingProps.platform === MusicItemType.SPOTIFY
        ? MARKETING_PERMISSIONS.SPOTIFY
        : MARKETING_PERMISSIONS.APPLE;

    Cookies.set(cookieName, JSON.stringify(marketingProps), { expires: 180 });
    toggleMarketingModal(false);
  };

  /* Set marketing permissions in the backend when modal is closed */
  useEffect(() => {
    if (userMarketingProps.agreeToMarketing !== null) {
      dispatch(
        setPreSaveMarketingPermissionActions.REQUEST({
          payload: userMarketingProps,
        }),
      );
    }
  }, [showMarketingModal]);

  return (
    <Modal
      centered
      closable
      closeIcon={<CloseIcon />}
      footer={false}
      className="marketing-modal m__y--40"
      visible={showMarketingModal}
      onCancel={() => handlePermissions()}
      bodyStyle={{ padding: 0 }}
    >
      <div
        className="modal-header"
        style={{ background: talent?.talentProfile?.themeColor.backgroundColor || "initial" }}
      >
        <div className="modal-header-overlay" />
      </div>
      <div className="modal-body">
        <Row className="modal-card">
          <Col>
            <ImageProgessive
              ratio={0.25}
              threshold={[0.25, 1]}
              borderRadius={16}
              src={musicItem.metadata.images[0].url || ""}
              width={isMobile ? 120 : 160}
              height={isMobile ? 120 : 160}
            />
          </Col>

          <Col className="modal-card-col-stack">
            <Tag>PRE-SAVED</Tag>
            <Text preset="semibold16">{musicItem?.metadata?.name || ""}</Text>
            <Text preset="regular12" className="opacity--05">
              {artists || ""}
            </Text>
          </Col>
        </Row>

        <Text preset="semibold18">
          {"Sign up to receive email updates from " + (talent?.talentProfile?.displayName || " ")}
        </Text>

        <div className="modal-form">
          {/* Form that validates field(s) and sends them to be set in permissions */}
          <Form
            requiredMark={false}
            layout="vertical"
            onFinish={(email) => handlePermissions(email.email, true)}
            initialValues={{ email: userMarketingProps.email || "" }}
          >
            {userMarketingProps.platform === MusicItemType.APPLE_MUSIC && ( //render email fields for apple presaves as they're already captured in spotify scope
              <FormItem
                name={"email"}
                label={"Email"}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input placeholder="Enter your email address" />
              </FormItem>
            )}
            <Text preset="regular12">
              {"You will receive news and promotions from the artist and their record label in line with their "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={talent?.talentProfile?.privacyPolicy || `${process.env.NEXT_PUBLIC_URL}/talent-privacy-policy`}
              >
                Privacy Policy
              </a>
            </Text>

            <div className="btn-actions m__t--24 m__b--24 text-right">
              <Button type="primary" htmlType="submit">
                {userMarketingProps.platform === MusicItemType.SPOTIFY ? "ACCEPT" : "SUBSCRIBE"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default PreReleaseMarketingModal;
