import { KOMI_APPLE_MUSIC_TOKEN, MARKETING_PERMISSIONS } from "constants/auth";
import Cookies from "js-cookie";

interface MusicKitMemo {
  musicKitInstance: MusicKit.MusicKitInstance | null;
}

const memo: MusicKitMemo = {
  musicKitInstance: null,
};

/**
 * Configures the global MusicKit object
 */
export const configureMusicKit = (): void => {
  console.log(process.env.NEXT_PUBLIC_APPLE_MUSIC_DEVELOPER_TOKEN);
  window.MusicKit.configure({
    developerToken: process.env.NEXT_PUBLIC_APPLE_MUSIC_DEVELOPER_TOKEN,
    app: {
      name: process.env.NEXT_PUBLIC_APPLE_MUSIC_APP_NAME,
      build: process.env.NEXT_PUBLIC_APPLE_MUSIC_APP_VERSION,
    },
  });
};

/**
 * Creates a new MusicKit instance
 *
 * @returns {MusicKit.MusicKitInstance} The MusicKit instance
 */
export const createMusicKitInstance = (): MusicKit.MusicKitInstance => {
  return window.MusicKit.getInstance();
};

/**
 * Gets a MusicKit instance
 *
 * @returns {MusicKit.MusicKitInstance} The MusicKit instance
 */
export const getMusicKitInstance = (): MusicKit.MusicKitInstance => {
  // Configure, create and memoize the instance, if it isn't memoized already
  if (memo.musicKitInstance === null) {
    configureMusicKit();
    memo.musicKitInstance = createMusicKitInstance();
  }

  return memo.musicKitInstance;
};

/**
 * Authorize the MusicKit API and get a Music User token
 *
 * @returns {string} Apple's Music User token; the token has an expiry of 180 days
 */
export const authorizeMusicKit = async (): Promise<string> => {
  const instance = getMusicKitInstance();

  // Check if the cookie exists, and return the value if it does
  let token = Cookies.get(KOMI_APPLE_MUSIC_TOKEN);
  const marketingPermissions = Cookies.get(MARKETING_PERMISSIONS.APPLE);

  if (token && marketingPermissions) {
    return token;
  }

  // Authorize, and set the cookie to the returned token, expiring in 180 days
  token = await instance.authorize();

  Cookies.set(KOMI_APPLE_MUSIC_TOKEN, token, { expires: 180 });

  return token;
};

/**
 * Unauthorize the MusicKit API, invalidating the token and cookie
 */
export const unauthorizeMusicKit = async (): Promise<void> => {
  const instance = getMusicKitInstance();
  await instance.unauthorize();

  Cookies.remove(KOMI_APPLE_MUSIC_TOKEN);
};
