import { BaseItem, TalentProfileModule } from "redux/User/types";

export const isBaseItem = (element: any): element is BaseItem => {
  return element.visible !== undefined;
};

export const isModule = (element: any): element is TalentProfileModule => {
  return element.items !== undefined;
};

export const calculateGroupVisible = (group: any) => {
  if (isModule(group.items[0])) {
    const subModules = group.items as TalentProfileModule[];
    let visible = false;
    // let hasNoBaseItems = true;
    subModules.forEach((subModule) => {
      // if the submodule items are of baseItem
      if (isBaseItem(subModule.items[0])) {
        const baseItems = subModule.items as BaseItem[];
        // and at least one is visible, we show it
        if (baseItems.filter((x) => x.visible).length > 0) {
          visible = true;
        }
      } // If the visible group has a module with items that cannot be hidden, show it
      else {
        visible = true;
      }
    });
    // If all visible submodules have hidden baseItems, we hide the group
    return visible;
  }
  return group.visible;
};
