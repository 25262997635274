import { ConfigProvider, Row, Spin } from "antd";
import TalentDetailBandsintown from "components/TalentDetailBandsintown";
import TalentDetailShopMyShelf from "components/TalentDetailShopMyShelf";
import TalentDetailExperiences from "components/TalentDetailExperiences";
import TalentDetailFanClub from "components/TalentDetailFanClub";
import TalentDetailHeader from "components/TalentDetailHeader";
import TalentDetailLinks from "components/TalentDetailLinks";
import TalentDetailMusic from "components/TalentDetailMusic";
import TalentDetailOnDemands from "components/TalentDetailOnDemands";
import TalentDetailPodcast from "components/TalentDetailPodcast";
import TalentDetailPodcastAutomation from "components/TalentDetailPodcastAutomation";
import TalentDetailProducts from "components/TalentDetailProducts";
import TalentDetailProductShopify from "components/TalentDetailProductShopify";
import TalentDetailSocialLinks from "components/TalentDetailSocialLinks";
import TalentDetailTabs from "components/TalentDetailTabs";
import TalentDetailYoutube from "components/TalentDetailYoutube";
import TalentDetailMultipleYoutube from "components/TalentDetailMultipleYoutube";
import { KOMI_APPLE_MUSIC_TOKEN } from "constants/auth";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import DetailLayout from "layout/DetailLayout";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectLoadingTalentModules,
  selectLoadingTalentProfile,
  selectTalentBasicInfo,
  selectTalentError,
  selectTalentErrorCode,
} from "redux/Talent/selector";
import { Talent } from "redux/Talent/types";
import { getListUserPreSaveAppleActions, getListUserPreSaveSpotifyActions } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { BaseItem, ModuleItem, TalentProfileModule, TalentProfileModuleType, User } from "redux/User/types";
import { KOMI_SPOTIFY_REFRESH_TOKEN } from "services/SpotifyService";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
import classNames from "classnames";
import { Text } from "components/Typography";
import TalentDetailEvents from "components/TalentDetailEvents";
import DataCaptureModule from "components/DataCaptureModule";
import Divider from "antd/lib/divider";
import LabelTooltip from "components/LabelTooltip/LabelTooltip";
import TalentDetailSeated from "components/TalentDetailSeated";
import reduce from "lodash/reduce";
import { calculateGroupVisible, isBaseItem } from "utils/visibilityUtils";
import { getTalentByUsernameActions } from "redux/Talent/actions";

function trackViewTalentProfile(user: User | undefined, talent: Talent | undefined) {
  const properties = {
    userId: user?.id,
    name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
    location: AnalyticServices.getLocation(),
    talentUserId: talent?.id,
    talentName: `${talent?.talentProfile?.firstName} ${talent?.talentProfile?.lastName}`,
    platform: AnalyticServices.getPlatform({ side: "client-side" }),
    pageId: talent?.talentProfile?.localizationId || null,
    pageName: talent?.talentProfile?.localizationName || "Default",
  } as const;

  AnalyticServices.track(SEGMENT_EVENT.VIEW_TALENT_PROFILE, {
    "User id": properties.userId,
    Name: properties.name,
    Location: properties.location,
    "Talent User ID": properties.talentUserId,
    "Talent Name": properties.talentName,
    Platform: properties.platform,
    "Page ID": properties.pageId,
    "Page Name": properties.pageName,
  });
  AnalyticServices.track(SEGMENT_EVENT.GA_VIEW_TALENT_PROFILE, {
    is_ga_only: true,
    user_id: properties.userId,
    name: properties.name,
    location: properties.location,
    talent_user_id: properties.talentUserId,
    talent_name: properties.talentName,
    platform: properties.platform,
    page_id: properties.pageId,
    page_name: properties.pageName,
  });
}

//import ShopList from "./dataFake";
interface IProps {
  isMobile: boolean;
  isPreview?: boolean;
  banned?: boolean;
  message?: string;
  username?: string;
  talent?: Talent;
  talentModules?: TalentProfileModule[];
  isGroup?: boolean;
}

const TalentDetail: React.FC<IProps> = ({
  isMobile,
  banned,
  message,
  username,
  isPreview = false,
  talent,
  talentModules,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const user: User = useTypedSelector(selectUserData);
  const talentBasicInfo = useTypedSelector(selectTalentBasicInfo);
  const loadingTalentProfile = useTypedSelector(selectLoadingTalentProfile);
  const loadingTalentModule = useTypedSelector(selectLoadingTalentModules);
  const error = useTypedSelector(selectTalentError);
  const errorCode = useTypedSelector(selectTalentErrorCode);

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isSentEvent, setSentEvent] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const modules = useMemo(() => {
    if (!talentModules) {
      return [];
    }
    return reduce(
      talentModules,
      (results: any, module) => {
        if (!module.items?.length || [TalentProfileModuleType.FAN_CLUB].includes(module.type)) {
          return results;
        }
        if (module.type === TalentProfileModuleType.GROUP) {
          const items = (module.items as any).filter(
            (item: any) => item.items.length && ![TalentProfileModuleType.FAN_CLUB].includes(item.type),
          );
          if (!items.length) {
            return results;
          }
          return [...results, { ...module, items }];
        }
        return [...results, module];
      },
      [],
    );
  }, [talentModules]);
  const socialProfileLinks = useMemo(
    () => talent?.talentProfile?.socialProfileLinks,
    [talent?.talentProfile?.socialProfileLinks],
  );
  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );

  useEffect(() => {
    if (talent && !isSentEvent && (window as any)?.analytics && !isPreview) {
      setSentEvent(true);
      trackViewTalentProfile(user, talent);
    }
  }, [isPreview, isMobile, isSentEvent, talent, user]);

  useEffect(() => {
    const spotifyToken = Cookies.get(KOMI_SPOTIFY_REFRESH_TOKEN);
    const appleMusicToken = Cookies.get(KOMI_APPLE_MUSIC_TOKEN);

    if (talent) {
      if (spotifyToken) {
        dispatch(
          getListUserPreSaveSpotifyActions.REQUEST({
            token: spotifyToken,
          }),
        );
      }
      if (appleMusicToken) {
        dispatch(
          getListUserPreSaveAppleActions.REQUEST({
            token: appleMusicToken,
          }),
        );
      }
    }
  }, [dispatch, talent]);

  useEffect(() => {
    if (talent && process.browser) {
      const themeColor = talent?.talentProfile?.themeColor;

      ConfigProvider.config({
        theme: {
          primaryColor: themeColor?.backgroundColor, // backgroundColor
          infoColor: themeColor?.typographyColor, // textColor
          successColor: isDarken ? "rgba(18, 18, 18, 0.1)" : "rgba(255, 255, 255, 0.1)", // overlayColor
        },
      });
    } else {
      ConfigProvider.config({
        theme: {
          primaryColor: "#121212", // backgroundColor
          infoColor: "#ffffff", // textColor
          successColor: "rgba(255, 255, 255, 0.1)", // overlayColor
        },
      });
    }
  }, [isDarken, talent]);

  useEffect(() => {
    if (username && !talent) {
      dispatch(getTalentByUsernameActions.REQUEST({ username, countryCode: router.query?.countryCode }));
    }
  }, [dispatch, username, talent, router]);

  useEffect(() => {
    if (error) {
      if (errorCode === "not-found") {
        /* This will cause an error. `notification.error.message` must be a string
        notification.error({
          message: error,
        });
        */
        // setTimeout(() => router.push("/404"), 1000);
        setNotFound(true)
      } else if (errorCode === "rejected") {
        /* This will cause an error. `notification.error.message` must be a string
        notification.error({
          message: error,
        });
        */
        setTimeout(() => router.push("/"), 1000);
      } else {
        /* This will cause an error. `notification.error.message` must be a string
        notification.error({
          message: error,
        });
        */
      }
    }
  }, [error, errorCode, router]);

  const title = useMemo(
    () =>
      talentBasicInfo?.talentProfile
        ? `${talentBasicInfo?.talentProfile?.firstName} ${talentBasicInfo?.talentProfile?.lastName}`
        : notFound
          ? '404 | This page could not be found'
          : ``,
    [talentBasicInfo?.talentProfile, notFound],
  );
  const renderTalentProfileModule = (module: any, i: any, isGroup: boolean) => {
    if (!module.visible) return;
    // If the module has items that are all not visible, return
    if (
      module.items.length > 0 &&
      isBaseItem(module.items[0]) &&
      module.items.filter((x: BaseItem) => x.visible === true).length === 0
    )
      return;

    switch (module.type) {
      case TalentProfileModuleType.GROUP:
        if (!calculateGroupVisible(module)) return;

        return (
          <>
            <div
              id={module.id}
              key={module.id}
              className={`talent-detail__links talent-detail__module ${
                isMobile
                  ? i === 0
                    ? "m__t--0 m__b--16 p__x--16"
                    : "m__t--24 m__b--16 p__x--16"
                  : module.items.length > 0 && module.items[0]?.name.length > 0
                  ? "m__t--24 m__b--20"
                  : "m__t--24 m__b--16"
              }`}
            >
              <LabelTooltip
                className="my-swiper__title"
                preset={isMobile ? "semibold24" : "semibold28"}
                label={module.name}
                ellipsis={{ rows: 1 }}
                maxLength={isMobile ? module.name.length : 30}
              />
            </div>
            {module.items
              .filter((x: any) => x.visible)
              .map((groupItem: any, index: any) => {
                return renderTalentProfileModule(groupItem, index, true);
              })}
            {i + 1 < modules?.length && <Divider className="m__t--24 m__b--0" />}
          </>
        );
      case TalentProfileModuleType.SHOP_MY_SHELF:
      case TalentProfileModuleType.SHOP_LIST:
        return (
          <TalentDetailShopMyShelf
            className={`talent-detail__module ${i === 0 ? "p__t--0" : isGroup ? "p__t--16" : "p__t--24"}`}
            showDivider={i + 1 < modules?.length}
            isMobile={isMobile}
            isDarken={isDarken}
            module={module}
            key={module.id}
            isGroup={isGroup}
            talent={talent}
          />
        );
      case TalentProfileModuleType.FORM_DATA:
        return (
          <DataCaptureModule
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            showDivider={i + 1 < modules?.length}
            isMobile={isMobile}
            isDarken={isDarken}
            module={module}
            key={module.id}
            isGroup={isGroup}
            talent={talent}
          />
        );

      case TalentProfileModuleType.EXPERIENCE:
        return (
          <TalentDetailExperiences
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.LINK:
        return (
          <TalentDetailLinks
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.BANDSINTOWN:
        return (
          <>
            <TalentDetailBandsintown
              key={module.id}
              className={`talent-detail__module ${
                i === 0
                  ? "p__t--0"
                  : isMobile
                  ? "p__t--16"
                  : isGroup && (module.name.length === 0 || module.showTitle === false)
                  ? "p__t--16"
                  : "p__t--24"
              }`}
              module={module}
              isMobile={isMobile}
              isPreview={isPreview}
              talent={talent}
              isGroup={isGroup}
            />
          </>
        );

      case TalentProfileModuleType.EVENTS:
        return (
          <TalentDetailEvents
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.MUSIC:
        return (
          <TalentDetailMusic
            key={module.id}
            talent={talent}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.PODCAST:
        return (
          <TalentDetailPodcast
            key={module.id}
            talent={talent}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            isGroup={isGroup}
          />
        );
      case TalentProfileModuleType.PODCAST_AUTOMATION:
        return (
          <TalentDetailPodcastAutomation
            key={module.id}
            talent={talent}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            isGroup={isGroup}
          />
        );
      case TalentProfileModuleType.ON_DEMAND_VIDEO:
        return (
          <TalentDetailOnDemands
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            showDivider={i + 1 < modules?.length}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.PRODUCT:
        return (
          <TalentDetailProducts
            key={module.id}
            talent={talent}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            showDivider={i + 1 < modules?.length}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.SHOPIFY_PRODUCT:
      case TalentProfileModuleType.SHOPIFY_COLLECTION:
        return (
          <TalentDetailProductShopify
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );
      case TalentProfileModuleType.YOUTUBE_COLLECTION:
        return (
          <TalentDetailMultipleYoutube
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.YOUTUBE_VIDEO:
        return (
          <TalentDetailYoutube
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );

      case TalentProfileModuleType.FAN_CLUB:
        return (
          <TalentDetailFanClub
            key={module.id}
            talent={talent}
            className={`${`talent-detail__module ${i === 0 ? "p__t--0" : "p__t--24"}`} ${isMobile ? "p__x--16" : ""}`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
          />
        );
      case TalentProfileModuleType.SEATED:
        return (
          <TalentDetailSeated
            key={module.id}
            className={`talent-detail__module ${
              i === 0
                ? "p__t--0"
                : isMobile
                ? "p__t--16"
                : isGroup && (module.name.length === 0 || module.showTitle === false)
                ? "p__t--16"
                : "p__t--24"
            }`}
            module={module}
            isMobile={isMobile}
            isPreview={isPreview}
            talent={talent}
            isGroup={isGroup}
          />
        );
    }
  };
  const renderContent = () => {
    if (banned) {
      return (
        <Row className="height-vertical" align="middle" justify="center">
          <Spin spinning={true} />
        </Row>
      );
    }

    return (
      <React.Fragment>
        <ConfigProvider>
          <NextSeo
            title={title}
            openGraph={{
              images: talentBasicInfo?.talentProfile?.avatar
                ? [
                    {
                      url: talentBasicInfo?.talentProfile?.avatar,
                    },
                  ]
                : [],
              title,
            }}
          />
          <DetailLayout
            hideDescription
            title={title}
            images={[
              {
                url: talentBasicInfo?.talentProfile?.avatar,
              },
            ]}
            backgroundImage={talent?.talentProfile?.avatar}
            isMobile={isMobile}
            isPreview={isPreview}
            mobileMenuVisible={mobileMenuVisible}
            toggleMenuVisible={() => setMobileMenuVisible((status) => !status)}
            showFooter={isPreview ? !!talent?.talentProfile?.avatar?.length || !!modules?.length : true}
            talent={talent}
            footerClassName="m__t--24"
            loading={isPreview ? false : !talent || loadingTalentProfile}
            notFound={notFound}
            loadingModule={isPreview ? false : loadingTalentModule}
          >
            <div className={`talent-detail ${isDarken ? "darken" : ""}`}>
              <TalentDetailHeader
                mobileMenuVisible={mobileMenuVisible}
                toggleMenuVisible={() => setMobileMenuVisible((status) => !status)}
                isMobile={isMobile}
                isPreview={isPreview}
                talent={talent as Talent}
              />
              {socialProfileLinks && socialProfileLinks?.length > 0 ? (
                <TalentDetailSocialLinks
                  links={socialProfileLinks}
                  isMobile={isMobile}
                  className={isMobile ? "m__t--24" : "m__t--16"}
                />
              ) : isPreview ? (
                <div className="empty-state empty-state--social-links">
                  <Text className="opacity--06" preset={isMobile ? "semibold16" : "semibold20"}>
                    Social Links
                  </Text>
                </div>
              ) : (
                <></>
              )}
              {isPreview && !modules?.length ? (
                <div
                  className={classNames({
                    "empty-state empty-state--module": true,
                    "m__b--14": isMobile && !modules?.length && !talent,
                  })}
                >
                  <Text className="opacity--06" preset={isMobile ? "semibold16" : "semibold20"}>
                    Your modules will appear here
                  </Text>
                </div>
              ) : !isPreview && loadingTalentModule ? (
                <div className="snippet d--flex justify__content--center m__t--68" data-title=".dot-flashing">
                  <div className="stage">
                    <div className={`dot-flashing ${isDarken ? "darken" : ""}`}></div>
                  </div>
                </div>
              ) : (
                <>
                  {modules && (
                    <TalentDetailTabs
                      modules={modules}
                      isMobile={isMobile}
                      isPreview={isPreview}
                      className="m__t--32"
                      loading={isPreview ? false : !talent || loadingTalentProfile}
                    />
                  )}

                  {modules && modules?.length > 0 && (
                    <>
                      <div className={isMobile ? "m__t--34" : "m__t--32"} />
                      {modules?.map((module: any, i: number) => {
                        return renderTalentProfileModule(module, i, false);
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </DetailLayout>
        </ConfigProvider>
      </React.Fragment>
    );
  };
  return renderContent();
};

export default TalentDetail;
